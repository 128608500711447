<template>
  <div>
    <footer
      class="footer"
      style="height: 2rem !important; padding-bottom: 1.4rem"
    >
      <div class="container-fluid">
        <div class="row">
          <div class="col-md-12 footer-copyright text-center">
            <p
              style="font-size: 11px"
              :style="{ marginLeft: togglesidebar ? '15rem' : '0' }"
            >
              Copyright {{ currentYear }} &copy; {{websiteName }}, All rights reserved.,
            </p>
          </div>
        </div>
      </div>
    </footer>
  </div>
</template>

<script>
import json from '../data/menu';
export default {
  name: 'footerpage',
  props: ['togglesidebar'],
  data() {
    return {
      websiteName: '',
      currentYear: null,
      customizer: false,
      data:
        'data:text/json;charset=utf-8,' +
        encodeURIComponent(JSON.stringify(json)),
    };
  },
  mounted() {
    this.getCurrentYear();
    this.websiteName = window.location.hostname
  this.websiteName =  window.location.hostname == 'thailandpost.smartcall.cloud' ? 'Thailand Post' : 'Smart Call'
  },
  methods: {
    getCurrentYear() {
      const date = new Date();
      this.currentYear = date.getFullYear();
    },
  },
};
</script>
