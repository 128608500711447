<template>
  <div>
    <div class="page-wrapper" :class="layoutobj">
      <div class="page-header" :class="{ close_icon: !togglesidebar }">
        <Header @clicked="sidebar_toggle" />
      </div>
      <div class="page-body-wrapper">
        <div class="bg-overlay"></div>
        <div
          class="sidebar-wrapper"
          :class="[{ close_icon: !togglesidebar }]"
          :sidebar-layout="layout.settings.sidebar_setting"
        >
          <Sidebar @clicked="sidebar_toggle" />
        </div>
        <div class="page-body" @click="hidesecondmenu()">
          <transition name="fadeIn" enter-active-class="animated fadeIn">
            <router-view class="view"></router-view>
          </transition>
        </div>
        <Footer :togglesidebar="togglesidebar" style="margin-top: 1rem" />
      </div>
      <TapTop />
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import { layoutClasses } from '../constants/layout';
import Header from './header';
import Sidebar from './sidebar.vue';
import Footer from './footer';
import Customizer from './customizer';
import TapTop from './taptop';

export default {
  name: 'mainpage',
  // props:['sidebar_toggle_var'],
  components: {
    Header,
    Sidebar,
    Footer,
    Customizer,
    TapTop,
  },
  data() {
    return {
      mobileheader_toggle_var: false,
      sidebar_toggle_var: false,
      horizontal_Sidebar: true,
      resized: false,
      layoutobj: {},
    };
  },
  computed: {
    ...mapState({
      menuItems: (state) => state.menu.data,
      layout: (state) => state.layout.layout,
      togglesidebar: (state) => state.menu.togglesidebar,
      activeoverlay: (state) => state.menu.activeoverlay,
    }),
    layoutobject: {
      get: function () {
        return JSON.parse(
          JSON.stringify(
            layoutClasses.find(
              (item) => Object.keys(item).pop() === this.layout.settings.layout
            )
          )
        )[this.layout.settings.layout];
      },
      set: function () {
        this.layoutobj = layoutClasses.find(
          (item) => Object.keys(item).pop() === this.layout.settings.layout
        );
        this.layoutobj = JSON.parse(JSON.stringify(this.layoutobj))[
          this.layout.settings.layout
        ];
        return this.layoutobj;
      },
    },
  },
  watch: {
    $route() {
      this.menuItems.filter((items) => {
        if (items.path === this.$route.path)
          this.$store.dispatch('menu/setActiveRoute', items);
        if (!items.children) return false;
        items.children.filter((subItems) => {
          if (subItems.path === this.$route.path)
            this.$store.dispatch('menu/setActiveRoute', subItems);
          if (!subItems.children) return false;
          subItems.children.filter((subSubItems) => {
            if (subSubItems.path === this.$route.path)
              this.$store.dispatch('menu/setActiveRoute', subSubItems);
          });
        });
      });
      this.layoutobj = layoutClasses.find(
        (item) => Object.keys(item).pop() === this.layout.settings.layout
      );
      if (
        (window.innerWidth < 991 &&
          this.layout.settings.layout === 'LosAngeles') ||
        (window.innerWidth < 991 &&
          this.layout.settings.layout === 'Singapore') ||
        (window.innerWidth < 991 && this.layout.settings.layout === 'Barcelona')
      ) {
        const newlayout = JSON.parse(
          JSON.stringify(this.layoutobj).replace(
            'horizontal-wrapper',
            'compact-wrapper'
          )
        );
        this.layoutobj = JSON.parse(JSON.stringify(newlayout))[
          this.layout.settings.layout
        ];
      } else {
        this.layoutobj = JSON.parse(JSON.stringify(this.layoutobj))[
          this.layout.settings.layout
        ];
      }
    },
    sidebar_toggle_var: function () {
      this.resized =
        this.width <= 991 ? !this.sidebar_toggle_var : this.sidebar_toggle_var;
    },
  },
  created() {
    window.addEventListener('resize', this.handleResize);
    this.handleResize();
    this.resized = this.sidebar_toggle_var;
    this.$store.dispatch('layout/set');
    // this.$router.replace({ 'query': null }).catch(err => err);
    this.layout.settings.layout = this.$route.query.layout
      ? this.$route.query.layout
      : 'Dubai';
    this.layoutobj = layoutClasses.find(
      (item) => Object.keys(item).pop() === this.layout.settings.layout
    );
    this.layoutobj = JSON.parse(JSON.stringify(this.layoutobj))[
      this.layout.settings.layout
    ];
  },
  methods: {
    sidebar_toggle(value) {
      this.sidebar_toggle_var = !value;
    },
    mobiletoggle_toggle(value) {
      this.mobileheader_toggle_var = value;
    },
    handleResize() {
      this.$store.dispatch('menu/resizetoggle');
    },
    removeoverlay() {
      this.$store.state.menu.activeoverlay = false;
      if (window.innerWidth < 991) {
        this.$store.state.menu.togglesidebar = false;
      }
      this.menuItems.filter((menuItem) => {
        menuItem.active = false;
      });
    },
    hidesecondmenu() {
      if (this.layoutobject.split(' ').includes('compact-sidebar')) {
        this.menuItems.filter((menuItem) => {
          menuItem.active = false;
        });
      }
      if (window.innerWidth < 991) {
        this.$store.state.menu.togglesidebar = false;
      }
    },
  },
};
</script>
<style>
/* ==================== font-size all ==================== */
body {
  font-family: 'Nunito', sans-serif !important;
}
html {
  font-family: 'Nunito', sans-serif !important;
}
.page-wrapper .page-body-wrapper .page-title .breadcrumb li {
  font-family: 'Nunito', sans-serif !important;
}
.font-roboto {
  font-family: 'Nunito', sans-serif !important;
}
.page-wrapper.compact-wrapper
  .page-body-wrapper
  div.sidebar-wrapper
  .sidebar-main
  .sidebar-links
  > li
  .sidebar-submenu
  li
  a {
  font-family: 'Nunito', sans-serif !important;
}
.page-wrapper.compact-wrapper
  .page-body-wrapper
  div.sidebar-wrapper
  .sidebar-main
  .sidebar-links
  li
  a
  span {
  font-family: 'Nunito', sans-serif !important;
}

/* ==================== font-size all ==================== */

/* ==================== :hover all ==================== */
#clear-btable-whitlist-btn-z:hover {
  color: #ffffff;
  background-color: #f83366 !important;
  border-color: #f83366 !important;
}
/* #import-csv-whitlist-btn-z:hover {
  background-color: #0258da !important; 
  border-color: #0258da !important;
} */
/* #export-csv-whitlist-btn-z:hover {
  background-color: #0258da !important; 
  border-color: #0258da !important;
} */
/* #add-whitlist-btn-z:hover {
  background-color: #0258da !important; 
  border-color: #0258da !important;
}
#filter-whitlist-btn-z:hover {
  background-color: #0258da !important; 
  border-color: #0258da !important;
} */

#submit-add-whitelist-btn-z:hover {
  color: #ffffff;
  background-color: #3f60fb !important;
  border-color: #3f60fb !important;
}

#submit-import-whitlist-btn-z:hover {
  color: #ffffff !important;
  background-color: #3f60fb !important;
  border-color: #3f60fb !important;
}
#clear-import-whitlist-btn-z:hover {
  color: #ffffff !important;
  background-color: #f83366 !important;
  border-color: #f83366 !important;
}
/* #search-filter-whitelist-btn-z:hover {
  color: #ffffff;
  background-color: #0258da !important; 
  border-color: #0258da !important;
} */
#clear-filter-whitelist-btn-z:hover {
  color: #ffffff !important;
  background-color: #f83366 !important;
  border-color: #f83366 !important;
}

/* ==== */

#clear-btable-callactivity-btn-z:hover {
  color: #ffffff !important;
  background-color: #f83366 !important;
  border-color: #f83366 !important;
}
#search-one-callactivity-btn-z:hover {
  color: #ffffff;
  background-color: #3f60fb !important;
  border-color: #3f60fb !important;
}
#clear-one-callactivity-btn-z:hover {
  color: #ffffff !important;
  background-color: #f83366 !important;
  border-color: #f83366 !important;
}

/* ==== */

/* #export-csv-interval-btn-z:hover {
  background-color: #0258da !important; 
  border-color: #0258da !important;
} */
#search-one-interval-btn-z:hover {
  color: #ffffff;
  background-color: #3f60fb !important;
  border-color: #3f60fb !important;
}
#clear-one-interval-btn-z:hover {
  color: #ffffff;
  background-color: #f83366 !important;
  border-color: #f83366 !important;
}
#clear-two-interval-btn-z:hover {
  color: #ffffff;
  background-color: #f83366 !important;
  border-color: #f83366 !important;
}

/* ==== */

#search-one-daily-btn-z:hover {
  color: #ffffff;
  background-color: #3f60fb !important;
  border-color: #3f60fb !important;
}
#clear-one-daily-btn-z:hover {
  color: #ffffff;
  background-color: #f83366 !important;
  border-color: #f83366 !important;
}

/* ==================== :hover all ==================== */

/* ==================== title ==================== */
.page-wrapper .page-body-wrapper .page-title .row h3 {
  font-size: 16px !important;
}
.page-wrapper .page-body-wrapper .page-title {
  padding: 8px 30px 8px 30px !important;
  margin: 0 -30px 12px !important;
}
/* ==================== title ==================== */

/* ==================== header ==================== */
.page-wrapper .page-header .header-wrapper {
  padding: 15px 30px 15px 0px !important;
}
/* ==================== header ==================== */
/* ==================== page-body ==================== */
.page-wrapper.compact-wrapper .page-body-wrapper .page-body {
  margin-top: 67px !important;
  min-height: calc(94vh - 82px) !important;
  /* margin-left: calc(20% - 50px) !important; */
}
/* ==================== page-body ==================== */

/* ==================== card ==================== */
.card .card-body {
  padding: 11px 16px 11px 16px !important;
}

.card {
  margin-bottom: 12px !important;
  border-radius: 6px !important;
}
/* ==================== card ==================== */

/* ==================== btn ==================== */
.btn-xs {
  font-size: 10px !important;
}
/* ==================== btn ==================== */

/* ==================== b-table dropdown-menu ==================== */
.dropdown-menu.show {
  padding: 1px;
  text-align: center;
}
.dropdown-item {
  font-size: 10px !important;
}
/* ==================== b-table dropdown-menu ==================== */

/* ==================== size ==================== */
small {
  font-size: 60% !important;
}
/* ==================== size ==================== */

/* ==================== form-row whitelist ==================== */
.form-row > .col,
.form-row > [class*='col-'] {
  padding-right: 0px !important;
  padding-left: 0px !important;
}
/* ==================== form-row whitelist ==================== */

/* ==================== b-table all ==================== */
/* set class */
.s-th {
  padding: 0px 0px 0px 0px !important;
}
/* .s-th {  
  padding: 0px 0px 0px 0px !important; 
  width: 15px;
  text-align: center;
} */
/* .table.b-table tr th div {
  font-size: 10px !important;
  width: 120px !important;
  text-align: center !important;
}
th.position-relative {
  padding: 4px 30px !important;
}
.table.b-table tbody tr td div {
  font-size: 10px !important;
  text-align: center !important;
} */

/* === */

/* .status-pill {
  padding: 0.25rem 0.5rem;
  border-radius: 10rem;
}
.status-pill-warning {
  background-color: #ffc107ca;
}
.status-pill-success {
  background-color: #3be5639e;
}
.status-pill-danger {
  background-color: #f73162a4;
} */
/* ==================== b-table all ==================== */

/* ==================== card-body-z whitelist ==================== */
.card-body-z {
  margin: 8px 8px 14px 8px;
  /* padding: 11px 16px 11px 16px; */
}

/* ==================== card-body-z ==================== */
</style>
