<template>
  <div>
    <Breadcrumbs title="ลงทะเบียน SIM" />
    <!-- Container-fluid starts-->
    <div class="container-fluid">
      <div class="row">
        <div class="col-md-12">
          <div class="card">
            <div class="card-body">
              <!-- justify-content-between -->
              <b-row class="justify-content-end">
                <editWhitelist
                  ref="editWhitelistModal"
                  :isThaipost="isThaipost"
                  @editUser="getPaginationWhitelist()"
                />

                <b-row
                  class="row mb-3"
                  style="margin: 0 1rem 0 0; padding: 0 1px 0px 0"
                >
                  <!-- ==================== modal of Create whitelist ==================== -->
                  <b-row class="row">
                    <b-col class="" style="margin: 0 15px 0 0px">
                      <b-button
                        v-b-modal.addWhielist
                        :style="{
                          backgroundColor: btnPermission
                            ? '#d0d4ca !important'
                            : '',
                        }"
                        style="color: white; font-size: 10px !important"
                        size="sm"
                        id="add-whitlist-btn-z"
                        class="btn btn-pill btn-xs btnGlobal"
                        variant="primary"
                        :disabled="btnPermission"
                      >
                        + เพิ่ม
                      </b-button>
                      <addWhitelist
                        :isThaipost="isThaipost"
                        @postDataTable="postDataTable"
                      />
                    </b-col>
                  </b-row>
                  <!-- ========== Export CSV ========== -->
                  <b-row class="row">
                    <b-col class="" style="margin: 0 15px 0 0px">
                      <ImportWhitelist
                        :isThaipost="isThaipost"
                        @getDataTable="getPaginationWhitelist"
                      />
                    </b-col>
                  </b-row>

                  <!-- ==================== modal of Filter Interval Report ==================== -->
                  <filterWhitelist
                    :isThaipost="isThaipost"
                    @onFilter="onFilter"
                    @onClear="clearFilter"
                  />

                  <!-- ==================== modal of Filter whitelist ==================== -->

                  <!-- ========== Import CSV ========== -->
                  <b-row class="row">
                    <b-col class="" style="margin: 0 0px 0 0px">
                      <b-button
                        id="export-csv-whitlist-btn-z"
                        @click="getExport"
                        class="btn btn-pill btn-xs"
                        size="sm"
                        variant="primary"
                        :disabled="btnPermission"
                        style="color: white"
                        :style="{
                          backgroundColor: btnPermission
                            ? '#d0d4ca !important'
                            : '',
                          border: btnPermission ? 'none' : '',
                        }"
                      >
                        <b-spinner
                          v-if="isExport == 100"
                          small
                          label="Loading..."
                        ></b-spinner>
                        <div v-if="isExport == 0">
                          <font-awesome-icon icon="fa-solid fa-file-export" />
                          ส่งออก
                        </div>
                      </b-button>
                    </b-col>
                    <exportCSV ref="exportdata" />
                  </b-row>

                  <!-- === Dropdown Limit === -->
                  <b-row class="row">
                    <b-col style="margin: 0 0px 0 0px">
                      <b-form-select
                        v-model="perPage"
                        :options="pageOptions"
                        class="btn-xs"
                        style="
                          width: 55px;
                          height: 24px;
                          margin: 2px 0px 0 15px;
                          font-size: 10px !important;
                        "
                      >
                      </b-form-select>
                    </b-col>
                  </b-row>
                </b-row>
              </b-row>

              <div class="table-responsive table-whitelist">
                <div>
                  <v-data-table
                    :headers="headers"
                    :items="datatable_whitelist"
                    :page.sync="currentPage"
                    hide-default-footer
                    :items-per-page="perPage"
                    @page-count="pageCount = $event"
                    class="elevation-1"
                    :sort-by.sync="sortBy"
                    :sort-desc.sync="sortDesc"
                    :loading="isLoading"
                    @update:sort-by="onSorted"
                  >
                    <template v-slot:loading>
                      <v-progress-circular
                        indeterminate
                        color="primary"
                        size="40"
                        class="ma-2"
                      ></v-progress-circular>
                    </template>
                    <template slot="no-data"> ไม่มีข้อมูล.. </template>
                    <template v-slot:item.username="{ item }">
                  <div
                    class="text-center"
                    style="width: 100px; font-size: 10px"
                  >
                    {{ item.username }}
                  </div>
                </template>
                <template v-slot:item.subordination="{ item }">
                  <div
                    class="text-center"
                    style="width: 50px; font-size: 10px"
                  >
                    {{ item.subordination }}
                  </div>
                </template>
                <template v-slot:item.postcode="{ item }">
                  <div
                    class="text-center"
                    style="width: 100px; font-size: 10px"
                  >
                    {{ item.postcode }}
                  </div>
                </template>
                <template v-slot:item.post_office="{ item }">
                  <div
                    class="text-center"
                    style="width: 80px; font-size: 10px"
                  >
                    {{ item.post_office }}
                  </div>
                </template>
                <template v-slot:item.root_call="{ item }">
                  <div
                    class="text-center"
                    style="width: 80px; font-size: 10px"
                  >
                    {{ item.root_call }}
                  </div>
                </template>
                <template v-slot:item.in_service="{ item }">
                  <div
                    class="text-center"
                    style="width: 90px; font-size: 10px"
                  >
                    {{ item.in_service }}
                  </div>
                </template>
                <template v-slot:item.oos="{ item }">
                  <div
                    class="text-center"
                    style="width: 90px; font-size: 10px"
                  >
                    {{ item.oos }}
                  </div>
                </template>
                <template v-slot:item.work_date="{ item }">
                  <div
                    class="text-center"
                    style="width: 70px; font-size: 10px"
                  >
                    {{ item.work_date }}
                  </div>
                </template>
                <template v-slot:item.work_time="{ item }">
                  <div
                    class="text-center"
                    style="width: 100px; font-size: 10px"
                  >
                    {{ item.work_time }}
                  </div>
                </template>
                <template v-slot:item.last_modified="{ item }">
                  <div
                    class="text-center"
                    style="width: 130px; font-size: 10px"
                  >
                    {{ item.last_modified }}
                  </div>
                </template>
                <template v-slot:item.action="{ item }">
                  <div
                    class="text-center"
                    style="width: 120px; font-size: 10px"
                  >
                  <div class="d-flex m-0">
                      <b-button
                        size="sm"
                        id="editbtn"
                        class="btn btn-pill btn-xs mr-1 btnGlobal"
                         :style="{ backgroundColor: btnPermission ? '#d0d4ca !important' : '#ffa33c !important'}"
                        variant="primary"
                        :disabled="btnPermission"
                        @click="editUser(item)"
                      >
                        แก้ไข
                      </b-button>
                        <!-- :class="
                          btnPermission ? 'deleteBtnPer' : 'deleteBtnNoPer'
                        " -->
                      <b-button
                        id="deletebtn"
                        class="btn btn-pill btn-xs btnGlobal"
                        variant="primary"
                        :style="{ backgroundColor: btnPermission ? '#d0d4ca !important' : '#f83366 !important'}"
                        :disabled="btnPermission"
                        @click.prevent="deleteDataTableWhitelist(item)"
                      >
                        ลบ
                      </b-button>
                    </div>
                  </div>
                </template>
                  </v-data-table>
                  <div class="text-center pt-2 sm">
                    <b-pagination
                      v-model="currentPage"
                      :total-rows="totalRows"
                      :per-page="perPage"
                      align="center"
                      @input="getPaginationWhitelist"
                      class="justify-content-center"
                      size="sm"
                      aria-controls="my-table"
                    ></b-pagination>
                    <!-- <v-pagination
                      id="paginationDaily"
                      v-model="currentPage"
                      :length="total_pages"
                      :total-visible="currentPage + 3"
                    ></v-pagination> -->
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- Container-fluid Ends-->
  </div>
</template>

<script>
import { requestService } from '../_services/request.service';
import ExportCSVWhitelist from './whitelist_main/export_csv_whitelist.vue';
import ImportWhitelist from './whitelist_main/import_csv_whitelist.vue';
import addWhitelist from '@/pages/whitelist_main/addWhitelist';
import editWhitelist from '@/pages/whitelist_main/editWhitelist.vue';
import exportCSV from '../components/export';
import filterWhitelist from '../components/whitelist/filter';
// import exportCSV from '../../components/export.vue';

export default {
  components: {
    ExportCSVWhitelist,
    ImportWhitelist,
    addWhitelist,
    editWhitelist,
    exportCSV,
    filterWhitelist,
  },
  data() {
    return {
      isLoading: false,
      isThaipost: false,
      isExport: 0,
      perPage: 20,
      pageOptions: [10, 20, 30, 40, 50, 100],
      // ========== Filter ==========
      username: '',
      domain: '',
      root_call: '',
      grp: '',
      tableData: [],
      filter: null,
      total_pages: null,
      totalRows: 10,
      currentPage: 1,
      page: 1,
      totalPages: 1,
      indexRow: null,
      filterOn: [],
      // pageOptions: [10, 15, 30, 50, 100],
      sortBy: '',
      sortDesc: false,
      sorting: '',
      sortDirection: 'asc',
      btnPermission: false,
      modalVisibleWhitlist: false,
      datatable_whitelist: [],
      workDateOptions: [
        { text: 'จันทร์', value: '1', isCheck: false },
        { text: 'อังคาร', value: '2', isCheck: false },
        { text: 'พุธ', value: '3', isCheck: false },
        { text: 'พฤหัสบดี', value: '4', isCheck: false },
        { text: 'ศุกร์', value: '5', isCheck: false },
        { text: 'เสาร์', value: '6', isCheck: false },
        { text: 'อาทิตย์', value: '7', isCheck: false },
      ],
      startWork: '',
      endWork: '',
      postcode: '',
      subordi: '',
      office: '',
      inservice_time: '',
      oos_time: '',
      headers: [
        {
          value: 'username',
          text: 'เบอร์โทรศัพท์',
          thClass: 'username-whitelist text-center align-middle',
          tdClass: 'username-whitelist',
          sortable: true,
        },
        // {
        //   value: 'domain',
        //   text: 'Domain',
        //   thClass: 'domain-whitelist text-center',
        //   tdClass: 'domain-whitelist',
        //   sortable: true,
        // },
        //---------
        {
          value: 'subordination',
          text: 'สังกัด',
          thClass: 'subordination-whitelist text-center align-middle',
          tdClass: 'subordination-whitelist',
          sortable: true,
        },
        {
          value: 'postcode',
          text: 'รหัสไปรษณีย์',
          thClass: 'domain-whitelist text-center align-middle',
          tdClass: 'domain-whitelist',
          sortable: true,
        },

        {
          value: 'post_office',
          text: 'ที่ทำการ',
          thClass: 'domain-whitelist text-center align-middle',
          tdClass: 'domain-whitelist',
          sortable: true,
        },
        {
          value: 'root_call',
          text: 'หมายเลขผู้โทรออก',
          thClass: 'domain-whitelist text-center',
          tdClass: 'domain-whitelist',
          sortable: true,
        },
        {
          value: 'in_service',
          text: 'เบอร์โอนสายในเวลาทำการ',
          thClass: 'domain-whitelist text-center align-middle',
          tdClass: 'domain-whitelist',
          sortable: true,
        },
        {
          value: 'oos',
          text: 'เบอร์โอนสายนอกเวลาทำการ',
          thClass: 'domain-whitelist text-center align-middle',
          tdClass: 'domain-whitelist',
          sortable: true,
        },
        {
          value: 'work_date',
          text: 'วันทำการ',
          thClass: 'domain-whitelist text-center align-middle',
          tdClass: 'domain-whitelist',
          sortable: true,
        },
        {
          value: 'work_time',
          text: 'เวลาทำการ',
          thClass: 'worktime-whitelist text-center align-middle',
          tdClass: 'worktime-whitelist',
          sortable: true,
        },

        //-----------
        // {
        //   value: 'grp',
        //   text: 'Group',
        //   thClass: 'grp-whitelist text-center',
        //   tdClass: 'grp-whitelist',
        //   sortable: true,
        // },
        {
          value: 'last_modified',
          text: 'แก้ไขล่าสุด',
          thClass: 'last_modified-whitelist text-center align-middle',
          tdClass: 'last_modified-whitelist',
          sortable: true,
        },
        {
          // formatter: (value, value, datatable_whitelist) => {
          //   return value ? 'Yes' : 'No'
          // },
          sortable: true,
          sortByFormatted: true,
          filterByFormatted: true,
        },
        {
          value: 'action',
          text: 'แก้ไข',
          thClass: 'action-whitelist text-center align-middle',
          tdClass: 'action-whitelist',
          thStyle: {},
          sortable: false,
        },
      ],
    };
  },
  watch: {
    perPage(newVal) {
      this.getPaginationWhitelist();
    },
    currentPage(newVal){
      this.getPaginationWhitelist();
    }
  },
  async mounted() {
    // this.isThaipost = true
    this.isThaipost =
      window.location.hostname == 'thailandpost.smartcall.cloud' ? true : false;
    this.permissionUpdate();
    this.totalRows = this.datatable_whitelist.length;
    this.getPaginationWhitelist();
  },
  methods: {
    onFilter(e) {
      // console.log('onFilter: ', e)
      this.username = e.username;
      this.domain = e.domain;
      this.root_call = e.root_call;
      this.startWork = e.startWork;
      this.endWork = e.endWork;
      this.postcode = e.postcode;
      this.subordi = e.subordi;
      this.office = e.office;
      this.inservice_time = e.inservice_time;
      this.oos_time = e.oos_time;
      this.workDateOptions = e.workDateOptions;
      this.getPaginationWhitelist();
    },
    formatNum(e) {
      let formattedValue = String(e).substring(0, 10);
      // if (formattedValue.startsWith('0')) {
      //   formattedValue = formattedValue.slice(1);
      // }

      return formattedValue;
    },
    editUser(user) {
      this.$refs.editWhitelistModal.setData(user);
      this.$bvModal.show('editWhielist');
    },
    onSorted() {
      let by = '';
      if (!this.sortBy) {
        by = this.sorting.replace(/\s?(asc|desc)$/, '');
      } else {
            by = this.sortBy;
      }
      let sortType = 'asc';
      switch (this.sortDesc) {
        case true:
          sortType = 'desc';
          break;
        case false:
          sortType = 'asc';
          break;
      }
      this.sorting = by + ' ' + sortType
      console.log('sorting: ', this.sorting)
      this.getPaginationWhitelist();

    },
    permissionUpdate() {
      const permission = localStorage.getItem('permission');
      let result =
        permission.includes('can_update_whitelist') ||
        permission.includes('is_admin');
      if (!result) {
        this.btnPermission = true;
      } else {
        this.btnPermission = false;
      }
      // console.log('wl: ', result);
    },
    // onFiltered: Trigger pagination to update the number of buttons/pages due to filtering
    onFiltered(filteredItems) {
      this.totalRows = filteredItems.length;
      this.currentPage = page;
    },
    onPageChange(page) {
      this.currentPage = page;
      this.getPaginationWhitelist();
    },
    formatTime(time) {
      const dateObject = new Date(time);
      const year = dateObject.getFullYear();
      const month = (dateObject.getMonth() + 1).toString().padStart(2, '0'); // Month is zero-indexed
      const day = dateObject.getDate().toString().padStart(2, '0');
      const hours = dateObject.getHours().toString().padStart(2, '0');
      const minutes = dateObject.getMinutes().toString().padStart(2, '0');
      const seconds = dateObject.getSeconds().toString().padStart(2, '0');
      const formattedDateString = `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;

      return formattedDateString;
    },
    formatWorkDate(date) {
      const dayMapping = {
        1: 'จ',
        2: 'อ',
        3: 'พ',
        4: 'พฤ',
        5: 'ศ',
        6: 'ส',
        7: 'อา',
      };
      const arrayFromString = date.split('').map(Number);
      const result = arrayFromString.map((dayNumber) => dayMapping[dayNumber]);
      return result.join(',');
    },
    formatWorkTime(time) {
      const hoursStart = time.substring(0, 2);
      const minutesStart = time.substring(2, 4);

      const hoursEnd = time.substring(4, 6);
      const minutesEnd = time.substring(6, 8);

      const formattedResult = `${hoursStart}:${minutesStart}-${hoursEnd}:${minutesEnd}`;

      return formattedResult;
    },
    deleteColon(e) {
      return e.replace(/:/g, '');
    },
    async getExport() {
      if (this.isExport == 0) {
        this.isExport = 100;
        let workDateSeleted = [];
        let workTimeFinal = `${this.deleteColon(
          this.startWork
        )}${this.deleteColon(this.endWork)}`;

        this.workDateOptions.forEach((e) => {
          if (e.isCheck) {
            workDateSeleted.push(e.value);
          }
        });
        let webName = this.isThaipost ? 'whitelist' : 'smartcall';
        let workDate = workDateSeleted.join('');
        let url = `/whitelist/?total_rows=${this.totalRows}&total_pages=${this.total_pages}&page=${this.currentPage}&limit=${this.perPage}&username=${this.username}&part=${this.subordi}&zip_code=${this.postcode}&office=${this.office}&trans_num_in_time=${this.inservice_time}&trans_num_out_time=${this.oos_time}&biz_day=${workDate}&biz_time=${workTimeFinal}&root_call=${this.root_call}&sort=${this.sortBy}`;
        let header = '';
        if (this.isThaipost) {
          header =
            'เบอร์โทรศัพท์,สังกัด,รหัสไปรษณีย์,ที่ทำการ,หมายเลขผู้โทรออก,เบอร์โอนสายในเวลาทำการ,เบอร์โอนสายนอกเวลาทำการ,วันทำการ,เวลาทำการ,แก้ไขล่าสุด';
        } else {
          header =
            'เบอร์โทรศัพท์,แผนก,หมายเลขผู้โทรออก,เบอร์โอนสายในเวลาทำการ,เบอร์โอนสายนอกเวลาทำการ,วันทำการ,เวลาทำการ,แก้ไขล่าสุด';
        }
        await this.$refs.exportdata.loopGetData(
          url,
          'data_whitelist.csv',
          header,
          webName
        );
        this.isExport = 0;
      }
    },
    getPaginationWhitelist() {
      this.isLoading = true
      let workDateSeleted = [];
      let workTimeFinal = `${this.deleteColon(
        this.startWork
      )}${this.deleteColon(this.endWork)}`;

      this.workDateOptions.forEach((e) => {
        if (e.isCheck) {
          workDateSeleted.push(e.value);
        }
      });
      let workDate = workDateSeleted.join('');
      let phoneNum = '';
      // if (this.username != '') {
      //   if (this.username.startsWith('0')) {
      //     phoneNum = this.username.slice(1);
      //   } else {
      //     phoneNum = this.username;
      //   }
      // } else {
      //   phoneNum = this.username;
      // }
      var url = `/whitelist/?total_rows=${this.totalRows}&total_pages=${this.total_pages}&page=${this.currentPage}&limit=${this.perPage}&username=${this.username}&part=${this.subordi}&zip_code=${this.postcode}&office=${this.office}&trans_num_in_time=${this.inservice_time}&trans_num_out_time=${this.oos_time}&biz_day=${workDate}&biz_time=${workTimeFinal}&root_call=${this.root_call}&sort=${this.sorting}`;
      requestService
        .GetFetch(url)
        .then((response) => {
          let arr = response.data.data.rows.map((e) => {
            let splitData = e.domain.split('|');
            // console.log('splitData: ', splitData)
            if (e.domain == '||||||||') {
              let obj = {
                id: e.id,
                username: `${e.username}`,
                // domain: splitData[0],
                subordination: '',
                postcode: '',
                post_office: '',
                root_call: '',
                in_service: '',
                oos: '',
                work_date: '',
                work_time: '',
                grp: e.grp,
                last_modified: this.formatTime(e.last_modified),
                last_modified_raw: e.last_modified,
                // last_modified: '2000-01-01T00:00:01Z',
              };
              return obj;
            } else if (e.domain == '') {
              let obj = {
                id: e.id,
                username: `${e.username}`,
                // domain: splitData[0],
                subordination: '',
                postcode: '',
                post_office: '',
                root_call: splitData[4],
                in_service: '',
                oos: '',
                work_date: this.formatWorkDate('12345'),
                work_time: this.formatWorkTime('08001700'),
                grp: e.grp,
                last_modified: this.formatTime(e.last_modified),
                last_modified_raw: e.last_modified,
              };
              return obj;
            } else if (splitData[0] == '') {
              let obj = {
                id: e.id,
                username: `${e.username}`,
                // domain: splitData[0],
                subordination: splitData[1],
                postcode: splitData[2],
                post_office: splitData[3],
                root_call: splitData[4],
                in_service: splitData[5],
                oos: splitData[6],
                work_date: this.formatWorkDate(splitData[7]),
                work_time: this.formatWorkTime(splitData[8]),
                grp: e.grp,
                last_modified: this.formatTime(e.last_modified),
                last_modified_raw: e.last_modified,
              };
              return obj;
            } else {
              let obj = {
                id: e.id,
                username: `${e.username}`,
                // domain: splitData[0],
                subordination: splitData[0],
                postcode: splitData[1],
                post_office: splitData[2],
                root_call: splitData[4],
                in_service: splitData[3],
                oos: splitData[4],
                work_date: this.formatWorkDate(splitData[5]),
                work_time: this.formatWorkTime(splitData[6]),
                grp: e.grp,
                last_modified: this.formatTime(e.last_modified),
                last_modified_raw: e.last_modified,
              };
              return obj;
            }
          });

          this.isLoading = false
          this.datatable_whitelist = arr;
          this.totalRows = response.data.data.total_rows;
          this.total_pages = response.data.data.total_pages;
        })
        .catch((error) => {
          console.error(error);
        });
    },
    getDataTable() {
      let url = `/whitelist/`;
      requestService
        .GetFetch(url)
        .then((res) => {
          // console.log('getDataTable:', res.data.data);
          this.datatable_whitelist = [];
          this.datatable_whitelist = res.data.data.rows;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    postDataTable(arr) {
      // var wl = [];
      // wl.push(this.formGetDatatable);
      let url = '/whitelist/';
      requestService
        .PostFetch(url, arr)
        .then((res) => {
          this.$swal.fire({
            icon: 'success',
            title: 'เพิ่มข้อมูลสำเร็จ',
            showConfirmButton: false,
            timer: 1000,
          });
          this.getPaginationWhitelist();
          // this.getDataTable();
          // this.loadData();
          setTimeout(() => {
            this.$bvModal.hide('addWhielist');
          }, 500);
        })
        .catch((error) => {
          // console.log(error);
          this.$swal.fire({
            icon: 'error',
            title: 'มีบางอย่างผิดพลาด',
            text: 'ไม่สามารถเพิ่มข้อมูลได้',
            showConfirmButton: true,
            timer: 1500,
          });
        });
    },
    deleteDataTableWhitelist(item) {
      // console.log('item: ', item)
      this.$swal({
        title: 'คุณแน่ใจใช่หรือไม่?',
        text: 'หากลบแล้วจะไม่สามารถย้อนกลับได้',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'ลบข้อมูล',
        cancelButtonText: 'ยกเลิก',
      }).then((result) => {
        if (result.isConfirmed) {
          let domain = '';
          // let phoneNum = null;
          // if (item.username.startsWith('0')) {
          //   phoneNum = item.username.slice(1);
          // } else {
          //   phoneNum = item.username;
          // }
          // console.log(phoneNum);

          let url = `/whitelist/?grp=${item.grp}&username=${item.username}`;
          requestService
            .DeleteFetch(url)
            .then((res) => {
              this.getPaginationWhitelist();
              this.$swal.fire('ลบข้อมูลสำเร็จ');
            })
            .catch((error) => {
              console.log(error);
              this.$swal.fire('Error', 'ลบข้อมูลไม่สำเร็จ');
            });
        }
      });
    },
    clearFilter() {
      this.username = '';
      this.startWork = '';
      this.endWork = '';
      this.postcode = '';
      this.subordi = '';
      this.office = '';
      this.root_call = '';
      this.inservice_time = '';
      this.oos_time = '';
      this.workDateOptions.forEach((e) => {
        e.isCheck = false;
      });
      this.getPaginationWhitelist();
    },
    closeFilter() {
      this.username = '';
      this.startWork = '';
      this.endWork = '';
      this.postcode = '';
      this.subordi = '';
      this.office = '';
      this.workDateOptions.forEach((e) => {
        e.isCheck = false;
      });
    },
    async getFiltersWhitelist() {
      await this.getPaginationWhitelist();
    },
  },
};
</script>

<style scoped>
#paginationDaily >>> .theme--light.v-pagination .v-pagination__item--active {
  background-color: #3f60fb !important;
  color: white;
}
.totalRow {
  height: 25px !important;
}
.deleteBtnPer {
  width: 50px;
  background-color: #f83366 !important;
  border: none !important;
  font-size: 8px !important;
  padding: 4px !important;
}
.btnGlobal {
  width: 50px;
  border: none !important;
  font-size: 8px !important;
  padding: 4px !important;
}
.deleteBtnNoPer {
  width: 50px;
  border: none !important;
  background-color: #d0d4ca !important;
  font-size: 8px !important;
  padding: 4px !important;
}

.editBtnPer {
  width: 50px;
  background-color: #ffa33c !important;
  border: none !important;
  font-size: 8px !important;
  padding: 4px !important;
}
.addBtnNoPer {
  background-color: #d0d4ca !important;
  border: none !important;
}
</style>
<style>
.btnNoPer {
  background-color: #d0d4ca !important;
}

.btnPer {
  background-color: #1b4afa !important;
}

#export-csv-whitlist-btn-z {
  width: 82px;
  height: 24px;
  margin: 2px 0 0 0;
}

.inputNum::-webkit-inner-spin-button,
inputNum::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.three-dots-vertical {
  color: #434343ae;
}

.no-border {
  background-color: transparent;
  border: none;
}

.search-filter-whitlist-z {
  font-size: 5px;
  padding: 0.1rem 0.05rem;
}

#clear-btable-whitlist-btn-z {
  color: #f83366;
  background-color: #feeaef !important;
  border-color: #feeaef !important;
}

#add-whitlist-btn-z {
  width: 82px;
  height: 24px;
  margin: 2px 0 0 0;
  /* background-color: #3F60FB !important; 
    border-color: #3F60FB !important; */
}

#filter-whitlist-btn-z {
  width: 82px;
  height: 24px;
  margin: 1px 0 0 0;
  /* background-color: #3F60FB !important; 
    border-color: #3F60FB !important; */
}

#submit-add-whitelist-btn-z {
  color: #3f60fb;
  background-color: #dfedff !important;
  border-color: #dfedff !important;
}

#search-filter-whitelist-btn-z {
  color: #3f60fb;
  background-color: #dfedff !important;
  border-color: #dfedff !important;
  /* color: #ffffff;
  background-color: #3f60fb !important;
  border-color: #3f60fb !important; */
}

#clear-filter-whitelist-btn-z {
  color: #f83366;
  background-color: #feeaef !important;
  border-color: #feeaef !important;
}

/* ========== dropdown b-table ========== */
.dropdown-toggle {
  padding: initial !important;
  height: 15px;
}

/* ========== dropdown b-table ========== */
/* ========== three-dots dropdown b-table ========== */
#popover-three-dots-vertical-whitelist {
  font-size: 75%;
}

.table-xs tbody tr td div div {
  left: 8px;
  top: 0;
}

/* ========== three-dots dropdown b-table ========== */

div#dropdown-delete-whitelist ul.dropdown-menu.show {
  min-width: 5rem;
}

.filter-border-z {
  color: #388eff;
  border-bottom: 1px solid #1279ff;
  border-bottom-width: 2px;
  margin: 0 0 0 0;
  height: 21px;
}

#search_filter_whitelist_username {
  border-radius: 3px;
  /* Material Design Shadows/1 */
  box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.2),
    0px 0.10000000149011612px 0.30000001192092896px 0px rgba(0, 0, 0, 0.1);
}

#search_filter_whitelist_domain {
  border-radius: 3px;
  /* Material Design Shadows/1 */
  box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.2),
    0px 0.10000000149011612px 0.30000001192092896px 0px rgba(0, 0, 0, 0.1);
}

#search_filter_whitelist_grp {
  border-radius: 3px;
  /* Material Design Shadows/1 */
  box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.2),
    0px 0.10000000149011612px 0.30000001192092896px 0px rgba(0, 0, 0, 0.1);
}

/* ========== Filter dropdown-menu ========== */

/* ==================== b-table whitelist_page ==================== */
div.table-whitelist table.table-whitelist thead tr th {
  /* width: auto; */
  padding: 2px 33px 2px 14px;
}

/* div.table-whitelist table.table-whitelist thead tr th div {
  width: auto;
  padding: 0px 1px 0px 1px;
  font-size: 10px;
} */
/* div.table-whitelist table.table-whitelist tbody tr td {
  width: 10px;
  padding: 2px 1px 2px 1px;
} */
div.table-whitelist table.table-whitelist tbody tr td div {
  /* width: auto; */
  /* padding: 3.2px 32px; */
  margin: 0 0 0 -8px;
  font-size: 10px;
}

div.table-whitelist table.table-whitelist tbody tr td div div.action-whitelist {
  /* width: auto; */
  text-align: center;
  /* background-color: #f83366; */
}

.id-whitelist {
  /* width: auto; */
  width: 40px;
}

.username-whitelist {
  /* width: auto; */
  width: 100px;
}

.domain-whitelist {
  width: auto;
}

.worktime-whitelist {
  width: 120px;
}

.grp-whitelist {
  /* width: auto; */
  width: 50px;
}

.last_modified-whitelist {
  /* width: auto; */
  width: 120px;
}

.action-whitelist {
  width: 0;
}

/* ==================== b-table whitelist_page ==================== */

/* @media only screen and (min-width: 721px) and (max-width: 1080px) {
  div #clear-filter-whitelist-btn-z {
    width: 1em; height: 1em;
  }

} */
@media only screen and (max-width: 991px) {
  div.col div.custom-file label.custom-file-label {
    width: 59em;
  }

  div.modal-dialog div.row.justify-content-between div.row {
    padding: 0 0 0 12px;
  }
}

@media only screen and (max-width: 720px) {
  div.col div.custom-file label.custom-file-label {
    width: 59.2em !important;
  }
}

@media only screen and (min-width: 480px) and (max-width: 991px) {
  div.col div.custom-file label.custom-file-label {
    width: 54.7em;
  }

  div.modal-dialog div.row.justify-content-between div.row {
    padding: 0 0 0 12px;
  }

  div.modal-body form.needs-validation div.form-row div.media-css {
    margin: 0px 0px 0px 0px !important;
  }

  form.needs-validation div.form-row div.row div.d-flex {
    /* add-button-submit */
    margin: 11px 0 0px 0px;
  }

  div.table-responsive table.table tbody tr td.action-whitelist {
    padding: 0px;
  }
}
</style>
