<template>
  <div>
    <!-- page-wrapper Start-->
    <!-- error-403 start-->
    <div class="error-wrapper addspace">
      <div class="w-100">
        <img
          class="img-100"
          src="../assets/images/other-images/sad.png"
          alt=""
        />
        <div class="error-heading mb-0">
          <h2 class="headline font-success">403</h2>
        </div>
        <div class="col-md-8 offset-md-2">
          <p class="sub-content">
            You don't have a permission to access this page.
          </p>
        </div>
      </div>
    </div>
    <!-- error-403 end-->
  </div>
</template>
<script>
export default {};
</script>

<style scoped>
.addspace {
  padding-bottom: 100px;
}

.sub-content {
  font-size: 1.5rem;
  color: #514f4f;
}
</style>
