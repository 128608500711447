export const defaultLocale = 'en';
export const localeOptions = [
  { id: 'en', name: 'English' },
  { id: 'es', name: 'Español' },
];

export const host = process.env.NODE_ENV === 'production' ? '/api' : 'https://thailandpost.smartcall.cloud/api';

// export const host = '/api';
// export const host = 'https://thailandpost.smartcall.cloud/api';
