<template>
  <div>
    <b-modal
      id="edit-user-modal"
      ref="edituser"
      title="Edit User"
      class="theme-modal"
      hide-footer
      @hidden="onReset"
      @shown="showUserData"
      header-close-label="close"
    >
      <!-- {{ indexRow }}
      {{ useritems }} -->
      <!-- {{ userdata }} -->
      <div>
        <b-form @submit="onsubmit" @reset="cancelClick" v-if="show">
          <div class="d-flex">
            <!-- First Name -->
            <b-form-group
              id="input-group-2"
              label-for="input-2"
              class="mr-2 w-100"
            >
              <p class="text-size">First Name</p>
              <b-form-input
                id="firstname"
                v-model="user.firstname"
                placeholder="Enter First Name"
                required
              ></b-form-input>
            </b-form-group>
            <!-- Last Name -->
            <b-form-group id="input-group-2" label-for="input-2" class="w-100">
              <p class="text-size">Last Name</p>
              <b-form-input
                id="lastname"
                v-model="user.lastname"
                placeholder="Enter Last Name"
                required
              ></b-form-input>
            </b-form-group>
          </div>

          <!-- Email -->

          <b-form-group id="input-group-1" label-for="input-1">
            <p class="text-size">Email Address</p>
            <b-form-input
              id="input-1"
              v-model="user.email"
              type="email"
              placeholder="Enter email"
              required
              class="mt-0"
            ></b-form-input>
          </b-form-group>

          <!-- Password -->
          <div class="passwordBox mb-2">
            <b-form-group
              id="password-input-group"
              label-for="password-input-1"
            >
              <p class="text-size">New Password</p>
              <b-form-input
                id="password-input"
                v-model="user.newPassword"
                type="password"
                placeholder="Enter Password"
                @input="checkPassword"
              ></b-form-input>
              <!-- required -->
            </b-form-group>
            <div class="passwordValidator font-weight-light">
              <ul>
                <li v-bind:class="{ is_valid: contains_eight_characters }">
                  8 Characters
                </li>
                <li v-bind:class="{ is_valid: contains_number }">
                  Contains Number
                </li>
                <li v-bind:class="{ is_valid: contains_uppercase }">
                  Contains Uppercase
                </li>
                <li v-bind:class="{ is_valid: contains_special_character }">
                  Contains Special Character
                </li>
              </ul>
            </div>
          </div>

          <!-- Confirm Password -->

          <b-form-group id="input-group-1" label-for="input-1">
            <p class="text-size">Confirm New Password</p>
            <!-- {{ user.confirmPassword }} -->
            <b-form-input
              id="confirmpassword-input"
              v-model="user.confirmPassword"
              type="password"
              placeholder="Enter Confirm Password"
              @input="checkConfirm"
            ></b-form-input>
            <!-- required -->
          </b-form-group>


          <!-- Button -->
          <div class="d-flex justify-content-end">
            <b-button class="mr-3" type="submit" variant="primary"
              >Save</b-button
            >
          </div>
        </b-form>
      </div>
    </b-modal>
  </div>
</template>

<script>
import { requestService } from '../../_services/request.service';

export default {
  name: 'EditUser',
  props: ['userdata'],
  data() {
    return {
      password_length: 0,
      contains_eight_characters: false,
      contains_number: false,
      contains_uppercase: false,
      contains_special_character: false,
      btnDisabled: true,
      user: {
        firstname: null,
        lastname: null,
        email: null,
        newPassword: '',
        confirmPassword: '',
      },
      show: true,
    };
  },
  methods: {
    showUserData() {
      this.user.firstname = this.userdata.firstname;
      this.user.lastname = this.userdata.lastname;
      this.user.email = this.userdata.email;
    },
    checkPassword() {
      this.password_length = this.user.newPassword.length;
      const format = /[ !@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]/;
      if (this.password_length >= 8) {
        this.contains_eight_characters = true;
      } else {
        this.contains_eight_characters = false;
      }
      this.contains_number = /\d/.test(this.user.newPassword);
      this.contains_uppercase = /[A-Z]/.test(this.user.newPassword);
      this.contains_special_character = format.test(this.user.newPassword);
    },
    checkConfirm() {
      if (this.user.confirmPassword == this.user.newPassword) {
        this.btnDisabled = false;
      } else {
        this.btnDisabled = true;
      }
    },
    onsubmit(e) {
      e.preventDefault();
      const id = this.userdata.ID;
      const editUser = {
        email: this.user.email,
        firstname: this.user.firstname,
        lastname: this.user.lastname,
        new_password: this.user.newPassword,
        confirm_new_password: this.user.confirmPassword,
      };

      requestService
        .PatchFetch(`/auth/user_management/${id}`, editUser)
        .then((res) => {
          this.$emit('editUserData');
          let payload = {
            firstname: this.user.firstname,
            lastname: this.user.lastname,
          };
          this.onReset();
          this.hideModal();
          this.$store.dispatch('SET_NAME', payload);
          // localStorage.setItem('firstname',)
          // localStorage.setItem('lastname',)
          console.log('edit user: ', res);
        })
        .catch((err) => {
          console.log('err: ', err);
        });
    },
    cancelClick() {
      this.onReset();
      this.hideModal();
    },
    // onSubmit(event) {
    //   event.preventDefault();
    //   const newUser = {
    //     email: this.user.email,
    //     import: this.user.can_whitelistChecked,
    //     export: this.user.can_updatewhitelistChecked,
    //     add: this.user.can_reportChecked,
    //     report: this.user.reportChecked,
    //     whitelist: this.user.whitelistChecked,
    //   };
    //   this.$emit("addNewUser", newUser);

    //   // this.hideModal();
    //   this.onReset();
    // },
    onReset(event) {
      this.user.email = '';
      this.user.newPassword = '';
      this.user.confirmPassword = '';
      this.user.firstname = '';
      this.user.lastname = '';
      // this.user.can_whitelistChecked = false;
      // this.user.can_updatewhitelistChecked = false;
      // this.user.can_reportChecked = false;
      // this.user.reportChecked = false;
      // this.user.whitelistChecked = false;

      this.contains_eight_characters = false;
      this.contains_number = false;
      this.contains_uppercase = false;
      this.contains_special_character = false;
      this.btnDisabled = true;
      // Trick to reset/clear native browser newUser validation state
      this.show = false;
      this.$nextTick(() => {
        this.show = true;
      });
    },

    hideModal() {
      this.$refs['edituser'].hide();
      // console.log('hide');
    },
  },
};
</script>

<style>
.text-size {
  font-size: 0.9rem;
  font-weight: 500;
  margin: 0;
}

.container-permission {
  margin-top: 5px;
  margin-bottom: 2rem;
  display: flex;
  gap: 5rem;
}

.passwordValidator > ul {
  color: #888888;
  padding-left: 20px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  list-style-type: disc;
}

input[type='password'] {
  line-height: 1.5;
  display: block;
  color: rgba(136, 152, 170, 1);
  font-weight: 300;
  width: 100%;
  height: calc(2.75rem + 2px);
  padding: 0.625rem 0.75rem;
  border-radius: 0.25rem;
  background-color: #fff;
  transition: border-color 0.4s ease;
  border: 1px solid #cad1d7;
  outline: 0;
}

input[type='password']:focus {
  border-color: rgba(50, 151, 211, 0.45);
}

/* Checkmark & Strikethrough --------- */

.is_valid {
  color: #58ca28;
  transition-duration: 0.5s;
}
.is_valid:before {
  width: 100%;
}
</style>
