<template>
  <div>
    <Breadcrumbs title="การตั้งค่า" />
    <div class="container-fluid mb-2">
      <div class="row">
        <div class="col-md-12">
          <div class="card">
            <div class="card-body">
              <div class="mt-3">
                <!-- Cache Expire Time -->
                <div class="row">
                  <div class="col">
                    <div class="form-group wrapper">
                      <div class="text-box">
                        <label>Cache Expire Time:</label>
                      </div>
                      <div class="input-box">
                        <b-form-input v-model="cacheexp" type="number" class="input-data"></b-form-input>
                      </div>
                      <b-button id="default-primary" variant="primary" class="btnUpdate"
                        @click="updateCache">Update</b-button>
                    </div>
                  </div>
                </div>
                <!-- Cache Expire Time Ends -->
                <!-- Time Before Transfer -->
                <!-- Time Before Transfer Ends -->
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { requestService } from '.././_services/request.service';

export default {
  name: 'SettingPage',
  data() {
    return {
      cacheexp: null,
      // timebefore: null,
    };
  },
  mounted() {
    this.getSetting();
  },
  methods: {
    updateCache() {
      //callbacktime
      let url = `/settings/callbacktime?value=${this.cacheexp}`;
      // console.log('timeb4', url);
      requestService
        .PatchFetch(url)
        .then((res) => {
          this.$fire({
            title: 'Updated',
            type: 'success',
            showConfirmButton: false,
            timer: 3000,
          });
          // console.log('patch timeb4: ', res);
        })
        .catch((err) => {
          console.log('timeb4 err: ', err);
        });
    },
    // updateTimeBefore() {
    //   //fr_inv_timer
    //   let url = `/settings/fr_inv_timer?value=${this.timebefore}`;
    //   // console.log('Cache', url);
    //   requestService
    //     .PatchFetch(url)
    //     .then((res) => {
    //       this.$fire({
    //         title: 'Updated',
    //         type: 'success',
    //         showConfirmButton: false,
    //         timer: 3000,
    //       });
    //     })
    //     .catch((err) => {
    //       console.log('cache err: ', err);
    //     });
    // },

    getSetting() {
      let url = `/auth/settings`;
      requestService.GetFetch(url).then((res) => {
        let data = res.data.data;
        data.forEach((e) => {
          if (e.key == 'callbacktime') {
            this.cacheexp = e.value;
          }
          // else {
          //   this.timebefore = e.value;
          // }
        });
      });
    },
  },
};
</script>

<style scoped>
.card-body {
  padding: 40px !important;
}

.wrapper {
  display: flex;
  align-items: center;
  gap: 20px;
}

label {
  font-size: 14px;
}

.text-box {
  width: 17%;
  margin-top: 10px;
}

.input-box {
  width: 10rem;
}

.input-data {
  font-size: 14px;
}

.input-data::-webkit-inner-spin-button,
input-data::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.btnUpdate {
  font-size: 14px;
}
</style>
