<template>
  <div></div>
</template>

<script>
import { requestService } from '../_services/request.service';
import * as XLSX from 'xlsx';

export default {
  name: 'exportCSV',
  data() {
    return {};
  },

  mounted() {},

  methods: {
    async loopGetData(url, filename, header, type) {
      let arr1 = [];
      let arr2 = [];
      let last_page = null;
      let sumReport = {};

      await requestService.GetFetch(url).then((res) => {
        arr1 = res.data.data.rows;
        last_page = res.data.data.total_pages;
        if (res.data.data.sum_report) {
          sumReport = res.data.data.sum_report;
        }
      });

      for (let i = 2; i <= last_page; i++) {
        let url2 = `${url}&page=${i}`;
        await requestService.GetFetch(url2).then((res) => {
          arr2 = res.data.data.rows;
        });
        arr1 = arr1.concat(arr2);
      }

      switch (type) {
        case 'whitelist':
          arr1 = arr1.map((e) => {
            let splitData = e.domain.split('|');
            if (e.domain == '||||||||') {
              return {
                username: `${e.username}`,
                subordination: '',
                postcode: '',
                post_office: '',
                root_call: '',
                in_service: '',
                oos: '',
                work_date: '',
                work_time: '',
                last_modified: this.formatTime(e.last_modified),
              };
            } else if (splitData[0] == '') {
              return {
                username: `${e.username}`,
                subordination: splitData[1],
                postcode: splitData[2],
                post_office: splitData[3],
                root_call: splitData[4],
                in_service: splitData[5],
                oos: splitData[6],
                work_date: this.formatWorkDate(splitData[7]),
                work_time: this.formatWorkTime(splitData[8]),
                last_modified: this.formatTime(e.last_modified),
              };
            } else {
              return {
                username: `${e.username}`,
                subordination: splitData[0],
                postcode: splitData[1],
                post_office: splitData[2],
                root_call: splitData[4],
                in_service: splitData[3],
                oos: splitData[4],
                work_date: this.formatWorkDate(splitData[5]),
                work_time: this.formatWorkTime(splitData[6]),
                last_modified: this.formatTime(e.last_modified),
              };
            }
          });
          break;

        case 'smartcall':
          arr1 = arr1.map((e) => {
            let splitData = e.domain.split('|');
            return {
              username: `${e.username}`,
              post_office: splitData[3],
              root_call: splitData[4],
              in_service: splitData[5],
              oos: splitData[6],
              work_date: this.formatWorkDate(splitData[7]),
              work_time: this.formatWorkTime(splitData[8]),
              last_modified: this.formatTime(e.last_modified),
            };
          });
          break;

        case 'callActivity':
          arr1 = arr1.map((e) => {
            let direct = e.direction == 'In' ? 'Inbound' : 'Outbound';
            return {
              call_start_time: this.formatTime(e.call_start_time),
              call_end_time: this.formatTime(e.call_end_time),
              direction: direct,
              src: e.src,
              dst: e.dst,
              status: e.status,
              ring: e.ring,
              talk: e.talk,
              forward: e.forward,
            };
          });
          break;

        case 'dailyReport_v2':
        case 'intervalReport_v2':
          arr1 = await Promise.all(
            arr1.map(async (item) => {
              const denominator = item.answered_calls + item.abandoned_calls;
              const perAnsweredCalls = denominator
                ? ((item.answered_calls / denominator) * 100).toFixed(2)
                : 0;
              const perAbandonCalls = denominator
                ? ((item.abandoned_calls / denominator) * 100).toFixed(2)
                : 0;
              const talkTime =
                item.answered_calls == 0 ? 0 : item.talk / item.answered_calls;
              const averageTalkTime = await this.convertAverageTalkTime(
                talkTime
              );

              let newItem = {};
              header.forEach(({ name, key }) => {
                if (key === 'per_answered_calls') {
                  newItem[name] = perAnsweredCalls;
                } else if (key === 'per_abandon_calls') {
                  newItem[name] = perAbandonCalls;
                } else if (key === 'average_talk_time') {
                  newItem[name] = averageTalkTime;
                } else {
                  newItem[name] = item[key] !== undefined ? item[key] : '';
                }
              });

              return newItem;
            })
          );

          let obj2 = {
              Date: 'รวม',
              'Total Calls': sumReport.total,
              'Outbound Calls': sumReport.total_out_call,
              'Inbound Calls': sumReport.total_in_call,
              'Answered Calls':
                sumReport.total_answered_out_call +
                sumReport.total_answered_in_call,
              'No Answered Calls':
                sumReport.total_abandoned_out_call +
                sumReport.total_abandoned_in_call,
              '%Answered Calls': sumReport.total_answered_per,
              '%No Answered Calls': sumReport.total_abandoned_per,
              'Average Talk Time': sumReport.total_average_talk_time,
            }

            arr1.push(obj2)
            header = header.map((e) => e.name).join(',');
          break;

        case 'monthlyReport':
          this.exportToExcel(arr1,sumReport, filename);
          break;

        default:
          console.log(`Unknown type: ${type}`);
          break;
      }
      if (type != 'monthlyReport') {
        console.log('arr1', arr1);
        this.getExportCSV(arr1, filename, header);
      }
    },
    exportToExcel(data,sumReport, filename) {
      const worksheet = XLSX.utils.aoa_to_sheet([
        [
          'วันที่',
          'Total',
          'สายโทรออก(จนจ.โทรหาผู้รับ)',
          '',
          '',
          '',
          '',
          'สายโทรเข้า (ผู้รับโทรกลับ 1505)',
          '',
          '',
          '',
          '',
        ],
        [
          '',
          '',
          'รวม',
          '(ผู้รับสาย)',
          '% การรับสาย',
          '(ผู้รับไม่รับสาย)',
          '% การไม่รับสาย',
          'รวม',
          '(จนจ.รับสาย)',
          '% การรับสาย',
          '(จนจ.ไม่รับสาย)',
          '% การไม่รับสาย',
        ],
      ]);

      let rows = data.map((e) => {
        let denominatorOut =
          e.answered_out_bound_calls + e.abandoned_out_bound_calls;
        let denominatorInb =
          e.answered_in_bound_calls + e.abandoned_in_bound_calls;

        return [
          e.date,
          e.total_in_out_calls,
          
          e.total_out_bound_calls,
          e.answered_out_bound_calls,
          denominatorOut
            ? ((e.answered_out_bound_calls / denominatorOut) * 100).toFixed(2)
            : 0,
          e.abandoned_out_bound_calls,
          denominatorOut
            ? ((e.abandoned_out_bound_calls / denominatorOut) * 100).toFixed(2)
            : 0,
            e.total_in_bound_calls,
          e.answered_in_bound_calls,
          denominatorInb
            ? ((e.answered_in_bound_calls / denominatorInb) * 100).toFixed(2)
            : 0,
          e.abandoned_in_bound_calls,
          denominatorInb
            ? ((e.abandoned_in_bound_calls / denominatorInb) * 100).toFixed(2)
            : 0,
        ];
      });

      let arr_report = [
        'รวม',
        sumReport.total,
        sumReport.total_out_call,
        sumReport.total_answered_out_call,
        sumReport.total_answered_out_per,
        sumReport.total_abandoned_out_call,
        sumReport.total_abandoned_out_per,
        sumReport.total_in_call,
        sumReport.total_answered_in_call,
        sumReport.total_answered_in_per,
        sumReport.total_abandoned_in_call,
        sumReport.total_abandoned_in_per,
        ]



      rows.push(arr_report)
      XLSX.utils.sheet_add_aoa(worksheet, rows, { origin: -1 });

      worksheet['!merges'] = [
        { s: { r: 0, c: 0 }, e: { r: 1, c: 0 } },
        { s: { r: 0, c: 1 }, e: { r: 1, c: 1 } },
        { s: { r: 0, c: 2 }, e: { r: 0, c: 6 } },
        { s: { r: 0, c: 7 }, e: { r: 0, c: 11 } },
      ];

      const workbook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');

      XLSX.writeFile(workbook, filename);
    },
    async getExportCSV(arr, filename, header) {
      const csvContent = JSON.stringify(arr);
      const csvDataDaily = await this.convertToCSV(arr, header);
      const blob = new Blob(['\ufeff' + csvDataDaily], {
        type: 'text/csv;charset=utf-8;',
      });
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = filename;
      document.body.appendChild(a);
      a.click();
      window.URL.revokeObjectURL(url);
      document.body.removeChild(a);
    },
    async convertToCSV(array, header) {
      // var array = typeof objArray != 'object' ? JSON.parse(objArray) : objArray;
      var str = '';
      str += `${header} \r\n`;
      for (var i = 0; i < array.length; i++) {
        var line = '';
        for (var index in array[i]) {
          if (line !== '') line += ',';
          console.log('in: ', index)
          if (index === 'work_date') {
            const workDateValues = array[i][index].split(',');
            line += `"${workDateValues.join(',')}"`;
          } else {
            const value =
              index === 'per_abandon_calls' &&
              typeof array[i][index] === 'number'
                ? ` ${array[i][index].toFixed(2)}`
                : array[i][index];

            line += `${value}`;
          }
        }

        str += line + '\r\n';
      }

      return str;
    },
    passNumberNoZeroExcel(value) {
      var str_length = value;
      var srt_dump = '';
      if (!isNaN(str_length)) {
        if (str_length.length) {
          for (var i = 0; i < str_length.length; i++) {
            srt_dump += '0';
          }
        }
        return '=TEXT(' + value + ',"' + srt_dump + '")';
      } else {
        return str_length;
      }
    },
    formatWorkDate(date) {
      const dayMapping = {
        1: 'จ',
        2: 'อ',
        3: 'พ',
        4: 'พฤ',
        5: 'ศ',
        6: 'ส',
        7: 'อา',
      };
      const arrayFromString = date.split('').map(Number);
      const result = arrayFromString.map((dayNumber) => dayMapping[dayNumber]);
      return result.join(',');
    },
    formatWorkTime(time) {
      const hoursStart = time.substring(0, 2);
      const minutesStart = time.substring(2, 4);

      const hoursEnd = time.substring(4, 6);
      const minutesEnd = time.substring(6, 8);

      const formattedResult = `${hoursStart}:${minutesStart}-${hoursEnd}:${minutesEnd}`;

      return formattedResult;
    },
    formatTime(time) {
      const dateObject = new Date(time);
      const year = dateObject.getFullYear();
      const month = (dateObject.getMonth() + 1).toString().padStart(2, '0'); // Month is zero-indexed
      const day = dateObject.getDate().toString().padStart(2, '0');
      const hours = dateObject.getHours().toString().padStart(2, '0');
      const minutes = dateObject.getMinutes().toString().padStart(2, '0');
      const seconds = dateObject.getSeconds().toString().padStart(2, '0');

      // Create the formatted date string
      const formattedDateString = `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
      return formattedDateString;
    },
    convertAverageTalkTime(seconds) {
      const totalSeconds = Math.floor(seconds);
      const hours = Math.floor(totalSeconds / 3600);
      const minutes = Math.floor((totalSeconds % 3600) / 60);
      const remainingSeconds = totalSeconds % 60;

      const paddedHours = String(hours).padStart(2, '0');
      const paddedMinutes = String(minutes).padStart(2, '0');
      const paddedSeconds = String(remainingSeconds).padStart(2, '0');

      return `${paddedHours}:${paddedMinutes}:${paddedSeconds}`;
    },
  },
};
</script>

<style lang="scss" scoped></style>
