<template>
  <div>
    <Breadcrumbs title="การจัดการบัญชีผู้ใช้" />

    <!-- Container-fluid starts-->
    <div class="container-fluid mb-2">
      <div class="row">
        <div class="col-md-12">
          <div class="card">
            <EditUser :userdata="userdata" @editUserData="editUserData" />
            <div class="card-body">
              <!-- Table -->
              <b-row>
                <b-col>
                  <div class="d-flex justify-content-between w-100 pt-1">
                    <div class="w-50">
                      <!-- Search -->
                      <div class="row ml-2">
                        <b-form-input
                          class="col-5 searchinput"
                          v-model="filter"
                          placeholder="ค้นหาข้อมูล"
                          @input="filteredData"
                        ></b-form-input>
                        <b-button
                          class="col-2"
                          :disabled="!filter"
                          @click="filter = ''"
                          id="btn-clear"
                          pill
                          >ล้าง</b-button
                        >
                      </div>
                    </div>
                    <!-- Modal -->
                    <div>
                      <b-button
                        v-b-modal.adduser
                        variant="primary"
                        class="mb-2 btn-adduser"
                        pill
                        >+ เพิ่มบัญชีผู้ใช้</b-button
                      >
                      <AddUser @addNewUser="addNewUser" />
                    </div>
                    <!-- Modal Ends -->
                  </div>
                </b-col>
              </b-row>

              <!-- Table Data -->
              <div class="daily-report table-responsive">
                <v-data-table
                  id="usermanageTable"
                  :headers="headers"
                  :items="items"
                  :page.sync="currentPage"
                  hide-default-footer
                  :items-per-page="perPage"
                  @page-count="pageCount = $event"
                  class="elevation-1"
                  :loading="isLoading"
                >
                  <template v-slot:loading>
                    <v-progress-circular
                      indeterminate
                      color="primary"
                      size="40"
                      class="ma-2"
                    ></v-progress-circular>
                  </template>
                  <template slot="no-data"> ไม่มีข้อมูล.. </template>
                  <template v-slot:item.email="{ item }">
                    <div
                      class="text-start"
                      style="width: 160px; font-size: 10px"
                    >
                      {{ item.email }}
                    </div>
                  </template>
                  <template v-slot:item.can_whitelist="{ item }">
                    <div
                      class="text-center p-0 m-0"
                      style="width: 140px; font-size: 10px"
                    >
                      <toggle-button
                        color="#3F60FB"
                        v-model="item.can_whitelist"
                        :sync="true"
                        :width="38"
                        :height="17"
                        @change="toggleChange(item, 'can_whitelist')"
                      />
                    </div>
                  </template>
                  <template v-slot:item.can_updatewhitelist="{ item }">
                    <div
                      class="text-center"
                      style="width: 140px; font-size: 10px"
                    >
                      <toggle-button
                        color="#3F60FB"
                        v-model="item.can_update_whitelist"
                        :sync="true"
                        :width="38"
                        :height="17"
                        @change="toggleChange(item, 'can_update_whitelist')"
                      />
                    </div>
                  </template>
                  <template v-slot:item.can_report="{ item }">
                    <div
                      class="text-center"
                      style="width: 120px; font-size: 10px"
                    >
                      <toggle-button
                        color="#3F60FB"
                        v-model="item.can_report"
                        :sync="true"
                        :width="38"
                        :height="17"
                        @change="toggleChange(item, 'can_report')"
                      />
                    </div>
                  </template>

                  <template v-slot:item.can_exportreport="{ item }">
                    <div
                      class="text-center"
                      style="width: 140px; font-size: 10px"
                    >
                      <toggle-button
                        color="#3F60FB"
                        v-model="item.can_export_report"
                        :sync="true"
                        :width="38"
                        :height="17"
                        @change="toggleChange(item, 'can_export_report')"
                      />
                    </div>
                  </template>
                  <template v-slot:item.is_admin="{ item }">
                    <div
                      class="text-center"
                      style="width: 80px; font-size: 10px"
                    >
                      <toggle-button
                        color="#3F60FB"
                        v-model="item.is_admin"
                        :sync="true"
                        :width="38"
                        :height="17"
                        @change="toggleChange(item, 'is_admin')"
                      />
                    </div>
                  </template>
                  <template v-slot:item.action="{ item }">
                    <div
                      class="text-center"
                      style="width: 130px; font-size: 10px"
                    >
                      <!-- {{ row.item.ID }} -->
                      <b-dropdown
                        variant="transparent"
                        no-caret
                        toggle-class="px-1"
                        class="mb-2 mr-3"
                      >
                        <!-- rounded-circle px-2  -->
                        <template #button-content>
                          <b-icon
                            icon="three-dots-vertical"
                            style="margin-left: 1.7vw"
                          />
                        </template>
                        <!-- drop down -->
                        <b-dropdown-item
                          v-b-modal.edit-user-modal
                          @click="handleDropdownItemClick(item)"
                          ><feather
                            style="width: 15px; height: 15px"
                            type="edit"
                          ></feather>
                          แก้ไข</b-dropdown-item
                        >
                        <!-- v-b-modal.delete-user-modal -->
                        <b-dropdown-item @click="deleteUser(item.ID)"
                          ><feather
                            style="width: 15px; height: 15px"
                            type="trash-2"
                          ></feather>
                          ลบ</b-dropdown-item
                        >
                      </b-dropdown>
                    </div>
                  </template>
                </v-data-table>
                <div class="text-center pt-2 sm">
                  <b-pagination
                    v-model="currentPage"
                    :total-rows="totalRows"
                    :per-page="perPage"
                    align="center"
                    @input="getAllUser"
                    class="justify-content-center"
                    size="sm"
                    aria-controls="my-table"
                  ></b-pagination>
                </div>
              </div>
              <!-- Table Ends -->
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- Container-fluid Ends-->
  </div>
</template>

<script>
import { requestService } from '../../_services/request.service';
import EditUser from '../../components/usermanagement/edituser';
import AddUser from '../../components/usermanagement/adduser';

export default {
  components: {
    EditUser,
    AddUser,
  },
  data() {
    return {
      isLoading: false,
      currentPage: 1,
      totalRows: null,
      totalPage: null,
      page: 1,
      filter: null,
      perPage: 10,
      selectAll: false,
      userdata: null,
      // ===============
      headers: [
        {
          text: 'Email',
          value: 'email',
          sortable: true,
          thStyle: { width: '18%' },
          thClass: 'text-center emailhead',
        },
        {
          text: 'Can View Whitelist',
          value: 'can_whitelist',
          thStyle: { width: '19%' },
          thClass: 'text-center',
          sortable: false,
        },
        {
          text: 'Can Update Whitelist',
          value: 'can_updatewhitelist',
          thStyle: { width: '19%' },
          thClass: 'text-center',
          sortable: false,
        },
        {
          text: 'Can View Report',
          value: 'can_report',
          thStyle: { width: '15%' },
          thClass: 'text-center',
          sortable: false,
        },
        {
          text: 'Can Export Report',
          value: 'can_exportreport',
          thStyle: { width: '17%' },
          thClass: 'text-center',
          sortable: false,
        },
        {
          text: 'Is Admin',
          value: 'is_admin',
          thStyle: { width: '10%' },
          thClass: 'text-center',
          sortable: false,
        },
        {
          value: 'action',
          thStyle: { width: '4%' },
          thClass: 'text-center',
          sortable: false,
        },
      ],
      items: [],
    };
  },
  async mounted() {
    await this.getAllUser(1);
  },
  computed: {
    sortOptions() {
      // Create an options list from our fields
      return this.fields
        .filter((f) => f.sortable)
        .map((f) => {
          return { text: f.label, value: f.key };
        });
    },
    selectedItems() {
      return this.items.filter((item) => item.selected);
    },
  },

  methods: {
    filteredData() {
      const lowerCaseQuery = this.filter.toLowerCase();
      if (!lowerCaseQuery) {
        return this.getAllUser(1);
      }

      setTimeout(() => {
        this.items = this.items.filter(
          (item) =>
            item.email.toLowerCase().includes(lowerCaseQuery) ||
            item.firstname.toLowerCase().includes(lowerCaseQuery) ||
            item.lastname.toLowerCase().includes(lowerCaseQuery)
        );
      }, 500);
    },

    getAllUser(e) {
      this.items = [];
      this.isLoading = true;
      requestService
        .GetFetch(`/auth/user_management?page=${e}`)
        .then((res) => {
          this.items = res.data.data.rows;
          this.totalRows = res.data.data.total_rows;
          this.totalPage = res.data.data.total_pages;
          this.isLoading = false;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    toggleChange(item, per) {
      const id = item.ID;
      let newPer = {};
      if (per == 'is_admin') {
        if (item.is_admin) {
          newPer = {
            is_admin: true,
            can_whitelist: true,
            can_update_whitelist: true,
            can_report: true,
            can_export_report: true,
          };
        } else {
          newPer = {
            is_admin: item.is_admin,
            can_whitelist: item.can_whitelist,
            can_update_whitelist: item.can_update_whitelist,
            can_report: item.can_report,
            can_export_report: item.can_export_report,
          };
        }
      } else {
        if (!item[per] && item.is_admin) {
          newPer = {
            is_admin: !item.is_admin,
            can_whitelist: item.can_whitelist,
            can_update_whitelist: item.can_update_whitelist,
            can_report: item.can_report,
            can_export_report: item.can_export_report,
          };
        } else {
          newPer = {
            is_admin: item.is_admin,
            can_whitelist: item.can_whitelist,
            can_update_whitelist: item.can_update_whitelist,
            can_report: item.can_report,
            can_export_report: item.can_export_report,
          };
        }
      }

      // console.log('toggle: ', newPer)
      requestService
        .PatchFetch(`/auth/user_management/role/${id}`, newPer)
        .then((res) => {
          this.getAllUser(this.currentPage);
          // this.$store.dispatch('SET_PERMISSION', newPer);
          // console.log('patch permission: ', res);
          // console.log('changed');
        })
        .catch((err) => {
          this.$swal.fire({
            icon: 'error',
            title: 'มีบางอย่างผิดพลาด',
            text: 'ไม่สามารถ​แก้ไขข้อมูลได้',
            showConfirmButton: true,
            timer: 1500,
          });
        });
    },
    editUserData() {
      this.getAllUser(this.currentPage);
    },
    deleteUser(ID) {
      const id = ID;

      this.$swal({
        title: 'คุณแน่ใจใช่หรือไม่?',
        text: 'หากลบแล้วจะไม่สามารถย้อนกลับได้!',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'ลบข้อมูล',
        cancelButtonText: 'ยกเลิก',
      })
        .then((result) => {
          if (result.isConfirmed) {
            requestService
              .DeleteFetch(`/auth/user_management/${id}`)
              .then((res) => {
                this.$swal.fire(
                  'ลบข้อมูลสำเร็จ!',
                  'บัญชีผู้ใช้ถูกลบเรียบร้อย',
                  'success'
                );
                this.getAllUser(this.currentPage);
              });
          }
        })
        .catch((error) => {
          // console.log(error);
          this.$swal.fire({
            icon: 'error',
            title: 'มีบางอย่างผิดพลาด',
            text: 'ไม่สามารถลบข้อมูลได้',
            showConfirmButton: true,
            timer: 1500,
          });
          // this.$swal.fire('Error!', 'ลบข้อมูลไม่สำเร็จ!', 'error');
        });
    },
    addNewUser() {
      this.getAllUser(this.currentPage);
      // console.log('added');
    },

    handleDropdownItemClick(e) {
      this.userdata = e;
    },
    onFiltered(filteredItems) {
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
  },
};
</script>

<style>
button.swal2-cancel.swal2-styled.swal2-default-outline {
  font-size: 14px;
}
th.text-center {
  font-size: 10px !important;
}

ul.dropdown-menu.show {
  min-width: 0.9rem !important;
}

/* td {
  padding: 0 !important;
  text-align: center;
} */

label.vue-js-switch {
  margin-left: 1.5rem;
}
</style>

<style scoped>
#usermanageTable >>> td.text-start {
  height: 40px !important;
}
#usermanageTable >>> td {
  padding-left: 10px !important;
}
.table thead th {
  font-size: 10px !important;
}

thead.text-capitalize {
  font-size: 10px !important;
}

#btn-clear {
  width: 90px;
  height: 30px;
  font-size: 0.6rem;
  border: none !important;
}

.btn-adduser {
  width: 90px;
  height: 30px;
  font-size: 0.6rem;
  padding: 0;
}

.searchinput {
  height: 30px;
  font-size: 0.7rem;
  margin-right: 10px;
}

p {
  font-size: 10px;
}
</style>
