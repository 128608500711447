
<template>
  <div class='d-flex'>
    <b-dropdown
                    id="b-dropdown-filter-whitelist-z"
                    variant="link"
                    no-caret
                  >
                    <template #button-content>
                      <b-row class="row">
                        <b-col class="" style="margin: 0 15px 0 0px">
                          <b-button
                            id="filter-whitlist-btn-z"
                            class="btn btn-pill btn-xs"
                            style="padding: 1px 0px 0 0; color: white"
                            variant="primary"
                          >
                            <b-icon
                              icon="funnel-fill"
                              aria-hidden="true"
                            ></b-icon>
                           กรองข้อมูล
                          </b-button>
                        </b-col>
                      </b-row>
                    </template>
                    <b-col
                      class="d-flex justify-content-between mt-1"
                      style="height: 18px"
                    >
                      <p class="filter-border-z">Filter</p>

                      <b-row>
                        <b-col>
                          <b-button
                            @click="onFilter"
                            type="button"
                            variant="primary"
                            id="search-filter-whitelist-btn-z"
                            class="btn btn-xs mr-1"
                            size="sm"
                            style="
                              position: relative;
                              z-index: 1;
                              padding: revert;
                              width: 55px;
                              height: 18px;
                              margin: 3px 0 0 0;
                              font-size: 8px !important;
                            "
                          >
                            Search
                          </b-button>
                          <b-button
                            type="button"
                            variant="primary"
                            @click="clearFilter"
                            id="clear-filter-whitelist-btn-z"
                            class="btn btn-xs"
                            style="
                              position: relative;
                              z-index: 1;
                              padding: revert;
                              width: 55px;
                              height: 18px;
                              margin: 3px 0px 0px 0px;
                              font-size: 8px !important;
                            "
                          >
                            Clear
                          </b-button>
                        </b-col>
                      </b-row>
                    </b-col>

                    <b-row class="justify-content-between" style="margin: 3px">
                      <b-col class="d-flex" style="height: 44px">
                        <div class="mr-1" style="width: 150px !important">
                          <label
                            for="search_filter_callactivity_start-time"
                            style="
                              font-size: 10px;
                              width: max-content;
                              margin: 0px 90px 0px 0;
                            "
                            >เบอร์โทรศัพท์</label
                          >
                          <b-form-group
                            label-for="search_filter_callactivity_start-time"
                          >
                            <b-form-input
                            @keyup.enter='onFilter'
                              v-model="username"
                              placeholder="เบอร์โทรศัพท์"
                              type="number"
                              class="inputNum"
                              :formatter="formatNum"
                              id="search_filter_callactivity_start-time"
                              :style="{ fontSize: '10px', width: isThaipost ? '160px !important' : '335px !important' }"
                              size="sm"
                            >
                            </b-form-input>
                          </b-form-group>
                        </div>

                        <div v-if='isThaipost' class="" style="margin: 0px 0px 0px 20px">
                          <label
                            for="search_filter_callactivity_end-time"
                            style="
                              font-size: 10px;
                              width: max-content;
                              margin: 0 120px 0px 0;
                            "
                            >สังกัด</label
                          >
                          <b-form-group
                            label-for="search_filter_callactivity_end-time"
                          >
                            <b-form-input
                            @keyup.enter='onFilter'
                              v-model="subordi"
                              type="text"
                              id="search_filter_callactivity_end-time"
                              style="font-size: 10px; width: 160px"
                              size="sm"
                              placeholder="สังกัด"
                            >
                            </b-form-input>
                          </b-form-group>
                        </div>
                      </b-col>
                    </b-row>

                    <b-row
                    v-if='!isThaipost'
                      class="justify-content-between"
                      style="margin: 5px 3px 5px 3px"
                    >
                      <b-col class="d-flex" style="height: 44px">
                        <div class="mr-1 d-flex flex-column align-items-start" style="width: 150px !important">
                          <label
                            for="search_filter_callactivity_start-time"
                            class='mt-2 mb-1'
                            style="
                              font-size: 10px;
                              width: max-content;
                            "
                            >แผนก</label
                          >
                          <b-form-group
                            label-for="search_filter_callactivity_start-time"
                          >
                            <b-form-input
                            @keyup.enter='onFilter'
                              v-model="office"
                              placeholder="แผนก"
                              type="text"
                              id="search_filter_callactivity_start-time"
                              style="font-size: 10px; width: 160px !important"
                              size="sm"
                            >
                            </b-form-input>
                          </b-form-group>
                        </div>

                        <div class="mr-1 d-flex flex-column align-items-start" style="margin: 0px 0px 0px 20px">
                          <label
                            for="search_filter_callactivity_end-time"
                            class='mt-2 mb-1'
                            style="
                              font-size: 10px;
                              width: max-content;
                            "
                            >หมายเลขผู้โทรออก</label
                          >
                          <b-form-group
                            label-for="search_filter_callactivity_end-time"
                          >
                            <b-form-input
                            @keyup.enter='onFilter'
                              v-model="root_call"
                              placeholder="หมายเลขผู้โทรออก"
                              type="number"
                              class="inputNum"
                              id="search_filter_callactivity_end-time"
                              style="font-size: 10px; width: 160px"
                              size="sm"
                            >
                            </b-form-input>
                          </b-form-group>
                        </div>
                      </b-col>
                    </b-row>
                    <!-- Thaipost -->
                    <b-row
                    v-if='isThaipost'
                      class="justify-content-between"
                      style="margin: 5px 3px 0 3px"
                    >
                      <b-col class="d-flex" style="height: 44px">
                        <div class="mr-1" style="width: 150px !important">
                          <label
                            for="search_filter_callactivity_start-time"
                            style="
                              font-size: 10px;
                              width: max-content;
                              margin: 0px 90px 0px 0;
                            "
                            >รหัสไปรษณีย์</label
                          >
                          <b-form-group
                            label-for="search_filter_callactivity_start-time"
                          >
                            <b-form-input
                            @keyup.enter='onFilter'
                              v-model="postcode"
                              placeholder="รหัสไปรษณีย์"
                              type="text"
                              id="search_filter_callactivity_start-time"
                              style="font-size: 10px; width: 160px !important"
                              size="sm"
                            >
                            </b-form-input>
                          </b-form-group>
                        </div>

                        <div class="" style="margin: 0px 0px 0px 20px">
                          <label
                            for="search_filter_callactivity_end-time"
                            style="
                              font-size: 10px;
                              width: max-content;
                              margin: 0 120px 0px 0;
                            "
                            >ที่ทำการ</label
                          >
                          <b-form-group
                            label-for="search_filter_callactivity_end-time"
                          >
                            <b-form-input
                            @keyup.enter='onFilter'
                              v-model="office"
                              placeholder="ที่ทำการ"
                              type="text"
                              id="search_filter_callactivity_end-time"
                              style="font-size: 10px; width: 160px"
                              size="sm"
                            >
                            </b-form-input>
                          </b-form-group>
                        </div>
                      </b-col>
                    </b-row>

                    <b-row
                    v-if='isThaipost'
                      class="justify-content-between"
                      style="margin: 5px 3px 0 3px"
                    >
                      <b-col class="d-flex" style="height: 44px">
                        <div class="mr-1" style="width: 150px !important">
                          <label
                            for="search_filter_callactivity_start-time"
                            style="
                              font-size: 10px;
                              width: max-content;
                              margin: 0px 90px 0px 0;
                            "
                            >หมายเลขผู้โทรออก</label
                          >
                          <b-form-group
                            label-for="search_filter_callactivity_start-time"
                          >
                            <b-form-input
                            @keyup.enter='onFilter'
                              v-model="root_call"
                              placeholder="หมายเลขผู้โทรออก"
                              type="number"
                              class="inputNum"
                              id="search_filter_callactivity_start-time"
                              style="font-size: 10px; width: 335px !important"
                              size="sm"
                            >
                            </b-form-input>
                          </b-form-group>
                        </div>

                      </b-col>
                    </b-row>
 <!-- ======= -->
                    <b-row
                      class="justify-content-between"
                      style="margin: 10px 3px 0 3px"
                    >
                      <b-col class="d-flex" style="height: 44px">
                        <div class="mr-1" style="width: 150px !important">
                          <label
                            for="search_filter_callactivity_start-time"
                            style="
                              font-size: 10px;
                              width: max-content;
                              margin: 0px 69px 0px 0;
                            "
                            >เบอร์โอนสายในเวลาทำการ</label
                          >
                          <b-form-group
                            label-for="search_filter_callactivity_start-time"
                          >
                            <b-form-input
                            @keyup.enter='onFilter'
                              v-model="inservice_time"
                              placeholder="เบอร์โอนสายในเวลาทำการ"
                              type="number"
                              class="inputNum"
                              id="search_filter_callactivity_start-time"
                              style="font-size: 10px; width: 160px !important"
                              size="sm"
                            >
                            </b-form-input>
                          </b-form-group>
                        </div>

                        <div class="" style="margin: 0px 0px 0px 20px">
                          <label
                            for="search_filter_callactivity_end-time"
                            style="
                              font-size: 10px;
                              width: max-content;
                              margin: 0 80px 0px 0;
                            "
                            >เบอร์โอนสายนอกเวลาทำการ</label
                          >
                          <b-form-group
                            label-for="search_filter_callactivity_end-time"
                          >
                            <b-form-input
                            @keyup.enter='onFilter'
                              v-model="oos_time"
                              placeholder="เบอร์โอนสายนอกเวลาทำการ"
                              type="number"
                              class="inputNum"
                              id="search_filter_callactivity_end-time"
                              style="font-size: 10px; width: 160px"
                              size="sm"
                            >
                            </b-form-input>
                          </b-form-group>
                        </div>
                      </b-col>
                    </b-row>


                    <b-row
                      class="justify-content-between"
                      style="margin: 13px 3px 0 3px"
                    >
                      <b-col
                        class="d-flex flex-column align-items-baseline"
                        style="height: 44px"
                      >
                        <label
                          for="create_datatable_whitelist_domain"
                          style="font-size: 10px"
                          >วันทำการ :</label
                        >
                        <div class="pl-4 pt-0 d-flex col-3">
                          <div
                            v-for="(date, idate) in workDateOptions"
                            :key="idate"
                          >
                            <div class="mr-4">
                              <input
                              @keyup.enter='onFilter'
                                class="form-check-input"
                                type="checkbox"
                                id="inlineCheckbox1"
                                v-model="date.isCheck"
                                value="date.value"
                              />
                              <label
                                class="form-check-label"
                                for="inlineCheckbox1"
                                style="font-size: 10px"
                                >{{ date.text }}</label
                              >
                            </div>
                          </div>
                        </div>
                      </b-col>
                    </b-row>

                    <b-row
                      class="justify-content-between"
                      style="margin: 5px 3px 0 3px"
                    >
                      <b-col class="d-flex" style="height: 44px">
                        <div class="mr-1" style="width: 150px !important">
                          <label
                            for="search_filter_callactivity_start-time"
                            style="
                              font-size: 10px;
                              width: max-content;
                              margin: 0px 100px 0px 0;
                            "
                            >วันเริ่มทำการ</label
                          >
                          <b-form-group
                            label-for="search_filter_callactivity_start-time"
                          >
                            <b-form-input
                            @keyup.enter='onFilter'
                              v-model="startWork"
                              placeholder="วันทำการ"
                              type="time"
                              id="search_filter_callactivity_start-time"
                              style="font-size: 10px; width: 160px !important"
                              size="sm"
                            >
                            </b-form-input>
                          </b-form-group>
                        </div>

                        <div class="" style="margin: 0px 0px 0px 20px">
                          <label
                            for="search_filter_callactivity_end-time"
                            style="
                              font-size: 10px;
                              width: max-content;
                              margin: 0 120px 0px 0;
                            "
                            >เวลาเลิกทำการ</label
                          >
                          <b-form-group
                            label-for="search_filter_callactivity_end-time"
                          >
                            <b-form-input
                            @keyup.enter='onFilter'
                              v-model="endWork"
                              placeholder="เวลาทำการ"
                              type="time"
                              id="search_filter_callactivity_end-time"
                              style="font-size: 10px; width: 160px"
                              size="sm"
                            >
                            </b-form-input>
                          </b-form-group>
                        </div>
                      </b-col>
                    </b-row>
                  </b-dropdown>
  </div>
</template>

<script>
export default {
  props: ['isThaipost'],
  data() {
    return {
      username: '',
      domain: '',
      root_call: '',
      // grp: '',
      startWork: '',
      endWork: '',
      postcode: '',
      subordi: '',
      office: '',
      inservice_time: '',
      oos_time: '',
      workDateOptions: [
        { text: 'จันทร์', value: '1', isCheck: false },
        { text: 'อังคาร', value: '2', isCheck: false },
        { text: 'พุธ', value: '3', isCheck: false },
        { text: 'พฤหัสบดี', value: '4', isCheck: false },
        { text: 'ศุกร์', value: '5', isCheck: false },
        { text: 'เสาร์', value: '6', isCheck: false },
        { text: 'อาทิตย์', value: '7', isCheck: false },
      ],
    };
  },

  mounted() {},

  methods: {
    onFilter(){
      let obj = {
        username:   this.username,
        // domain: this.domain,
        root_call: this.root_call,
        // grp: grp,
        startWork: this.startWork,
        endWork:  this.endWork,
        postcode: this.postcode,
        subordi: this.subordi,
        office: this.office,
        inservice_time:  this.inservice_time,
        oos_time: this.oos_time,
        workDateOptions: this.workDateOptions,
      }
      // console.log('obj: ', obj)
      this.$emit('onFilter', obj);
    },
    clearFilter(){
      this.username= '';
      this.domain= '';
      this.root_call= '';
      this.startWork= '';
      this.endWork= '';
      this.postcode= '';
      this.subordi= '';
      this.office= '';
      this.inservice_time= '';
      this.oos_time= '';
      this.workDateOptions= [
        { text: 'จันทร์', value: '1', isCheck: false },
        { text: 'อังคาร', value: '2', isCheck: false },
        { text: 'พุธ', value: '3', isCheck: false },
        { text: 'พฤหัสบดี', value: '4', isCheck: false },
        { text: 'ศุกร์', value: '5', isCheck: false },
        { text: 'เสาร์', value: '6', isCheck: false },
        { text: 'อาทิตย์', value: '7', isCheck: false },
      ]
      this.$emit('onClear')
    },
    formatNum(e) {
      let formattedValue = String(e).substring(0, 10);
      // if (formattedValue.startsWith('0')) {
      //   formattedValue = formattedValue.slice(1);
      // }

      return formattedValue;
    },
  },
};
</script>

<style scoped>
/* #b-dropdown-filter-whitelist-z>>>div#b-dropdown-filter-whitelist-z ul.dropdown-menu.show {
  margin: 16px 0px 0px -197px;
  height: 350px !important;
  width: 390px !important;
} */
#b-dropdown-filter-whitelist-z>>>ul.dropdown-menu.show{
  margin: 16px 0px 0px -197px;
  /* height: 300px !important; */
  height: 350px !important;
  width: 390px !important;
}
</style>
