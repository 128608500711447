import { host } from '../constants/config';
import { store } from '@/store/index';
import api from './api';

export const requestService = {
  GetFetch,
  PutFetch,
  PostFetch,
  PatchFetch,
  DeleteFetch,
};

function generateURL(path) {
  return host + path;
}

async function GetFetch(path) {
  const url = generateURL(path);
  var token = localStorage.getItem('token');
  var requestOptions = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  return api.get(url, requestOptions);
}

async function PutFetch(path, object, form = null) {
  const url = generateURL(path);
  let requestOptions = {};
  if (form != null) {
    var token = localStorage.getItem('token');
    requestOptions = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
      body: object,
    };
  } else {
    var token = localStorage.getItem('token');
    requestOptions = {
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(object),
    };
  }
  return api.put(url, requestOptions.body, requestOptions.headers);
}

async function PostFetch(path, object, form) {
  const url = generateURL(path);
  let requestOptions = {};
  if (form != null) {
    var token = localStorage.getItem('token');
    requestOptions = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
      body: object,
    };
  } else {
    var token = localStorage.getItem('token');
    requestOptions = {
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(object),
    };
  }
  return api.post(url, requestOptions.body, requestOptions.headers);
}

async function PatchFetch(path, object, form = null) {
  const url = generateURL(path);
  let requestOptions = {};
  if (form != null) {
    var token = localStorage.getItem('token');
    requestOptions = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
      body: object,
    };
  } else {
    var token = localStorage.getItem('token');
    requestOptions = {
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
      body: object,
    };
  }
  return api.patch(url, requestOptions.body, requestOptions.headers);
}

async function DeleteFetch(path, object = null) {
  const url = generateURL(path);
  let requestOptions = {};
  if (object != null) {
    var token = localStorage.getItem('token');
    requestOptions = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
      body: object,
    };
  } else {
    var token = localStorage.getItem('token');
    requestOptions = {
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(object),
    };
  }
  return api.delete(url, requestOptions.body, requestOptions.headers);
}
