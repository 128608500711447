<
<template>
  <div>
    <b-dropdown id="b-dropdown-filter-callactivity-z" variant="link" no-caret>
      <template #button-content>
        <b-row>
          <b-col class="" style="margin: 0 15px 0 0px">
            <b-button
              id="filter-callactivity-btn-z"
              class="btn btn-pill btn-xs mb-1"
              style="padding: 1px 0px 0 0"
              variant="primary"
            >
              <b-icon icon="funnel-fill" aria-hidden="true"></b-icon>
              กรองข้อมูล
            </b-button>
          </b-col>
        </b-row>
      </template>

      <!-- Dropdown Filter -->
      <div class="card-body-z">
        <b-row>
          <b-col class="d-flex justify-content-between" style="height: 18px">
            <p class="filter-border-z">Filter</p>

            <div class="d-flex">
              <b-button
                @click="onFilter"
                type="button"
                variant="primary"
                id="searchbtn"
                class="btn btn-xs mr-1"
                style="
                  z-index: 1;
                  padding: revert;
                  width: 55px;
                  height: 18px;
                  margin: 3px 0 0 0;
                  font-size: 8px !important;
                "
              >
                Search
              </b-button>
              <b-button
                type="button"
                variant="danger"
                id="clearFilter"
                @click="clearFilter"
                class="btn btn-xs"
                style="
                  z-index: 1;
                  padding: revert;
                  width: 55px;
                  height: 18px;
                  margin: 3px 0 0 0;
                  font-size: 8px !important;
                "
              >
                Clear
              </b-button>
            </div>
          </b-col>
        </b-row>

        <div class="mt-1">
          <b-row class="justify-content-between">
            <b-col class="d-flex" style="height: 44px">
              <div class="" style="width: 120px !important">
                <label
                  for="search_filter_callactivity_start-time"
                  style="
                    font-size: 10px;
                    width: max-content;
                    margin: 0px 69px 0px 0;
                  "
                  >Call Start Time</label
                >
                <b-form-group label-for="search_filter_callactivity_start-time">
                  <b-form-input
                  @keyup.enter='onFilter'
                    v-model="call_start_time_value"
                    type="time"
                    id="search_filter_callactivity_start-time"
                    style="font-size: 10px; width: 126px !important"
                    size="sm"
                  >
                  </b-form-input>
                </b-form-group>
              </div>

              <div style="margin: 0px 0px 0px 20px">
                <label
                  for="search_filter_callactivity_end-time"
                  style="
                    font-size: 10px;
                    width: max-content;
                    margin: 0 80px 0px 0;
                  "
                  >Call End Time</label
                >
                <b-form-group label-for="search_filter_callactivity_end-time">
                  <b-form-input
                  @keyup.enter='onFilter'
                    v-model="call_end_time_value"
                    type="time"
                    id="search_filter_callactivity_end-time"
                    style="font-size: 10px; width: 126px"
                    size="sm"
                  >
                  </b-form-input>
                </b-form-group>
              </div>
            </b-col>
          </b-row>

          <!-- row2 -->
          <!-- <b-row>
            <b-col class="d-flex" style="height: 44px">
              <div
                class="d-flex flex-column align-items-start"
                style="margin: 10px 0px 0px 0px"
              >
                <label
                  for="search_filter_callactivity_end-time"
                  style="font-size: 10px; width: max-content; margin: 0"
                  >From</label
                >
                <b-form-group label-for="search_filter_callactivity_end-time">
                  <b-form-input
                  @keyup.enter='onFilter'
                    v-model="src"
                    type="number"
                    class="inputNum"
                    id="search_filter_callactivity_end-time"
                    style="font-size: 10px; width: 126px"
                    size="sm"
                    placeholder="Number"
                  >
                  </b-form-input>
                </b-form-group>
              </div>

              <div
                class="d-flex flex-column align-items-start"
                style="margin: 10px 0px 0px 15px"
              >
                <label
                  for="search_filter_callactivity_end-time"
                  style="font-size: 10px; width: max-content; margin: 0"
                  >To</label
                >
                <b-form-group label-for="search_filter_callactivity_end-time">
                  <b-form-input
                  @keyup.enter='onFilter'
                    v-model="dst"
                    type="number"
                    class="inputNum"
                    id="search_filter_callactivity_end-time"
                    style="font-size: 10px; width: 126px"
                    size="sm"
                    placeholder="Number"
                  >
                  </b-form-input>
                </b-form-group>
              </div>
            </b-col>
          </b-row> -->

            <!-- ===== -->
          <b-row class="mt-1">
            <b-col class="d-flex" style="height: 44px">
              <div
                class="d-flex flex-column align-items-start mt-2"
                style="width: 100% !important"
              >
                <label
                  for="search_filter_callactivity_direction"
                  class=""
                  style="font-size: 10px; margin: 0 107px 0px 0"
                  >Direction</label
                >
                <b-form-group label-for="search_filter_callactivity_direction">
                  <b-form-select
                  @change='onFilter'
                    v-model="directionFilter"
                    :options="optionsFilterDirection"
                    id="search_filter_callactivity_direction"
                    style="font-size: 10px; width: 16.6rem !important"
                    size="sm"
                    placeholder="Choose"
                  >
                  </b-form-select>
                </b-form-group>
              </div>
            </b-col>
          </b-row>

          <!-- Ring Time -->
          <b-row class="mt-1">
            <b-col class="d-flex" style="height: 44px">
              <div class="" style="width: 120px !important">
                <label
                  for="search_filter_callactivity_ring-time1"
                  style="
                    font-size: 10px;
                    width: max-content;
                    margin: 0 69px 0px 0;
                  "
                  >Ring Time</label
                >
                <b-form-group label-for="search_filter_callactivity_ring-time1">
                  <b-form-select
                  @change='onFilter'
                    v-model="selectedFilterRing"
                    :options="optionsFilterRing"
                    id="search_filter_callactivity_ring-time1"
                    style="font-size: 10px; width: 126px !important"
                    size="sm"
                    placeholder="Choose"
                  >
                  </b-form-select>
                </b-form-group>
              </div>

              <div style="margin: 0px 0px 0px 20px">
                <label
                  for="search_filter_callactivity_ring-time2"
                  style="
                    font-size: 10px;
                    width: max-content;
                    margin: 0 80px 0px 0;
                  "
                ></label>
                <b-form-group label-for="search_filter_callactivity_ring-time2">
                  <b-form-input
                  @keyup.enter='onFilter'
                    v-model="ring"
                    class="inputNum"
                    type="number"
                    id="search_filter_callactivity_ring-time2"
                    style="font-size: 10px; width: 126px"
                    size="sm"
                    placeholder="Value (second)"
                  >
                  </b-form-input>
                </b-form-group>
              </div>
            </b-col>
          </b-row>
          <!-- Talk Time -->
          <b-row class="mt-1">
            <b-col class="d-flex" style="height: 44px">
              <div class="" style="width: 120px !important">
                <label
                  for="search_filter_callactivity_talk-time1"
                  style="
                    font-size: 10px;
                    width: max-content;
                    margin: 0 69px 0px 0;
                  "
                  >Talk Time</label
                >
                <b-form-group label-for="search_filter_callactivity_talk-time1">
                  <b-form-select
                  @change='onFilter'
                    v-model="selectedFilterTalk"
                    :options="optionsFilterTalk"
                    id="search_filter_callactivity_talk-time1"
                    style="font-size: 10px; width: 126px !important"
                    size="sm"
                    placeholder="Choose"
                  >
                  </b-form-select>
                </b-form-group>
              </div>

              <div style="margin: 0px 0px 0px 20px">
                <label
                  for="search_filter_callactivity_talk-time2"
                  style="
                    font-size: 10px;
                    width: max-content;
                    margin: 0 80px 0px 0;
                  "
                ></label>
                <b-form-group label-for="search_filter_callactivity_talk-time2">
                  <b-form-input
                  @keyup.enter='onFilter'
                    v-model="talk"
                    class="inputNum"
                    type="number"
                    id="search_filter_callactivity_talk-time2"
                    style="font-size: 10px; width: 126px"
                    size="sm"
                    placeholder="Value (second)"
                  >
                  </b-form-input>
                </b-form-group>
              </div>
            </b-col>
          </b-row>
        </div>
      </div>
    </b-dropdown>
  </div>
</template>

<script>
export default {
  data() {
    return {
      call_start_time_value: '',
      call_end_time_value: '',
      call_start_time: '',
      call_end_time: '',
      // src: '',
      // dst: '',
      directionFilter: '',
      selectedFilterRing: null,
      selectedFilterTalk: null,
      ring: '',
      talk: '',
      department: '',
      callerId: '',
      optionsFilterDirection: [
        { value: '', text: 'Choose' },
        { value: 'in', text: 'Inbound' },
        { value: 'out', text: 'Outbound' },
        // { value: '<', text: '<' },
      ],
      optionsFilterRing: [
        { value: null, text: 'Choose' },
        { value: '=', text: '=' },
        { value: '>', text: '>' },
        { value: '<', text: '<' },
      ],
      selectedFilterTalk: null,
      optionsFilterTalk: [
        { value: null, text: 'Choose' },
        { value: '=', text: '=' },
        { value: '>', text: '>' },
        { value: '<', text: '<' },
      ],
    };
  },

  mounted() {},

  methods: {
    onFilter() {
      let obj = {
        call_start_time_value: this.call_start_time_value,
        call_end_time_value: this.call_end_time_value,
        call_start_time: this.call_start_time,
        call_end_time: this.call_end_time,
        // src: this.src,
        // dst: this.dst,
        directionFilter: this.directionFilter,
        selectedFilterRing: this.selectedFilterRing,
        selectedFilterTalk: this.selectedFilterTalk,
        ring: this.ring,
        talk: this.talk,
        department :this.department,
        callerId :this.callerId,
      };
    //   console.log('filter: ', obj)
      this.$emit('onSearch', obj);
    },
    clearFilter() {
      this.call_start_time_value = '';
      this.call_end_time_value = '';
      this.call_start_time = '';
      this.call_end_time = '';
      // this.src = '';
      // this.dst = '';
      this.directionFilter = '';
      this.selectedFilterRing = null;
      this.selectedFilterTalk = null;
      this.ring = '';
      this.talk = '';
      this.department= '';
      this.callerId= '';

      this.$emit('clear');
    },
  },
};
</script>

<style scoped>
#b-dropdown-filter-callactivity-z >>> ul.dropdown-menu.show {
  height: 15.5rem;
  width: 18rem;
  margin: 16px 0px 0px -203px;
}

.inputNum::-webkit-inner-spin-button,
inputNum::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
</style>
