<template>
  <div>
    <Breadcrumbs title="รายงานตามช่วงเวลา" />
    <!-- Container-fluid starts-->
    <div class="container-fluid">
      <div class="row">
        <div class="col-md-12">
          <div class="card">
            <b-row
              class="justify-content-start p-b-0"
              style="padding-top: 1px !important"
            >
              <b-col class="p-l-30 p-r-20 p-b-0" style="height: 64px">
                <label class="col-form-label" style="font-size: 10px"
                  >วันที่เริ่มต้น</label
                >
                <div class="col-sm-12 p-l-0 p-r-0">
                  <b-form-input
                    v-model="start_date_interval"
                    type="date"
                    size="sm"
                    style="font-size: 10px"
                  ></b-form-input>
                </div>
              </b-col>

              <b-col class="p-l-0 p-r-20 p-b-0" style="height: 64px">
                <label class="col-form-label" style="font-size: 10px"
                  >วันที่สิ้นสุด</label
                >
                <div class="col-sm-12 p-l-0 p-r-0">
                  <b-form-input
                    v-model="end_date_interval"
                    type="date"
                    size="sm"
                    style="font-size: 10px"
                  ></b-form-input>
                </div>
              </b-col>

              <b-col class="p-l-0 p-r-20 p-b-0" style="height: 64px">
                <label class="col-form-label" style="font-size: 10px"
                  >เวลาเริ่มต้น</label
                >
                <div class="col-sm-12 p-l-0 p-r-0">
                  <b-form-input
                    v-model="start_time_interval"
                    type="time"
                    size="sm"
                    style="font-size: 10px"
                  ></b-form-input>
                </div>
              </b-col>

              <b-col class="p-l-0 p-r-15 p-b-0" style="height: 64px">
                <label class="col-form-label" style="font-size: 10px"
                  >เวลาที่สิ้นสุด</label
                >
                <div class="col-sm-12 p-l-0 p-r-0">
                  <b-form-input
                    v-model="end_time_interval"
                    type="time"
                    size="sm"
                    style="font-size: 10px"
                  ></b-form-input>
                </div>
              </b-col>

              <!-- ========== Button ========== -->
              <b-row
                class="row"
                style="margin: 0 0px 0 0px; padding: 0 1px 0 0px"
              >
                <b-col class="d-flex align-items-center p-l-5">
                  <b-button
                    @click="getPaginationInterval"
                    type="button"
                    variant="primary"
                    id="search-one-interval-btn-z"
                    class="btn btn-pill btn-xs"
                    style="width: 90px; height: 24px; margin: 9px 15px 0 0"
                  >
                    ค้นหา
                  </b-button>

                  <b-button
                    @click="clearFilter"
                    type="button"
                    variant="danger"
                    id="clear-one-interval-btn-z"
                    class="btn btn-pill btn-xs h-2"
                    style="width: 90px; height: 24px; margin: 9px 15px 0 0"
                  >
                    ล้าง
                  </b-button>
                </b-col>
              </b-row>
              <!-- ========== Button ========== -->
            </b-row>
          </div>
          <div class="card">
            <div class="card-body">
              <b-row class="justify-content-end">
                <b-row class="row mb-3">
                  <!-- ========== Export CSV ========== -->
                  <div
                    class="d-flex col"
                    style="margin: 0 0px 0 10px; padding: 0 30px 0 0px"
                  >
                    <!-- ===== filter ==== -->

                    <!-- == export == -->
                    <b-button
                      @click="getExportCSVInterval"
                      id="export-csv-interval-btn-z"
                      class="btn btn-pill btn-xs"
                      size="sm"
                      variant="primary"
                      :disabled="exportBtn"
                      :style="{
                        backgroundColor: exportBtn ? '#d0d4ca !important' : '',
                        border: exportBtn ? 'none' : '',
                      }"
                    >
                      <b-spinner
                        v-if="isExport == 100"
                        small
                        label="Loading..."
                      ></b-spinner>
                      <div v-if="isExport == 0">
                        <font-awesome-icon icon="fa-solid fa-file-export" />
                        ส่งออก
                      </div>
                    </b-button>
                    <exportData ref="exportInterval" />
                  </div>
                </b-row>

           <!--     <b-row class="row">
                  <b-col class="mr-3">
                    <b-form-select
                      v-model="perPage"
                      :options="pageOptions"
                      class="btn-xs"
                      style="
                        width: 55px;
                        height: 24px;
                        margin: 2px 0px 0 15px;
                        font-size: 10px !important;
                      "
                    >
                    </b-form-select>
                  </b-col>
                </b-row> -->
              </b-row>
              <!-- datatable-vue mb-0 -->
              <div class="daily-report table-responsive">
                <v-data-table
                  :headers="headers"
                  :items="rows"
                  :page.sync="currentPage"
                  hide-default-footer
                  :items-per-page="perPage"
                  @page-count="pageCount = $event"
                  class="elevation-1"
                  loading
                  :sort-by.sync="sortBy"
                  :sort-desc.sync="sortDesc"
                  @update:sort-by="onSorted"
                  >
                  <template v-slot:loading>
                    <v-progress-circular
                      indeterminate
                      color="primary"
                      size="40"
                      class="ma-2"
                    ></v-progress-circular>
                  </template>
                  <template v-slot:body.prepend>
                    <tr>
                      <td
                        v-for="(item, index) in sumRows"
                        :key="index"
                        class="text-center totalRow"
                        style="font-size: 10px; font-weight: 600"
                      >
                        {{ item }}
                      </td>
                    </tr>
                  </template>

                  <template v-slot:item.time_range="{ item }">
                    <div
                      class="text-center"
                      style="width: 100px; font-size: 10px"
                    >
                      {{ item.time_range }}
                    </div>
                  </template>
                  <template v-slot:item.total_calls="{ item }">
                    <div
                      class="text-center p-0 m-0"
                      style="width: 80px; font-size: 10px"
                    >
                      {{ item.total_calls.toLocaleString() }}
                    </div>
                  </template>
                  <template v-slot:item.out_bound_calls="{ item }">
                    <div
                      class="text-center"
                      style="width: 120px; font-size: 10px"
                    >
                      {{ item.out_bound_calls.toLocaleString() }}
                    </div>
                  </template>
                  <template v-slot:item.in_bound_calls="{ item }">
                    <div
                      class="text-center"
                      style="width: 120px; font-size: 10px"
                    >
                      {{ item.in_bound_calls.toLocaleString() }}
                    </div>
                  </template>

                  <template v-slot:item.answered_calls="{ item }">
                    <div
                      class="text-center"
                      style="width: 120px; font-size: 10px"
                    >
                      {{ item.answered_calls.toLocaleString() }}
                    </div>
                  </template>
                  <template v-slot:item.abandoned_calls="{ item }">
                    <div
                      class="text-center"
                      style="width: 140px; font-size: 10px"
                    >
                      {{ item.abandoned_calls.toLocaleString() }}
                    </div>
                  </template>
                  <template v-slot:item.per_answered_calls="{ item }">
                    <div
                      class="text-center"
                      style="width: 130px; font-size: 10px"
                    >
                      {{ item.per_answered_calls }}
                    </div>
                  </template>
                  <template v-slot:item.per_abandon_calls="{ item }">
                    <div
                      class="text-center"
                      style="width: 150px; font-size: 10px"
                    >
                      {{ item.per_abandon_calls }}
                    </div>
                  </template>
                  <template v-slot:item.average_talk_time="{ item }">
                    <div
                      class="text-center"
                      style="width: 130px; font-size: 10px"
                    >
                      {{ convertAverageTalkTime(item.average_talk_time) }}
                    </div>
                  </template>
                </v-data-table>

                <!-- <div class="text-center pt-2 sm">
                  <b-pagination
                      v-model="currentPage"
                      :total-rows="totalRows"
                      :per-page="perPage"
                      align="center"
                      @input="getPaginationInterval"
                      class="justify-content-center"
                      size="sm"
                      aria-controls="my-table"
                    ></b-pagination>
                </div> -->
                <br/>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- Container-fluid Ends-->
  </div>
</template>

<script>
import { requestService } from '@/_services/request.service';
import exportData from '@/components/export.vue';

export default {
  components: {
    exportData,
  },
  data() {
    return {
      isLoading: true,
      isExport: 0,
      inboundFilter: '',
      outboundFilter: '',
      exportBtn: false,
      // ========== Filter ==========
      start_date_interval: new Date().toISOString().substr(0, 10),
      end_date_interval: new Date().toISOString().substr(0, 10),
      start_time_interval: '',
      end_time_interval: '',

      time: '',
      total_calls: '',
      // total_calls: '',
      answered_calls: '',
      abandoned_calls: '',
      per_answered_calls: '',
      per_abandon_calls: '',
      average_talk_time: '',
      per_in_service_level: '',
      // ========== Filter ==========
      rows: [],
      tableData: [],
      filter: null,
      total_pages: '',
      totalRows: 1,
      currentPage: 1,
      page: 1,
      perPage: 30,
      // pageOptions: [10, 20, 30, 40, 50, 100],
      sortBy: '',
      sortDesc: false,
      sorting: '',
      modalVisibleInterval: false,
      headers: [
        {
          text: 'Time',
          value: 'time_range',
          class: 'headerClass',
        },
        {
          text: 'Total Calls',
          value: 'total_calls',
        },
        {
          text: 'Outbound Calls',
          value: 'out_bound_calls',
        },
        {
          text: 'Inbound Calls',
          value: 'in_bound_calls',
        },

        {
          text: 'Answered Calls',
          value: 'answered_calls',
        },
        {
          text: 'No Answered Calls',
          value: 'abandoned_calls',
        },
        {
          text: '%Answered Calls',
          value: 'per_answered_calls',
        },
        {
          text: '%No Answered Calls',
          value: 'per_abandon_calls',
        },
        {
          text: 'Average Talk Time',
          value: 'average_talk_time',
        },
      ],
      sumRows: [],
    };
  },
  watch: {
    perPage(newVal) {
      this.currentPage = 1;
      this.getPaginationInterval();
    },
  },
  mounted() {
    this.totalRows = this.rows.length;
    this.getPaginationInterval();
    this.permissionUpdate();
  },
  methods: {
    onSorted() {
      let by = '';
      if (!this.sortBy) {
        by = this.sorting.replace(/\s?(asc|desc)$/, '');
      } else {
        switch (this.sortBy) {
          case 'per_answered_calls':
            by = 'answered_calls';
            break;
          case 'per_abandon_calls':
            by = 'abandoned_calls';
            break;
          case 'average_talk_time':
            by = 'talk';
            break;
          default:
            by = this.sortBy;
            break;
        }
      }
      let sortType = 'asc';
      switch (this.sortDesc) {
        case true:
          sortType = 'desc';
          break;
        case false:
          sortType = 'asc';
          break;
      }
      this.sorting = by + ' ' + sortType
      this.getPaginationInterval();
    },
    async getExportCSVInterval() {
      if (this.isExport == 0) {
        this.isExport = 100;
        let start_time_interval = '';
        if (this.start_time_interval) {
          start_time_interval = this.start_time_interval;
        }

        let end_time_interval = '';
        if (this.end_time_interval) {
          end_time_interval = this.end_time_interval;
        }
        var url = `/v2/reports/interval?date_start=${this.start_date_interval}&date_end=${this.end_date_interval}&time_start=${start_time_interval}&time_end=${end_time_interval}&limit=20`;
        const header = [
          { name: 'Time', key: 'time_range' },
          { name: 'Total Calls', key: 'total_calls' },
          { name: 'Outbound Calls', key: 'out_bound_calls' },
          { name: 'Inbound Calls', key: 'in_bound_calls' },
          { name: 'Answered Calls', key: 'answered_calls' },
          { name: 'No Answered Calls', key: 'abandoned_calls' },
          { name: '%Answered Calls', key: 'per_answered_calls' },
          { name: '%No Answered Calls', key: 'per_abandon_calls' },
          { name: 'Average Talk Time', key: 'average_talk_time' },
        ];

        await this.$refs.exportInterval.loopGetData(
          url,
          'interval_report.csv',
          header,
          'intervalReport_v2'
        );
        this.isExport = 0;
      }
    },
    permissionUpdate() {
      const permission =
        localStorage.getItem('permission') || permission.includes('is_admin');
      let result = permission.includes('can_export_report');
      if (!result) {
        this.exportBtn = true;
      } else {
        this.exportBtn = false;
      }
    },
    onFiltered(filteredItems) {
      this.totalRows = filteredItems.length;
      this.currentPage = page;
    },
    onPageChange(page) {
      this.currentPage = page;
      this.getPaginationInterval();
    },
    async getPaginationInterval() {
      this.rows = [];
      this.sumRows = [];
      this.isLoading = true;
      let start_time_interval = '';
      if (this.start_time_interval) {
        start_time_interval = this.start_time_interval;
      }

      let end_time_interval = '';
      if (this.end_time_interval) {
        end_time_interval = this.end_time_interval;
      }
      let url = `/v2/reports/interval?date_start=${this.start_date_interval}&date_end=${this.end_date_interval}&time_start=${start_time_interval}&time_end=${end_time_interval}&total_rows=${this.totalRows}&total_pages=${this.total_pages}&page=${this.currentPage}&limit=${this.perPage}&sort=${this.sorting}`;
      requestService
        .GetFetch(url)
        .then(async (response) => {
          let data = await response.data.data;
          this.rows = data.rows.map((e) => {
            let denominator = e.answered_calls + e.abandoned_calls;
            return {
              per_answered_calls:
                denominator == 0
                  ? 0 + '%'
                  : ((e.answered_calls / denominator) * 100).toFixed(2) + '%',
              per_abandon_calls:
                denominator == 0
                  ? 0 + '%'
                  : ((e.abandoned_calls / denominator) * 100).toFixed(2) +
                      '%' || 0,
              average_talk_time: e.answered_calls
                ? e.talk / e.answered_calls
                : 0,
              ...e,
            };
          });

          this.sumRows = [
            'รวม',
            data.sum_report.total.toLocaleString(),
            data.sum_report.total_out_call.toLocaleString(),
            data.sum_report.total_in_call.toLocaleString(),
            data.sum_report.total_answered_call.toLocaleString(),
            data.sum_report.total_abandoned_call.toLocaleString(),
            data.sum_report.total_answered_per,
            data.sum_report.total_abandoned_per,
            data.sum_report.total_average_talk_time,
          ];

          // this.rows = row;
          this.isLoading = false;
          this.totalRows = data.total_rows;
          this.total_pages = data.total_pages;
        })
        .catch((error) => {
          console.error(error);
        });
    },
    clearFilterBox() {
      this.filter = '';
      this.inboundFilter = '';
      this.outboundFilter = '';
      this.sort = '';
      this.getPaginationInterval();
      // this.getFiltersInterval();
    },
    clearFilter() {
      this.filter = '';
      this.start_date_interval = new Date().toISOString().substr(0, 10);
      this.end_date_interval = new Date().toISOString().substr(0, 10);
      this.start_time_interval = '';
      this.end_time_interval = '';
      this.sort = '';
      this.inboundFilter = '';
      this.outboundFilter = '';

      this.getPaginationInterval();
      // this.getFiltersInterval();
    },
    showModalInterval() {
      this.modalVisibleInterval = true;
    },
    convertAverageTalkTime(seconds) {
      const totalSeconds = Math.floor(seconds);
      const hours = Math.floor(totalSeconds / 3600);
      const minutes = Math.floor((totalSeconds % 3600) / 60);
      const remainingSeconds = totalSeconds % 60;

      const paddedHours = String(hours).padStart(2, '0');
      const paddedMinutes = String(minutes).padStart(2, '0');
      const paddedSeconds = String(remainingSeconds).padStart(2, '0');

      return `${paddedHours}:${paddedMinutes}:${paddedSeconds}`;
    },
  },
};
</script>

<style scoped>
#paginationDaily >>> .theme--light.v-pagination .v-pagination__item--active {
  background-color: #3f60fb !important;
  color: white;
}
.totalRow {
  height: 25px !important;
}
</style>

<style>
div#b-dropdown-filter-interval ul.dropdown-menu.show {
  /* position: relative; */
  /* top: 10rem;
  left: 8rem; */
  margin: 16px 0px 0px -120px;
}

.three-dots-vertical {
  color: #434343ae;
}

.no-border {
  background-color: transparent;
  border: none;
}

.col-md-12 {
  padding-right: 0px !important;
  padding-left: 0px !important;
}

/* ==================== perpage ==================== */
.pagination-sm .page-link {
  padding: 0.6px 0.5rem !important;
}

/* ==================== perpage ==================== */

/* ==================== Start Date, End Date, Start Time, End Time ==================== */
.col-form-label {
  padding-bottom: 2px !important;
}

/* ==================== Start Date, End Date, Start Time, End Time ==================== */

/* ==================== b-table interval_report_page ==================== */
div.interval-report table.interval-report thead tr th {
  padding: 0px 2px 0px 1px !important;
}

div.interval-report table.interval-report thead tr th div {
  width: 120px;
  padding: 2px 12px 2px 2px !important;
  text-align: center;
  font-size: 10px;
}

div.interval-report table.interval-report tbody tr td {
  width: max-content;
  padding: 2px 2px 2px 2px !important;
}

div.interval-report table.interval-report tbody tr td div {
  text-align: center;
  font-size: 10px;
  margin: 0 0 0 -12px;
}

.filterBox {
  border-radius: 3px;
  box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.2),
    0px 0.10000000149011612px 0.30000001192092896px 0px rgba(0, 0, 0, 0.1);
}

.inputNum::-webkit-inner-spin-button,
inputNum::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
/* ==================== b-table interval_report_page ==================== */

/* ==================== search, clear ==================== */
#export-csv-interval-btn-z {
  width: 82px;
  height: 24px;
  margin: 2px 0 0 0;
  /* background-color: #3F60FB !important;
      border-color: #3F60FB !important; */
}

#search-one-interval-btn-z {
  color: #3f60fb;
  background-color: #dfedff !important;
  border-color: #dfedff !important;
}

#clear-one-interval-btn-z {
  color: #f83366;
  background-color: #feeaef !important;
  border-color: #feeaef !important;
}

#clear-two-interval-btn-z {
  color: #f83366;
  background-color: #feeaef !important;
  border-color: #feeaef !important;
}

/* ==================== search, clear ==================== */

@media only screen and (min-width: 419px) and (max-width: 679px) {
  div.card div.row.justify-content-start div.d-flex.align-items-center {
    left: 1.8em;
    bottom: 0.6em;
  }

  div.container-fluid div.row div.col-md-12 div.card {
    padding: 0 13px 0px 0px;
  }
}
</style>
