<template>
  <div>
    <b-modal
      id="adduser"
      ref="adduser"
      title="Add User"
      class="theme-modal"
      hide-footer
      @hidden="onReset"
    >
      <div>
        <b-form @submit="onSubmit" @reset="cancelClick" v-if="show">
          <!-- Email -->
          <div class="d-flex">
            <!-- First Name -->
            <b-form-group
              id="input-group-2"
              label-for="input-2"
              class="mr-2 w-100"
            >
              <p class="text-size">First Name</p>
              <b-form-input
                id="firstname"
                v-model="user.firstname"
                placeholder="Enter First Name"
                required
              ></b-form-input>
            </b-form-group>
            <!-- Last Name -->
            <b-form-group id="input-group-2" label-for="input-2" class="w-100">
              <p class="text-size">Last Name</p>
              <b-form-input
                id="lastname"
                v-model="user.lastname"
                placeholder="Enter Last Name"
                required
              ></b-form-input>
            </b-form-group>
          </div>
          <b-form-group id="input-group-1" label-for="input-1">
            <p class="text-size">Email Address</p>
            <b-form-input
              id="input-1"
              v-model="user.email"
              type="email"
              placeholder="Enter Email"
              required
              class="mt-0"
            ></b-form-input>
          </b-form-group>

          <!-- Password -->
          <div class="passwordBox mb-2">
            <b-form-group
              id="password-input-group"
              label-for="password-input-1"
            >
              <p class="text-size">Password</p>
              <b-form-input
                id="password-input"
                v-model="user.password"
                type="password"
                placeholder="Enter Password"
                required
                @input="checkPassword"
              ></b-form-input>
            </b-form-group>
            <div class="passwordValidator">
              <ul>
                <li v-bind:class="{ is_valid: contains_eight_characters }">
                  8 Characters
                </li>
                <li v-bind:class="{ is_valid: contains_number }">
                  Contains Number
                </li>
                <li v-bind:class="{ is_valid: contains_uppercase }">
                  Contains Uppercase
                </li>
                <li v-bind:class="{ is_valid: contains_special_character }">
                  Contains Special Character
                </li>
              </ul>
            </div>
          </div>

          <!-- Confirm Password -->

          <b-form-group id="input-group-1" label-for="input-1">
            <p class="text-size">Confirm Password</p>
            <!-- {{ user.confirm_password }} -->
            <b-form-input
              id="confirm_password-input"
              v-model="user.confirm_password"
              type="password"
              placeholder="Enter Confirm Password"
              required
              @input="checkConfirm"
            ></b-form-input>
          </b-form-group>

          <!-- Check Box -->
          <div class="checkbox-animated animate-chk">
            <p class="text-size">Permission</p>
            <div class="container-permission">
              <div>
                <label class="d-block" for="chk-import">
                  <input
                    v-model="user.can_whitelist"
                    class="checkbox_animated"
                    id="chk-import"
                    type="checkbox"
                  />
                  Can Manage Whitelist
                </label>
                <label class="d-block" for="chk-export">
                  <input
                    v-model="user.can_update_whitelist"
                    class="checkbox_animated"
                    id="chk-export"
                    type="checkbox"
                  />
                  Can Update Whitelist
                </label>
                <label class="d-block" for="chk-add">
                  <input
                    v-model="user.can_report"
                    class="checkbox_animated"
                    id="chk-add"
                    type="checkbox"
                  />
                  Can View Report
                </label>
              </div>
              <div>
                <label class="d-block mb-2" for="chk-report">
                  <input
                    v-model="user.can_export_report"
                    class="checkbox_animated"
                    id="chk-report"
                    type="checkbox"
                  />
                  Can Export Report
                </label>
                <label class="d-block mb-0" for="chk-whitelist">
                  <input
                    v-model="user.is_admin"
                    class="checkbox_animated"
                    id="chk-whitelist"
                    type="checkbox"
                  />
                  Is Admin
                </label>
              </div>
            </div>
          </div>
          <!-- Check Box Ends -->

          <!-- Button -->
          <div class="d-flex justify-content-end">

            <b-button
              :disabled="btnDisabled"
              class="mr-3"
              type="submit"
              variant="primary"
              >Add</b-button
            >
          </div>
        </b-form>
        <!-- Show Data from Input form -->
      </div>
    </b-modal>
  </div>
</template>

<script>
import { requestService } from '../../_services/request.service';

export default {
  name: 'AddUser',
  data() {
    return {
      password_length: 0,
      contains_eight_characters: false,
      contains_number: false,
      contains_uppercase: false,
      contains_special_character: false,
      btnDisabled: true,
      user: {
        email: '',
        password: null,
        confirm_password: null,
        firstname: '',
        lastname: '',
        can_whitelist: false,
        can_update_whitelist: false,
        can_report: false,
        can_export_report: false,
        is_admin: false,
      },
      show: true,
    };
  },
  methods: {
    checkPassword() {
      this.password_length = this.user.password.length;
      const format = /[ !@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]/;

      if (this.password_length >= 8) {
        this.contains_eight_characters = true;
      } else {
        this.contains_eight_characters = false;
      }

      this.contains_number = /\d/.test(this.user.password);
      this.contains_uppercase = /[A-Z]/.test(this.user.password);
      this.contains_special_character = format.test(this.user.password);
    },
    checkConfirm() {
      if (this.user.confirm_password == this.user.password) {
        this.btnDisabled = false;
      } else {
        this.btnDisabled = true;
      }
    },
    onSubmit(e) {
      e.preventDefault();
      const newUser = {
        email: this.user.email,
        password: this.user.password,
        confirm_password: this.user.confirm_password,
        can_whitelist: this.user.can_whitelist,
        can_update_whitelist: this.user.can_update_whitelist,
        can_report: this.user.can_report,
        can_export_report: this.user.can_export_report,
        is_admin: this.user.is_admin,
        firstname: this.user.firstname,
        lastname: this.user.lastname,
      };

      requestService
         .PostFetch('/auth/user_management', newUser)
         .then((res) => {
            //console.log('res adduser: ', res);
           this.$emit('addNewUser');
       this.hideModal();
       this.onReset();
         })
         .catch((err) => {
          this.$swal.fire({
            icon: 'error',
            title: 'มีบางอย่างผิดพลาด',
            text: 'ไม่สามารถเพิ่มข้อมูลได้',
            showConfirmButton: true,
            timer: 1500,
          });
        });

    },
    // async onSubmit1(event) {
    //   event.preventDefault();
    //   const token = localStorage.getItem('token');
    //   const newUser = {
    //     email: this.user.email,

    //     password: this.user.password,
    //     confirm_password: this.user.confirm_password,
    //     can_whitelist: this.user.can_whitelist,
    //     can_update_whitelist: this.user.can_update_whitelist,
    //     can_report: this.user.can_report,
    //     can_export_report: this.user.can_export_report,
    //     is_admin: this.user.is_admin,
    //     firstname: this.user.firstname,
    //     lastname: this.user.lastname,
    //   };

    //   console.log(newUser);
    //   await axios
    //     .post(api_host + '/auth/user_management', newUser, {
    //       headers: {
    //         Authorization: 'Bearer ' + token,
    //       },
    //     })
    //     .then((res) => {
    //       console.log('res adduser: ', res);
    //     })
    //     .catch((err) => {
    //       console.error('There was an error!', err);
    //     });

    //   this.$emit('addNewUser');
    //   this.hideModal();
    //   this.onReset();
    // },
    onReset(event) {
      this.user.email = '';
      this.user.password = '';
      this.user.confirm_password = '';
      this.user.firstname = '';
      this.user.lastname = '';
      this.user.can_whitelist = false;
      this.user.can_update_whitelist = false;
      this.user.can_report = false;
      this.user.can_export_report = false;
      this.user.is_admin = false;

      this.contains_eight_characters = false;
      this.contains_number = false;
      this.contains_uppercase = false;
      this.contains_special_character = false;
      this.btnDisabled = true;
      // Trick to reset/clear native browser newUser validation state
      this.show = false;
      this.$nextTick(() => {
        this.show = true;
      });
    },
    cancelClick() {
      this.onReset();
      this.hideModal();
    },
    hideModal() {
      this.$refs['adduser'].hide();
    },
  },
};
</script>

<style>
.text-size {
  font-size: 0.9rem;
  font-weight: 500;
  margin: 0;
}

.container-permission {
  margin-top: 5px;
  margin-bottom: 2rem;
  display: flex;
  gap: 5rem;
}

.passwordValidator > ul {
  color: #888888;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding-left: 20px;
  list-style-type: disc;
}

/* Password Input --------- */

input[type='password'] {
  line-height: 1.5;
  display: block;
  color: rgba(136, 152, 170, 1);
  font-weight: 300;
  width: 100%;
  height: calc(2.75rem + 2px);
  padding: 0.625rem 0.75rem;
  border-radius: 0.25rem;
  background-color: #fff;
  transition: border-color 0.4s ease;
  border: 1px solid #cad1d7;
  outline: 0;
}

input[type='password']:focus {
  border-color: rgba(50, 151, 211, 0.45);
}

/* Checkmark & Strikethrough --------- */

.is_valid {
  color: #58ca28;
  transition-duration: 0.5s;
}
.is_valid:before {
  width: 100%;
}
</style>
