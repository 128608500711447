<template>
  <div>
    <!-- Export CSV -->
    <b-button
      @click="getExport"
      id="export-csv-whitlist-btn-z"
      class="btn btn-pill btn-xs"
      size="sm"
      variant="primary"
      :disabled="btnExport"
    >
      <font-awesome-icon icon="fa-solid fa-file-export" /> Export
    </b-button>
    <exportCSV ref="exportdata" />
  </div>
</template>

<script>
import { requestService } from '@/_services/request.service';
import exportCSV from '../../components/export.vue'

export default {
  name: 'exportCSV',
  components: { exportCSV },
  props: {
    perPage: {
      type: Number,
      default: false,
    },
    totalRows: {
      type: Number,
      default: false,
    },
    totalRows: {
      type: Number,
      default: false,
    },
    total_pages: {
      type: Number,
      default: false,
    },
    currentPage: {
      type: Number,
      default: false,
    },
    username: {
      type: String,
      default: false,
    },
    domain: {
      type: String,
      default: false,
    },
    grp: {
      type: String,
      default: false,
    },
  },
  data() {
    return {
      btnExport: false,
      csvDataWhitelist: {
        username: '',
        domain: '',
        grp: '',
      }, // Holds the imported CSV data
    };
  },
  mounted() {
    this.permissionUpdate();
  },
  methods: {
    permissionUpdate() {
      const permission = localStorage.getItem('permission');
      let result =
        permission.includes('can_update_whitelist') ||
        permission.includes('is_admin');
      if (!result) {
        this.btnExport = true;
      } else {
        this.btnExport = false;
      }
      // console.log('wl: ', result);
    },
    getExport() {
      let header =
        'ลำดับ,เบอร์โทรศัพท์,สังกัด,รหัสไปรษณีย์,ที่ทำการ,เบอร์โอนสายในเวลาทำการ,เบอร์โอนสายนอกเวลาทำการ,วันทำการ,เวลาทำการ,แก้ไขล่าสุด';
      let url = `/whitelist?total_rows=${this.totalRows}&total_pages=${this.total_pages}&limit=${this.perPage}&username=${this.username}&domain=${this.domain}&grp=${this.grp}`;
      this.$refs.exportdata.loopGetData(
        url,
        'data_whitelist.csv',
        header,
        'whitelist'
      );
      // this.$refs.exportdata.getExportCSV(url);
    },
    getExportCSVWhitelist() {
      // Fetch the CSV data from the server using Axios
      let url = `/whitelist?total_rows=${this.totalRows}&total_pages=${this.total_pages}&limit=${this.perPage}&page=${this.currentPage}&username=${this.username}&domain=${this.domain}&grp=${this.grp}`;
      requestService
        .GetFetch(url)
        .then((response) => {
          const csvContent = JSON.stringify(response.data.data.rows);
          // console.log('getExportCSVWhitelist: ', csvContent);

          const csvDataWhitelist = this.convertToCSV(csvContent);

          // Create a download link for the CSV file
          // const link = document.createElement('a');
          // const blob = new Blob(["\ufeff" + csvData], { type: 'text/csv;charset=utf-8;' });
          // link.href = `data:text/csv;charset=utf-8,${encodeURI(csvDataWhitelist)}`;
          // link.target = '_blank';
          // link.download = 'exported_data.csv';
          // link.click();

          // const csvData = convertToCSV(this.items);

          const blob = new Blob(['\ufeff' + csvDataWhitelist], {
            type: 'text/csv;charset=utf-8;',
          });
          const url = window.URL.createObjectURL(blob);

          // Create a link for downloading the CSV
          const a = document.createElement('a');
          a.href = url;
          a.download = 'data_whitelist.csv';

          // Add the link to the DOM and click it to initiate the download
          document.body.appendChild(a);
          a.click();

          // Remove the link after the download is complete
          window.URL.revokeObjectURL(url);
          document.body.removeChild(a);
        })
        .catch((error) => {
          console.log('Error exporting CSV:', error);
        });
    },
    convertToCSV(objArray) {
      var array = typeof objArray != 'object' ? JSON.parse(objArray) : objArray;
      var str = '';

      str += 'id,username,domain,grp,last_modified' + '\r\n';
      for (var i = 0; i < array.length; i++) {
        var line = '';
        for (var index in array[i]) {
          if (line != '') line += ',';

          line += array[i][index];
        }

        str += line + '\r\n';
      }

      // format the data

      return str;
    },
  },
};
</script>
<style>
#export-csv-whitlist-btn-z {
  width: 82px;
  height: 24px;
  margin: 2px 0 0 0;
}
</style>
