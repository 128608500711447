<template>
  <div
    id="sidebar-thaipost-components"
    style="transition: width 1s ease 0s !important"
  >
    <div class="logo-wrapper">
      <router-link to="/home">
        <img
          :src="imgSrc"
          :width="imgSrc == thaipostLogo ? 100 : 80"
          height="80"
          alt="logo website"
        />
      </router-link>
      <div class="back-btn" @click="toggle_sidebar">
        <i class="fa fa-angle-left"></i>
      </div>
      <div class="toggle-sidebar" @click="toggle_sidebar">
        <feather
          class="status_toggle middle"
          type="grid"
          id="sidebar-toggle"
        ></feather>
      </div>
    </div>
    <div class="logo-icon-wrapper p-4">
      <router-link to="/home">
        <img
          class="img-fluid"
          :src="smallImgSrc"
          alt="logo website"
          width="60px"
          height="60px"
      /></router-link>
    </div>
    <nav class="sidebar-main">
      <ul>
        <li
          class="left-arrow"
          :class="{
            'd-none':
              layout.settings.layout_type == 'rtl'
                ? hideLeftArrowRTL
                : hideLeftArrow,
          }"
          @click="
            layoutobject.split(' ').includes('horizontal-wrapper') &&
            layout.settings.layout_type === 'rtl'
              ? scrollToLeftRTL()
              : scrollToLeft()
          "
        >
          <feather type="arrow-left"></feather>
        </li>
      </ul>
      <div id="sidebar-menu">
        <ul
          class="sidebar-links custom-scrollbar"
          id="myDIV"
          :style="[
            layoutobject.split(' ').includes('horizontal-wrapper')
              ? layout.settings.layout_type == 'rtl'
                ? { 'margin-right': margin + 'px' }
                : { 'margin-left': margin + 'px' }
              : { margin: '0px' },
          ]"
        >
          <li class="back-btn">
            <router-link to="/home">
              <img
                class="img-fluid"
                src="../assets/images/logo/logo-icon.png"
                alt=""
            /></router-link>
            <div class="mobile-back text-right">
              <span>Back</span
              ><i class="fa fa-angle-right pl-2" aria-hidden="true"></i>
            </div>
          </li>
          <li
            v-for="(menuItem, index) in menuItemPer"
            :key="index"
            :class="{
              active: menuItem.active,
              'sidebar-main-title': menuItem.type == 'headtitle',
            }"
            class="sidebar-list"
            style="margin: auto"
          >
            <!-- link title -->
            <div v-if="menuItem.type == 'headtitle'">
              <h6 class="lan-1">{{ menuItem.headTitle1 }}</h6>
              <p class="lan-2">{{ menuItem.headTitle2 }}</p>
            </div>
            <!-- here -->

            <!-- Type Sub (Only Report Menu) -->
            <label
              :class="'badge badge-' + menuItem.badgeType"
              v-if="menuItem.badgeType"
              >{{ menuItem.badgeValue }}</label
            >
            <a
              href="javascript:void(0)"
              class="sidebar-link sidebar-title"
              v-if="menuItem.type == 'sub'"
              @click="setNavActive(menuItem, index)"
            >
              <feather :type="menuItem.icon" class="top"></feather>
              <span>
                {{ menuItem.title }}
              </span>
              <div class="according-menu" v-if="menuItem.children">
                <i class="fa fa-angle-right pull-right"></i>
              </div>
            </a>
            <!-- Type Sub Ends -->

            <!-- Type Link -->
            <router-link
              :to="menuItem.path"
              class="sidebar-link sidebar-title"
              v-if="menuItem.type == 'link'"
              router-link-exact-active
            >
              <font-awesome-icon :icon="`fa-solid fa-${menuItem.icon}`" />
              <span>
                {{ menuItem.title }} <span v-if="menuItem.version == 2" style='color: red; font-size: 10px'>(dev)</span>
              </span>
              <i
                class="fa fa-angle-right pull-right"
                v-if="menuItem.children"
              ></i>
            </router-link>
            <!-- Type Link Ends-->
            <ul
              class="sidebar-submenu"
              v-if="menuItem.children"
              :class="{ 'menu-open': menuItem.active }"
            >
              <li
                v-for="(childrenItem, index) in menuItem.children"
                :key="index"
                :class="{ active: childrenItem.active }"
              >
                <!-- Sub -->

                <a
                  class="submenu-title"
                  href="javascript:void(0)"
                  v-if="childrenItem.type == 'sub'"
                  @click="setNavActive(childrenItem, index)"
                >
                  {{ childrenItem.title }}
                  <label
                    :class="
                      'badge badge-' + childrenItem.badgeType + ' pull-right'
                    "
                    v-if="childrenItem.badgeType"
                    >{{ childrenItem.badgeValue }}</label
                  >
                  <i
                    class="fa fa-angle-right pull-right mt-1"
                    v-if="childrenItem.children"
                  ></i>
                </a>
              </li>
            </ul>
          </li>
          <!-- Nav that get data from vuex Ends-->
        </ul>
      </div>
     
    </nav>
  </div>
</template>
<script>
import { mapState } from 'vuex';
import { layoutClasses } from '../constants/layout';
import thaipostLogo from '../assets/images/logo/thai-post-logo.png';
import smallThaipostLogo from '../assets/images/logo/logo-icon.png';
// import csLogo from '../assets/images/logo/cloudsoft.png'
import smartCallLogo from '../assets/images/logo/smartcall_logo.png';
export default {
  name: 'Sidebar',
  data() {
    return {
      imgSrc: '',
      smallImgSrc: '',
      width: 0,
      height: 0,
      margin: 0,
      hideRightArrowRTL: true,
      hideLeftArrowRTL: true,
      hideRightArrow: true,
      hideLeftArrow: true,
      menuWidth: 0,
      toggle_sidebar_var: false,
      clicked: false,
      layoutobj: {},
      thaipostLogo:thaipostLogo
    };
  },
  computed: {
    ...mapState({
      menuItems: (state) => state.menu.data,
      menuItemPer: (state) => state.menu.dataPermission,
      layout: (state) => state.layout.layout,
      sidebar: (state) => state.layout.sidebarType,
    }),
    layoutobject: {
      get: function () {
        return JSON.parse(
          JSON.stringify(
            layoutClasses.find(
              (item) => Object.keys(item).pop() === this.layout.settings.layout
            )
          )
        )[this.layout.settings.layout];
      },
      set: function () {
        this.layoutobj = layoutClasses.find(
          (item) => Object.keys(item).pop() === this.layout.settings.layout
        );
        this.layoutobj = JSON.parse(JSON.stringify(this.layoutobj))[
          this.layout.settings.layout
        ];
        return this.layoutobj;
      },
    },
  },
  created() {
    this.checkPermission();
    window.addEventListener('resize', this.handleResize);
    this.handleResize();
    if (this.width < 991) {
      this.layout.settings.layout = 'Dubai';
      this.margin = 0;
    }
    setTimeout(() => {
      const elmnt = document.getElementById('myDIV');
      this.menuWidth = elmnt.offsetWidth;
      this.menuWidth > window.innerWidth
        ? ((this.hideRightArrow = false), (this.hideLeftArrowRTL = false))
        : ((this.hideRightArrow = false), (this.hideLeftArrowRTL = true));
    }, 500);
    this.layoutobject = layoutClasses.find(
      (item) => Object.keys(item).pop() === this.layout.settings.layout
    );
    this.layoutobject = JSON.parse(JSON.stringify(this.layoutobject))[
      this.layout.settings.layout
    ];
  },
  destroyed() {
    window.removeEventListener('resize', this.handleResize);
  },
  mounted() {
    this.imgSrc =
      window.location.hostname == 'thailandpost.smartcall.cloud'
        ? thaipostLogo
        : smartCallLogo;
    this.smallImgSrc =
      window.location.hostname == 'thailandpost.smartcall.cloud'
        ? smallThaipostLogo
        : smartCallLogo;
    this.menuItems.filter((items) => {
      if (items.path === this.$route.path)
        // console.log("Item menu: ", items);
        this.$store.dispatch('menu/setActiveRoute', items);
      if (!items.children) return false;

      items.children.filter((subItems) => {
        // console.log("Subitem menu: ", subItems);
        if (subItems.path === this.$route.path)
          this.$store.dispatch('menu/setActiveRoute', subItems);

        if (!subItems.children) return false;

        subItems.children.filter((subSubItems) => {
          // console.log("subSubItem menu: ", subSubItems);
          if (subSubItems.path === this.$route.path)
            this.$store.dispatch('menu/setActiveRoute', subSubItems);
        });
      });
    });
  },
  methods: {
    checkPermission() {
      let userPer = localStorage.getItem('permission');
      let email = localStorage.getItem('email');
      let menuPer = this.$store.state.menu.data;
      let menuItemPer = [];
      // console.log('aa',menuPer)
      menuPer.forEach((menu) => {
          if (userPer.includes('is_admin')) {
              if(menu.version == 2){
                if(email.includes('cloudsoft')){
                  menuItemPer.push(menu);
                }
              }else{
                menuItemPer.push(menu);
              }
          } else {
           
            if (userPer.includes(menu.permission)) {
              if(menu.version == 2){
                if(email.includes('cloudsoft')){
                  menuItemPer.push(menu);
                }
              }else{
                menuItemPer.push(menu);
              }
            }
          }
      });
      this.$store.dispatch('menu/checkpermission', menuItemPer);
    },
    
    toggle_sidebar() {
      this.$store.dispatch('menu/opensidebar');
    },
    setNavActive(item) {
      this.$store.dispatch('menu/setNavActive', item);
      // console.log("setNavActive: ", item);
    },
    handleResize() {
      this.width = window.innerWidth - 310;
    },
    scrollToRightRTL() {
      this.temp = this.width + this.margin;
      // Checking condition for remaing margin
      if (this.temp === 0) {
        this.margin = this.temp;
        this.hideRightArrowRTL = true;
      }
      // else
      else {
        this.margin += this.width;
        this.hideRightArrowRTL = false;
        this.hideLeftArrowRTL = false;
      }
    },
    scrollToLeftRTL() {
      // If Margin is reach between screen resolution
      console.log('this.margin', this.margin);
      if (this.margin <= -this.width) {
        this.margin += -this.width;
        this.hideLeftArrowRTL = true;
      }
      //Else
      else {
        this.margin += -this.width;
        this.hideRightArrowRTL = false;
      }
    },
    scrollToLeft() {
      console.log('left');

      // If Margin is reach between screen resolution
      if (this.margin >= -this.width) {
        this.margin = 0;
        this.hideLeftArrow = true;
      }
      //Else
      else {
        this.margin += this.width;
        this.hideRightArrow = false;
      }
    },
    scrollToRight() {
      this.temp = this.menuWidth + this.margin;
      // Checking condition for remaing margin
      if (this.temp < this.menuWidth) {
        this.margin = -this.temp;
        this.hideRightArrow = true;
      }
      // else
      else {
        this.margin += -this.width;
        this.hideLeftArrow = false;
      }
    },
  },
};
</script>
<style>
/* #sidebar-thaipost-components{
  background: linear-gradient( 5deg, #0569ffab, #003cff);
} */
</style>
<!-- #0569ff -->
<!-- #006FFF -->
<!-- #1254FF -->
<!-- #267dff -->
<!-- #4d9aff - 35% -->
