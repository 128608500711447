import axios from 'axios';

let token = localStorage.getItem('token');

const instance = axios.create({
  headers: {
    Authorization: `Bearer ${token}`,
  },
});
// const instance = axios.create({
//   headers: {
//     'Access-Control-Allow-Origin': '*',
//     'Content-Type': 'application/json',
//     Authorization: 'Bearer ' + token,
//   },
// });

export default instance;
