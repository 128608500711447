<
<template>
  <div>
    <b-modal
      id="editWhielist"
      hide-footer
      content-class="shadow"
      title="แก้ไขข้อมูล"
      size="lg"
      class=""
      @hide="closeModal"
    >
      <b-form class="needs-validation" @submit.prevent="editWl">
        <div class="form-row d-flex flex-column p-3">
          <div class="d-flex row">
            <div class="col-4 p-1">
              <label
                for="create_datatable_whitelist_username"
                class=""
                style="font-size: 10px; font-weight: 600; margin: 0 0 2px"
                >เบอร์โทรศัพท์ : <span class="font-danger">*</span></label
              >
              <b-form-group
                label-for="create_datatable_whitelist_username"
                description="กรอกได้เพียงตัวเลข 0-9 และควรมีความยาวทั้งสิ้น 10 ตัวอักษร"
              >
                <b-form-input
                  type="number"
                  class="inputNum"
                  id="create_datatable_whitelist_username"
                  style="font-size: 10px; width: 100% !important"
                  size="sm"
                  v-model="formGetDatatable.username"
                  pattern="[0-9]{1,9}"
                  :formatter="formatNum"
                  required
                  disabled
                  placeholder="เบอร์โทรศัพท์"
                >
                </b-form-input>
              </b-form-group>
            </div>

           <!-- !isThaipost -->
           <div v-if="!isThaipost" class="media-css col-4 p-1">
              <label
                for="create_datatable_whitelist_domain"
                style="font-size: 10px; margin: 0 0 2px; font-weight: 600"
                >แผนก : <span class="font-danger">*</span></label
              >
              <b-form-group
                label-for="create_datatable_whitelist_grp"
                description="ระบุแผนก"
                style="padding: 0 0 0 0"
              >
                <b-form-input
                  type="text"
                  id="create_datatable_whitelist_grp"
                  style="font-size: 10px"
                  class="rowTwoInput"
                  size="sm"
                  v-model="formGetDatatable.post_office"
                  placeholder="แผนก"
                >
                </b-form-input>
              </b-form-group>
            </div>

            <div v-if='!isThaipost' class="media-css col-4 p-1">
              <label
                for="create_datatable_whitelist_domain"
                style="font-size: 10px; margin: 0 0 2px; font-weight: 600"
                >หมายเลขผู้โทรออก :<span class="font-danger">*</span></label
              >
              <b-form-group
                label-for="create_datatable_whitelist_grp"
                description="ระบุหมายเลขผู้โทรออก (กรอกได้เพียงตัวเลขเท่านั้น)"
                style="padding: 0 0 0 0"
              >
                <b-form-input
                  type="number"
                  id="create_datatable_whitelist_grp"
                  style="font-size: 10px"
                  class="rowTwoInput inputNum"
                  size="sm"
                  v-model="formGetDatatable.root_call"
                  required
                  placeholder="หมายเลขผู้โทรออก"
                >
                </b-form-input>
              </b-form-group>
            </div>

            <!-- isThaipost -->
            <div  v-if="isThaipost" class="col-4 p-1" style="margin: 0">
              <label
                for="create_datatable_whitelist_username"
                class=""
                style="font-size: 10px; margin: 0 0 2px; font-weight: 600"
                >สังกัด :</label
              >
              <b-form-group
                label-for="create_datatable_whitelist_username"
                description="ระบุสังกัด"
              >
                <b-form-input
                  type="text"
                  id="create_datatable_whitelist_domain"
                  style="font-size: 10px"
                  class="rowTwoInput"
                  size="sm"
                  v-model="formGetDatatable.subordination"
                  placeholder="สังกัด"
                />
              </b-form-group>
            </div>
            <div  v-if="isThaipost" class="col-4 p-1">
              <label
                for="create_datatable_whitelist_domain"
                style="font-size: 10px; margin: 0 0 2px; font-weight: 600"
                >รหัสไปรษณีย์ :</label
              >
              <b-form-group
                label-for="create_datatable_whitelist_domain"
                description="ระบุรหัสไปรษณีย์"
              >
                <b-form-input
                  type="number"
                  id="create_datatable_whitelist_domain"
                  style="font-size: 10px"
                  class="rowTwoInput inputNum"
                  size="sm"
                  v-model="formGetDatatable.postcode"
                  placeholder="รหัสไปรษณีย์"
                >
                </b-form-input>
              </b-form-group>
            </div>
          </div>

          <!-- === row 2=== -->
          <div class="d-flex row p-1">
            <div v-if='isThaipost' class="media-css col-4 p-1">
              <label
                for="create_datatable_whitelist_domain"
                style="font-size: 10px; margin: 0 0 2px; font-weight: 600"
                >ที่ทำการ :</label
              >
              <b-form-group
                label-for="create_datatable_whitelist_grp"
                description="ระบุที่ทำการ"
                style="padding: 0 0 0 0"
              >
                <b-form-input
                  type="text"
                  id="create_datatable_whitelist_grp"
                  style="font-size: 10px"
                  class="rowTwoInput"
                  size="sm"
                  v-model="formGetDatatable.post_office"
                  placeholder="ที่ทำการ"
                >
                </b-form-input>
              </b-form-group>
            </div>
            <div class="media-css col-4 p-1">
              <label
                for="create_datatable_whitelist_domain"
                style="font-size: 10px; margin: 0 0 2px; font-weight: 600"
                >เบอร์โอนสายในเวลาทำการ : <span class="font-danger">*</span></label
              >
              <b-form-group
                label-for="create_datatable_whitelist_inservice"
                description="เบอร์โอนสายในเวลาทำการ (กรอกได้เพียงตัวเลขเท่านั้น)"
                style="padding: 0 0 0 0"
              >
                <b-form-input
                  type="number"
                  id="create_datatable_whitelist_inservice"
                  style="font-size: 10px"
                  class="rowTwoInput inputNum"
                  size="sm"
                  v-model="formGetDatatable.inservice"
                  placeholder="เบอร์โอนสายในเวลาทำการ"
                >
                </b-form-input>
              </b-form-group>
            </div>
            <div class="media-css col-4 p-1">
              <label
                for="create_datatable_whitelist_domain"
                style="font-size: 10px; margin: 0 0 2px; font-weight: 600"
                >เบอร์โอนสายนอกเวลาทำการ : <span class="font-danger">*</span></label
              >
              <b-form-group
                label-for="create_datatable_whitelist_oos"
                description="เบอร์โอนสายนอกเวลาทำการ (กรอกได้เพียงตัวเลขเท่านั้น)"
                style="padding: 0 0 0 0"
              >
                <b-form-input
                  type="number"
                  id="create_datatable_whitelist_oos"
                  style="font-size: 10px"
                  class="rowTwoInput inputNum"
                  size="sm"
                  v-model="formGetDatatable.oos"
                  placeholder="เบอร์โอนสายนอกเวลาทำการ"
                >
                </b-form-input>
              </b-form-group>
            </div>
         
          </div>

          <div v-if='isThaipost' class="d-flex row p-1">
            <div class="media-css col-4 p-1">
              <label
                for="create_datatable_whitelist_domain"
                style="font-size: 10px; margin: 0 0 2px; font-weight: 600"
                >Root Call : <span class="font-danger">*</span></label
              >
              <b-form-group
                label-for="create_datatable_whitelist_grp"
                description="ระบุ Root Call"
                style="padding: 0 0 0 0"
              >
                <b-form-input
                  type="number"
                  id="create_datatable_whitelist_grp"
                  style="font-size: 10px"
                  class="rowTwoInput inputNum"
                  size="sm"
                  v-model="formGetDatatable.root_call"
                  required
                  placeholder="Root Call"
                >
                </b-form-input>
              </b-form-group>
            </div>
           
          </div>
        
          <!-- === row 3 === -->
          <div class="d-flex flex-column row p-1">
            <div class="col-6 p-0">
              <label
                for="create_datatable_whitelist_domain"
                style="font-size: 10px; font-weight: 600"
                >วันทำการ :</label
              >
              <div class="pl-4 d-flex col-3">
                <div v-for="(date, idate) in workDateOptions" :key="idate">
                  <div class="mr-4">
                    <input
                      class="form-check-input"
                      type="checkbox"
                      id="inlineCheckbox1"
                      v-model="date.isCheck"
                      value="date.value"
                    />
                    <label
                      class="form-check-label"
                      for="inlineCheckbox1"
                      style="font-size: 10px"
                      >{{ date.text }}</label
                    >
                  </div>
                </div>

              </div>
            </div>
          </div>

          <!-- === row 4 === -->
          <div class="d-flex row p-1 mt-2">
            <div class="mr-3 p-1">
              <label
                for="worktime_startTime"
                style="font-size: 10px; width: max-content; font-weight: 600"
                >เวลาเริ่มทำการ</label
              >
              <b-form-group label-for="worktime_startTime">
                <b-form-input
                  v-model="startTime"
                  type="time"
                  id="worktime_startTime"
                  style="font-size: 10px; width: 150px !important"
                  size="sm"
                >
                </b-form-input>
              </b-form-group>
            </div>

            <div class="p-1">
              <label
                for="worktime_endtime"
                style="font-size: 10px; width: max-content; font-weight: 600"
                >เวลาเลิกทำการ</label
              >
              <b-form-group label-for="worktime_endtime">
                <b-form-input
                  v-model="endTime"
                  type="time"
                  id="worktime_endtime"
                  style="font-size: 10px; width: 150px !important"
                  size="sm"
                >
                </b-form-input>
              </b-form-group>
            </div>
          </div>
          <!-- btn -->
          <div class="p-0">
            <b-row class="row">
              <b-col class="d-flex align-items-center p-0">
                <b-button
                  type="submit"
                  id="submit-add-whitelist-btn-z"
                  class="btn btn-pill btn-xs"
                  style="width: 90px; height: 24px"
                  size="sm"
                  variant="primary"
                  :disabled="checkRequire()"
                >
                  แก้ไข
                </b-button>
              </b-col>
            </b-row>
          </div>
        </div>
      </b-form>
    </b-modal>
  </div>
</template>

<script>
import { requestService } from '@/_services/request.service.js';
export default {
  name: 'addWhitelist',
props: ['isThaipost'],
  data() {
    return {
      isMonday: false,
      workDateOptions: [
        { text: 'จันทร์', value: '1', isCheck: false },
        { text: 'อังคาร', value: '2', isCheck: false },
        { text: 'พุธ', value: '3', isCheck: false },
        { text: 'พฤหัสบดี', value: '4', isCheck: false },
        { text: 'ศุกร์', value: '5', isCheck: false },
        { text: 'เสาร์', value: '6', isCheck: false },
        { text: 'อาทิตย์', value: '7', isCheck: false },
      ],
      startTime: '08:00',
      endTime: '17:00',
      workDateSeleted: [],
      formGetDatatable: {
        username: '',
        // domain: '',
        // grp: '',
        subordination: '',
        postcode: '',
        post_office: '',
        root_call: '',
        inservice: 1545,
        oos: 1545,
      },
      userid: null,
      lastModi: null,
    };
  },

  mounted() {},

  methods: {
    checkRequire() {
      if (this.isThaipost) {
        return !(
          this.formGetDatatable.username &&
          this.formGetDatatable.root_call &&
          this.formGetDatatable.inservice &&
          this.formGetDatatable.oos
        );
      } else {
        return !(
          this.formGetDatatable.username &&
          this.formGetDatatable.root_call &&
          this.formGetDatatable.inservice &&
          this.formGetDatatable.oos &&
          this.formGetDatatable.post_office
        );
      }
    },
    setData(e) {
      this.checkWorkDate(e.work_date);
      const [start, end] = e.work_time.split('-');
      this.userid = e.id;
      this.lastModi = e.last_modified_raw;
      this.startTime = start.trim();
      this.endTime = end.trim();
      this.formGetDatatable.username = e.username;
      this.formGetDatatable.root_call = e.root_call;
      this.formGetDatatable.subordination = e.subordination;
      this.formGetDatatable.postcode = e.postcode;
      this.formGetDatatable.post_office = e.post_office;
      this.formGetDatatable.inservice = e.in_service;
      this.formGetDatatable.oos = e.oos;
    },
    checkWorkDate(date) {
      const abbreviatedDays = date.split(',');

      this.workDateOptions.forEach((option) => {
        // Check if the abbreviated day name is present in the work_date string
        if (abbreviatedDays.includes(this.getAbbreviatedDay(option.text))) {
          option.isCheck = true;
        }
      });
    },
    getAbbreviatedDay(fullDay) {
      // You may need to adjust this function based on the actual abbreviations used in your data
      switch (fullDay) {
        case 'จันทร์':
          return 'จ';
        case 'อังคาร':
          return 'อ';
        case 'พุธ':
          return 'พ';
        case 'พฤหัสบดี':
          return 'พฤ';
        case 'ศุกร์':
          return 'ศ';
        case 'เสาร์':
          return 'ส';
        case 'อาทิตย์':
          return 'อา';
        default:
          return '';
      }
    },
    formatNum(e) {
      let formattedValue = String(e).substring(0, 9);
      if (formattedValue.startsWith('0')) {
        formattedValue = formattedValue.slice(1);
      }

      return formattedValue;
    },
    closeModal() {
      this.startTime = '';
      this.endTime = '';
      this.workDateSeleted = [];
      this.workDateOptions.forEach((e) => {
        e.isCheck = false;
      });
      this.formGetDatatable = {
        username: '',
        // domain: '',
        // grp: '',
        subordination: '',
        postcode: '',
        post_office: '',
        root_call: '',
        inservice: 1545,
        oos: 1545,
      };
    },
    editWl() {
      // console.log(this.startTime, this.endTime);
      let workDateFinal = `${this.deleteColon(
        this.startTime
      )}${this.deleteColon(this.endTime)}`;

      this.workDateOptions.forEach((e) => {
        if (e.isCheck) {
          this.workDateSeleted.push(e.value);
        }
      });
      let workDate = this.workDateSeleted.join('');
      // let phoneNum = '';

      // if (this.formGetDatatable.username != '') {
      //   if (this.formGetDatatable.username.startsWith('0')) {
      //     phoneNum = this.formGetDatatable.username.slice(1);
      //   } else {
      //     phoneNum = this.formGetDatatable.username;
      //   }
      // } else {
      //   phoneNum = this.formGetDatatable.username;
      // }

      let obj = {
        id: this.userid,
        username: this.formGetDatatable.username,
        domain: `|${
          this.formGetDatatable.subordination != ''
            ? `${this.formGetDatatable.subordination}`
            : ''
        }${
          this.formGetDatatable.postcode != ''
            ? `|${this.formGetDatatable.postcode}`
            : '|'
        }${
          this.formGetDatatable.post_office != ''
            ? `|${this.formGetDatatable.post_office}`
            : '|'
        }${
            this.formGetDatatable.root_call != ''
              ? `|${this.formGetDatatable.root_call}`
              : '|'
          }${
          this.formGetDatatable.inservice != ''
            ? `|${this.formGetDatatable.inservice}`
            : '|1545'
        }${
          this.formGetDatatable.oos != ''
            ? `|${this.formGetDatatable.oos}`
            : '|1545'
        }${workDate != '' ? `|${workDate}` : '|12345'}${
          workDateFinal != '' ? `|${workDateFinal}` : '|08001700'
        }`,
        // domain: `${this.formGetDatatable.subordination}|${this.formGetDatatable.postcode}|${this.formGetDatatable.post_office}|${this.formGetDatatable.root_call}|${this.formGetDatatable.inservice}|${this.formGetDatatable.oos}|${workDate}|${workDateFinal}`,
        // domain: `${this.formGetDatatable.domain}|${this.formGetDatatable.subordination}|${this.formGetDatatable.postcode}|${this.formGetDatatable.post_office}|${this.formGetDatatable.root_call}|${this.formGetDatatable.inservice}|${this.formGetDatatable.oos}|${workDate}|${workDateFinal}`,
        grp: 'thaipost',
        last_modified: this.lastModi,
      };
// console.log('patch: ', obj)
      let url = `/whitelist`;
      requestService
        .PatchFetch(url, obj)
        .then((res) => {
          // console.log('res');
          this.$emit('editUser');
          this.$bvModal.hide('editWhielist');
          this.$swal.fire({
            icon: 'success',
            title: 'แก้ไขข้อมูลสำเร็จ',
            showConfirmButton: false,
            timer: 1000,
          });
      // console.log('formGetDatatable: ', obj);
      //   this.$emit('postDataTable', arr);
      this.startTime = '';
      this.endTime = '';
      this.workDateSeleted = [];
      this.workDateOptions.forEach((e) => {
        e.isCheck = false;
      });
      this.formGetDatatable = {
        username: '',
        // domain: '',
        // grp: '',
        subordination: '',
        postcode: '',
        post_office: '',
        root_call: '',
        inservice: 1545,
        oos: 1545,
      };
        })
        .catch((err) => {
          this.$swal.fire({
            icon: 'error',
            title: 'มีบางอย่างผิดพลาด',
            text: 'ไม่สามารถแก้ไขข้อมูลได้',
            showConfirmButton: false,
            timer: 1200,
          });
          // console.log('patch err: ', err);
        });
  
    },
    deleteColon(e) {
      return e.replace(/:/g, '');
    },
  },
};
</script>

<style scoped>
.rowTwoInput {
  width: 100%;
}

#submit-add-whitelist-btn-z {
  color: #3f60fb;
  background-color: #dfedff !important;
  border-color: #dfedff !important;
}
.inputNum::-webkit-inner-spin-button,
inputNum::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

@media only screen and (min-width: 480px) and (max-width: 991px) {
  div.modal-body form.needs-validation div.form-row div.media-css {
    margin: 0px 0px 0px 0px !important;
  }
}
</style>
