<template>
  <div>
    <Breadcrumbs title="Admin Setting" />
    <div class="container-fluid mb-2">
      <div class="row card" style="height: 54vh">
        <div
          style="padding-top: 3rem"
          class="d-flex flex-column align-items-center"
        >
          <div
            class="d-flex flex-column align-items-center"
            style="padding-top: 1rem"
          >
            <img
              :src="imgSrc"
              style="width: 6.5rem"
              class="ml-0 mt-0"
              alt="logo website"
            />
            <input
              id="change_logo"
              type="file"
              ref="fileInput"
              style="display: none"
              accept="image/*"
            />
            <label for="change_logo" class="file_btn">เลือกรูปภาพ</label>
          </div>
          <div class="mt-3">
            <p class="m-0" style="font-size: 1rem; font-weight: 600">
              ชื่อเว็บไซต์
            </p>
            <b-form-input
              v-model="name"
              class="m-0"
              style="width: 17rem"
            ></b-form-input>
          </div>
        </div>

        <div class="d-flex justify-content-center" style="margin-top: 3rem">
          <b-button type="submit" variant="primary" class="mr-2">บันทึก</b-button>
          <b-button type="submit" variant="danger">ยกเลิก</b-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import thaipostLogo from "../../assets/images/logo/thai-post-logo.png"
import csLogo from '../../assets/images/logo/cloudsoft.png'
export default {
  data() {
    return {
      logopath: '',
      name: '',
      imgSrc: ''
    };
  },
  mounted() {
    this.name = window.location.hostname == 'thailandpost.smartcall.cloud' ? 'Thailand Post' : 'Smart Call'
    this.imgSrc = window.location.hostname == 'thailandpost.smartcall.cloud' ? thaipostLogo : csLogo
  },
  methods: {},
};
</script>

<style>
.file_btn {
  cursor: pointer;
  border-radius: 0.9rem;
  padding: 0.4rem 0rem;
  width: 6rem;
  font-size: 0.6rem;
  display: flex;
  justify-content: center;
  color: #fff;
  margin-top: 0.9rem;
  background: #3f60fb;
}
.file_btn:hover {
  background: #2c4ee7;
}
</style>
