import Vue from 'vue';
import Vuex from 'vuex';

Vue.use(Vuex);

let state = {
  email: '',
  token: '',
  exptoken: null,
  id: '',
  firstname: null,
  lastname: null,
  permission: null,
};

const getters = {
  access_token(state) {
    return state.user_detail;
  },
};

const mutations = {
  SET_USER: (state, payload) => {
    state.token = payload.token;
    state.id = payload.id;
    state.firstname = payload.firstname;
    state.lastname = payload.lastname;
    state.email = payload.email;
    localStorage.setItem('token', state.token);
    localStorage.setItem('id', state.id);
    localStorage.setItem('firstname', state.firstname);
    localStorage.setItem('lastname', state.lastname);
    localStorage.setItem('email', state.email);
  },
  SET_NAME: (state, payload) => {
    state.firstname = payload.firstname;
    state.lastname = payload.lastname;
    localStorage.setItem('firstname', state.firstname);
    localStorage.setItem('lastname', state.lastname);
  },
  SET_PERMISSION: (state, payload) => {
    const userPermissionArr = [];
    for (const key in payload) {
      if (payload[key] === true) {
        userPermissionArr.push(key);
      }
    }
    const userPermission = userPermissionArr.toString();
    state.permission = userPermission;
    localStorage.setItem('permission', state.permission);
    // console.log(userPermission);
  },
  SET_EXPIRED: (state, payload) => {
    state.exptoken = payload.exp;
    // console.log(state.exptoken);
    localStorage.setItem('exp', state.exptoken);
  },
};

const actions = {
  SET_USER: (context, payload) => {
    context.commit('SET_USER', payload);
  },
  SET_NAME: (context, payload) => {
    context.commit('SET_NAME', payload);
  },
  SET_PERMISSION: (context, payload) => {
    context.commit('SET_PERMISSION', payload);
  },
  SET_EXPIRED: (context, payload) => {
    context.commit('SET_EXPIRED', payload);
  },
};

export default {
  state,
  getters,
  mutations,
  actions,
};
