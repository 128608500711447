<template>
  <div>
    <div class="container-fluid p-0">
      <div class="row m-0">
        <div class="col-12 p-0">
          <div class="login-card">
            <div class="login-main">
              <b-card-text>
                <form @submit.prevent="onSubmit">
                  <div class="card-body wrapper">
                    <div class="p-3">
                      <div
                        class="d-flex flex-column justify-content-center align-items-center"
                      >
                        <img
                          :src="imgSrc"
                          :width="imgSrc == thaipostLogo ? 130 : 80"
                          :height="imgSrc == thaipostLogo ? 65 : 80"
                          alt='logo website'
                        />
                        <h4 class='mt-3'>Sign in to account</h4>
                      </div>
                      <!-- Email -->
                      <div class="mt-4">
                        <div class="form-group">
                          <label>Email Address</label>
                          <div class="input-box">
                            <b-form-input
                              id="input-email"
                              v-model="email"
                              class="inputform"
                              placeholder="Please input your email"
                            ></b-form-input>
                          </div>
                          <p class="text-danger mt-1">{{ emailMessage }}</p>
                        </div>
                        <!-- Email Ends -->

                        <!-- Password -->
                        <div class="form-group">
                          <label>Password</label>
                          <div class="input-box" style="padding-right: 10px">
                            <b-form-input
                              v-model="password"
                              class="inputform"
                              placeholder="Please input your password"
                              :type="showPassword ? 'text' : 'password'"
                            >
                            </b-form-input>
                            <div class="eyeshow">
                              <feather
                                @click="showPassword = !showPassword"
                                :type="showPassword ? 'eye' : 'eye-off'"
                                style="width: 20px; height: 20px"
                              ></feather>
                            </div>
                          </div>
                          <p class="text-danger mt-1">{{ passwordMessage }}</p>
                          <p class="text-danger mt-1">{{ invalidMessage }}</p>
                        </div>
                      </div>
                      <!-- Password Ends -->
                      <!-- Button -->
                      <div class="btn-wrapper">
                        <b-button
                          type="submit"
                          variant="primary"
                          class="mr-1 py-2 w-100 rounded-pill btn-signin"
                          >Sign In</b-button
                        >
                      </div>
                      <!-- Button Ends -->
                    </div>
                  </div>
                </form>
              </b-card-text>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import jwt_decode from 'jwt-decode';
import { requestService } from '../_services/request.service';
import thaipostLogo from "../assets/images/login/thai-post-logo.png"
// import csLogo from '../assets/images/logo/cloudsoft.png'
import smartCallLogo from '../assets/images/logo/smartcall_logo.png'
export default {
  name: 'LoginPage',
  data() {
    return {
      email: '',
      password: '',
      emailMessage: '',
      passwordMessage: '',
      invalidMessage: '',
      showPassword: false,
      imgSrc:'',
      thaipostLogo: thaipostLogo
    };
  },
  mounted() {
    this.imgSrc = window.location.hostname == 'thailandpost.smartcall.cloud' ? thaipostLogo : smartCallLogo
  },
  methods: {
    onSubmit() {
      requestService
        .PostFetch('/login', {
          email: this.email,
          password: this.password,
        })
        .then((response) => {
          // console.log('token from login: ', response.data.data);
          this.invalidMessage = '';
          this.$store.dispatch('SET_USER', response.data.data);
          let decoded = jwt_decode(response.data.data.token);
          this.$store.dispatch('SET_EXPIRED', decoded);
          this.$store.dispatch('SET_PERMISSION', decoded);
          // this.$router.push('/whitelist/whitelistpage');
          this.$router.push('/home');
        })
        .catch((error) => {
          console.log('err: ', error);
          this.invalidMessage = 'Incorrect email or password.';
        });
    },
  },
};
</script>

<style scoped>
.wrapper {
  width: 500px;
  background-color: #fff;
  border-radius: 10px;
  padding: 1.7rem;
  filter: drop-shadow(0 0 0.75rem #dcebff);
}
.input-box {
  border: 0.5px solid #e3e5e8 !important;
  border-radius: 5px;
  display: flex;
  align-items: center;
  /* padding-right: 10px; */
}

.inputform {
  height: 40px;
  width: 100%;
  background-color: #fff !important;
  border: none;
  margin-bottom: 0 !important;
}

.inputform:focus {
  border-color: none;
  box-shadow: none;
}

.eyeshow {
  padding: 8px 0 0 10px;
  cursor: pointer;
  border-left: 0.5px solid #e3e5e8;
}
.btn-wrapper {
  margin-top: 2rem;
  margin-bottom: 2rem;
}

.btn-signin {
  background-color: #0569ff !important;
  font-size: 1rem;
}
.btn-signin:hover {
  background-color: #0258da !important;
}
h4 {
  font-size: 1.8rem;
  font-weight: 400;
  margin-left: 10px;
  margin-top: 5px;
}

label {
  font-size: 1rem !important;
}
</style>
