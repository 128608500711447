<template>
  <div>
    <Breadcrumbs title="รายงานประจำวัน" />
    <!-- Container-fluid starts-->
    <div class="container-fluid">
      <div class="row">
        <div class="col-md-12">
          <div class="card">
            <b-row
              class="justify-content-start p-b-0"
              style="padding-top: 1px !important"
            >
              <b-col class="p-l-30 p-r-20 p-b-0" style="height: 64px">
                <label class="col-form-label" style="font-size: 10px"
                  >วันที่เริ่มต้น</label
                >
                <div class="col-sm-12 p-l-0 p-r-0">
                  <b-form-input
                    v-model="start_date_daily"
                    type="date"
                    value=""
                    size="sm"
                    style="font-size: 10px"
                  ></b-form-input>
                </div>
              </b-col>

              <b-col class="p-l-0 p-r-20 p-b-0" style="height: 64px">
                <label class="col-form-label" style="font-size: 10px"
                  >วันที่สิ้นสุด</label
                >
                <div class="col-sm-12 p-l-0 p-r-0">
                  <b-form-input
                    v-model="end_date_daily"
                    type="date"
                    value=""
                    size="sm"
                    style="font-size: 10px"
                  ></b-form-input>
                </div>
              </b-col>

              <!-- ========== Button ========== -->
              <b-row
                class="row"
                style="margin: 0 0px 0 0px; padding: 0 1px 0 0px"
              >
                <b-col class="d-flex align-items-center p-l-0">
                  <b-button
                    @click="getPaginationDaily"
                    type="button"
                    variant="primary"
                    id="search-one-daily-btn-z"
                    class="btn btn-pill btn-xs"
                    style="width: 90px; height: 24px; margin: 9px 15px 0 0"
                  >
                    ค้นหา
                  </b-button>
                  <b-button
                    @click="clearFilterAll"
                    type="button"
                    variant="danger"
                    id="clear-one-daily-btn-z"
                    class="btn btn-pill btn-xs h-2"
                    style="width: 90px; height: 24px; margin: 9px 15px 0 0"
                  >
                    ล้าง
                  </b-button>
                </b-col>
              </b-row>
              <!-- ========== Button ========== -->
            </b-row>
          </div>

          <div class="card">
            <div class="card-body">
              <b-row class="justify-content-end">
                <!-- ========== Export CSV ========== -->
                <b-row class="row mb-3">
                  <b-col
                    class="d-flex"
                    style="margin: 0 0px 0 0px; padding: 0 10px 0px 0px"
                  >
                    <!-- == export btn -->
                    <b-button
                      @click="getExportCSVDaily"
                      id="export-csv-daily-btn-z"
                      class="btn btn-pill btn-xs"
                      size="sm"
                      variant="primary"
                      :disabled="exportBtn"
                      style="color: white"
                      :style="{
                        backgroundColor: exportBtn ? '#d0d4ca !important' : '',
                        border: exportBtn ? 'none' : '',
                      }"
                    >
                      <b-spinner
                        v-if="isExport == 100"
                        small
                        label="Loading..."
                      ></b-spinner>
                      <div v-if="isExport == 0">
                        <font-awesome-icon icon="fa-solid fa-file-export" />
                        ส่งออก
                      </div>
                    </b-button>
                    <exportData ref="exportdaily" />
                  </b-col>
                </b-row>

                <b-row class="row">
                  <b-col class="mr-3">
                    <b-form-select
                      v-model="perPage"
                      :options="pageOptions"
                      class="btn-xs"
                      style="
                        width: 55px;
                        height: 24px;
                        margin: 2px 0px 0 15px;
                        font-size: 10px !important;
                      "
                    >
                    </b-form-select>
                  </b-col>
                </b-row>
              </b-row>
              <div class="daily-report table-responsive">
                <v-data-table
                  :headers="headers"
                  :items="rows"
                  :page.sync="currentPage"
                  hide-default-footer
                  :items-per-page="perPage"
                  @page-count="pageCount = $event"
                  class="elevation-1"
                  :loading="isLoading"
                  :sort-by.sync="sortBy"
                  :sort-desc.sync="sortDesc"
                  @update:sort-by="onSorted"
                >
                  <template v-slot:loading>
                    <v-progress-circular
                      indeterminate
                      color="primary"
                      size="40"
                      class="ma-2"
                    ></v-progress-circular>
                  </template>
                  <template slot="no-data"> ไม่มีข้อมูล.. </template>
                  <template v-slot:body.prepend>
                    <tr>
                      <td
                        v-for="(item, index) in sumRows"
                        :key="index"
                        class="text-center totalRow"
                        style="font-size: 10px; font-weight: 600"
                      >
                        {{ item }}
                      </td>
                    </tr>
                  </template>

                  <template v-slot:item.date="{ item }">
                    <div
                      class="text-center"
                      style="width: 100px; font-size: 10px"
                    >
                      {{ item.date }}
                    </div>
                  </template>
                  <template v-slot:item.total_calls="{ item }">
                    <div
                      class="text-center p-0 m-0"
                      style="width: 80px; font-size: 10px"
                    >
                      {{ item.total_calls.toLocaleString() }}
                    </div>
                  </template>
                  <template v-slot:item.out_bound_calls="{ item }">
                    <div
                      class="text-center"
                      style="width: 120px; font-size: 10px"
                    >
                      {{ item.out_bound_calls.toLocaleString() }}
                    </div>
                  </template>
                  <template v-slot:item.in_bound_calls="{ item }">
                    <div
                      class="text-center"
                      style="width: 120px; font-size: 10px"
                    >
                      {{ item.in_bound_calls.toLocaleString() }}
                    </div>
                  </template>

                  <template v-slot:item.answered_calls="{ item }">
                    <div
                      class="text-center"
                      style="width: 120px; font-size: 10px"
                    >
                      {{ item.answered_calls.toLocaleString() }}
                    </div>
                  </template>
                  <template v-slot:item.abandoned_calls="{ item }">
                    <div
                      class="text-center"
                      style="width: 140px; font-size: 10px"
                    >
                      {{ item.abandoned_calls.toLocaleString() }}
                    </div>
                  </template>
                  <template v-slot:item.per_answered_calls="{ item }">
                    <div
                      class="text-center"
                      style="width: 130px; font-size: 10px"
                    >
                      {{ item.per_answered_calls }}
                    </div>
                  </template>
                  <template v-slot:item.per_abandon_calls="{ item }">
                    <div
                      class="text-center"
                      style="width: 150px; font-size: 10px"
                    >
                      {{ item.per_abandon_calls }}
                    </div>
                  </template>
                  <template v-slot:item.average_talk_time="{ item }">
                    <div
                      class="text-center"
                      style="width: 130px; font-size: 10px"
                    >
                      {{ convertAverageTalkTime(item.average_talk_time) }}
                    </div>
                  </template>
                </v-data-table>

                <div class="text-center pt-2 sm">
                  <b-pagination
                    v-model="currentPage"
                    :total-rows="totalRows"
                    :per-page="perPage"
                    align="center"
                    @input="getPaginationDaily"
                    class="justify-content-center"
                    size="sm"
                    aria-controls="my-table"
                  ></b-pagination>
                  <!-- <v-pagination
                    id="paginationDaily"
                    v-model="currentPage"
                    :length="total_pages"
                    total-visible="5"
                      class="small-pagination"
                  ></v-pagination> -->
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- Container-fluid Ends-->
  </div>
</template>

<script>
import { requestService } from '@/_services/request.service';
import exportData from '@/components/export.vue';

export default {
  components: {
    exportData,
  },
  data() {
    return {
      isLoading: true,
      inboundFilter: '',
      outboundFilter: '',
      exportBtn: false,
      start_date_daily: new Date().toISOString().substr(0, 10),
      end_date_daily: new Date().toISOString().substr(0, 10),
      date: '',
      call_start_time: '',
      call_end_time: '',
      total_calls: '',
      total_calls: '',
      answered_calls: '',
      abandoned_calls: '',
      per_answered_calls: '',
      per_abandon_calls: '',
      average_talk_time: '',
      per_in_service_level: '',
      rows: [],
      filter: null,
      pageCount: 0,
      totalRows: 1,
      currentPage: 1,
      page: 1,
      total_pages: 1,
      perPage: 20,
      pageOptions: [10, 20, 30, 40, 50, 100],
      sortBy: '',
      sortDesc: false,
      sorting: '',
      headers: [
        {
          text: 'Date',
          value: 'date',
          class: 'headerClass',
        },
        {
          text: 'Total Calls',
          value: 'total_calls',
        },
        {
          text: 'Outbound Calls',
          value: 'out_bound_calls',
        },
        {
          text: 'Inbound Calls',
          value: 'in_bound_calls',
        },

        {
          text: 'Answered Calls',
          value: 'answered_calls',
        },
        {
          text: 'No Answered Calls',
          value: 'abandoned_calls',
        },
        {
          text: '%Answered Calls',
          value: 'per_answered_calls',
        },
        {
          text: '%No Answered Calls',
          value: 'per_abandon_calls',
        },
        {
          text: 'Average Talk Time',
          value: 'average_talk_time',
        },
      ],
      isExport: 0,
      sumRows: [],
    };
  },
  watch: {
    perPage(newVal) {
      this.currentPage = 1;
      this.getPaginationDaily();
    },
    currentPage(newVal) {
      this.getPaginationDaily();
    },
  },
  async mounted() {
    this.totalRows = this.rows.length;
    this.permissionUpdate();
    // this.getPaginationDaily();
  },
  methods: {
    onSorted() {
      let by = '';
      if (!this.sortBy) {
        by = this.sorting.replace(/\s?(asc|desc)$/, '');
      } else {
        switch (this.sortBy) {
          case 'date_duration':
            by = 'date';
            break;
          case 'per_answered_calls':
            by = 'answered_calls';
            break;
          case 'per_abandon_calls':
            by = 'abandoned_calls';
            break;
          case 'average_talk_time':
            by = 'talk';
            break;
          default:
            by = this.sortBy;
            break;
        }
      }
      let sortType = 'asc';
      switch (this.sortDesc) {
        case true:
          sortType = 'desc';
          break;
        case false:
          sortType = 'asc';
          break;
      }
      this.sorting = by + ' ' + sortType;
      console.log('sorting: ', this.sorting);
      this.getPaginationDaily();
    },
    permissionUpdate() {
      const permission =
        localStorage.getItem('permission') || permission.includes('is_admin');
      let result = permission.includes('can_export_report');
      if (!result) {
        this.exportBtn = true;
      } else {
        this.exportBtn = false;
      }
      this.getPaginationDaily();
    },
    async getExportCSVDaily() {
      if (this.isExport == 0) {
        this.isExport = 100;
        let url = `/v2/reports/daily?date_end=${this.end_date_daily}&date_start=${this.start_date_daily}&sort=${this.sortBy}`;
        const header = [
          { name: 'Date', key: 'date' },
          { name: 'Total Calls', key: 'total_calls' },
          { name: 'Outbound Calls', key: 'out_bound_calls' },
          { name: 'Inbound Calls', key: 'in_bound_calls' },
          { name: 'Answered Calls', key: 'answered_calls' },
          { name: 'No Answered Calls', key: 'abandoned_calls' },
          { name: '%Answered Calls', key: 'per_answered_calls' },
          { name: '%No Answered Calls', key: 'per_abandon_calls' },
          { name: 'Average Talk Time', key: 'average_talk_time' },
        ];
        await this.$refs.exportdaily.loopGetData(
          url,
          'daily_report.csv',
          header,
          'dailyReport_v2'
        );
        this.isExport = 0;
      }
    },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      // this.currentPage = page;
    },
    onPageChange(page) {
      this.getPaginationDaily();
    },

    getPaginationDaily() {
      this.rows = [];
      this.sumRows = [];
      this.isLoading = true;
      const url = `/v2/reports/daily?date_end=${this.end_date_daily}&date_start=${this.start_date_daily}&limit=${this.perPage}&total_rows=${this.totalRows}&total_pages=${this.total_pages}&page=${this.currentPage}&date=${this.date}&sort=${this.sorting}`;
      requestService
        .GetFetch(url)
        .then(async (response) => {
          let data = await response.data.data;
          this.rows = data.rows.map((e) => {
            let denominator = e.answered_calls + e.abandoned_calls;
            return {
              per_answered_calls:
                denominator == 0
                  ? 0 + '%'
                  : ((e.answered_calls / denominator) * 100).toFixed(2) + '%',
              per_abandon_calls:
                denominator == 0
                  ? 0 + '%'
                  : ((e.abandoned_calls / denominator) * 100).toFixed(2) + '%',
              average_talk_time: e.answered_calls
                ? e.talk / e.answered_calls
                : 0,
              ...e,
            };
          });

          this.sumRows = [
            'รวม',
            data.sum_report.total.toLocaleString(),
            data.sum_report.total_out_call.toLocaleString(),
            data.sum_report.total_in_call.toLocaleString(),
            data.sum_report.total_answered_call.toLocaleString(),
            data.sum_report.total_abandoned_call.toLocaleString(),
            data.sum_report.total_answered_per,
            data.sum_report.total_abandoned_per,
            data.sum_report.total_average_talk_time,
          ];

          //   this.sumRows = ['รวม', 123, 123, 123, 123, 123, 123, 123, 123];
          // console.log('rows: ', this.rows);
          this.isLoading = false;
          this.totalRows = data.total_rows;
          this.total_pages = response.data.data.total_pages;
        })
        .catch((error) => {
          console.error(error);
        });
    },
    clearFilterAll() {
      let date = new Date().toISOString().substr(0, 10);
      this.start_date_daily = date;
      this.end_date_daily = date;
      this.date = '';
      this.inboundFilter = '';
      this.outboundFilter = '';

      this.getPaginationDaily();
    },
    convertAverageTalkTime(seconds) {
      const totalSeconds = Math.floor(seconds);
      const hours = Math.floor(totalSeconds / 3600);
      const minutes = Math.floor((totalSeconds % 3600) / 60);
      const remainingSeconds = totalSeconds % 60;

      const paddedHours = String(hours).padStart(2, '0');
      const paddedMinutes = String(minutes).padStart(2, '0');
      const paddedSeconds = String(remainingSeconds).padStart(2, '0');

      return `${paddedHours}:${paddedMinutes}:${paddedSeconds}`;
    },
  },
};
</script>

<style scoped>
#paginationDaily >>> .theme--light.v-pagination .v-pagination__item--active {
  background-color: #3f60fb !important;
  color: white;
}
.totalRow {
  height: 25px !important;
}
</style>
<style>
.v-pagination__item {
  height: 25px;
  min-width: 25px;
  margin: 5px !important;
  padding: 0px !important;
  font-size: 14px;
}
.v-pagination__navigation {
  height: 25px;
  min-width: 20px !important;
  margin: 0px !important;
  padding: 0px !important;
  font-size: 12px !important;
}

td.text-start {
  height: 25px !important;
}

.v-data-table .v-data-table-header tr th {
  font-size: 11px !important;
  height: 25px !important;
  text-align: center !important;
}

.filterBox {
  border-radius: 3px;
  box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.2),
    0px 0.10000000149011612px 0.30000001192092896px 0px rgba(0, 0, 0, 0.1);
}

.inputNum::-webkit-inner-spin-button,
inputNum::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

#export-csv-daily-btn-z {
  width: 82px;
  height: 24px;
  margin: 2px 0 0 0;
}

.three-dots-vertical {
  color: #434343ae;
}

.no-border {
  background-color: transparent;
  border: none;
}

#search-one-daily-btn-z {
  color: #3f60fb;
  background-color: #dfedff !important;
  border-color: #dfedff !important;
}

#clear-one-daily-btn-z {
  color: #f83366;
  background-color: #feeaef !important;
  border-color: #feeaef !important;
}
</style>
