import { render, staticRenderFns } from "./addWhitelist.vue?vue&type=template&id=cff41442&scoped=true"
import script from "./addWhitelist.vue?vue&type=script&lang=js"
export * from "./addWhitelist.vue?vue&type=script&lang=js"
import style0 from "./addWhitelist.vue?vue&type=style&index=0&id=cff41442&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "cff41442",
  null
  
)

export default component.exports