var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticStyle:{"transition":"width 1s ease 0s !important"},attrs:{"id":"sidebar-thaipost-components"}},[_c('div',{staticClass:"logo-wrapper"},[_c('router-link',{attrs:{"to":"/home"}},[_c('img',{attrs:{"src":_vm.imgSrc,"width":_vm.imgSrc == _vm.thaipostLogo ? 100 : 80,"height":"80","alt":"logo website"}})]),_c('div',{staticClass:"back-btn",on:{"click":_vm.toggle_sidebar}},[_c('i',{staticClass:"fa fa-angle-left"})]),_c('div',{staticClass:"toggle-sidebar",on:{"click":_vm.toggle_sidebar}},[_c('feather',{staticClass:"status_toggle middle",attrs:{"type":"grid","id":"sidebar-toggle"}})],1)],1),_c('div',{staticClass:"logo-icon-wrapper p-4"},[_c('router-link',{attrs:{"to":"/home"}},[_c('img',{staticClass:"img-fluid",attrs:{"src":_vm.smallImgSrc,"alt":"logo website","width":"60px","height":"60px"}})])],1),_c('nav',{staticClass:"sidebar-main"},[_c('ul',[_c('li',{staticClass:"left-arrow",class:{
          'd-none':
            _vm.layout.settings.layout_type == 'rtl'
              ? _vm.hideLeftArrowRTL
              : _vm.hideLeftArrow,
        },on:{"click":function($event){_vm.layoutobject.split(' ').includes('horizontal-wrapper') &&
          _vm.layout.settings.layout_type === 'rtl'
            ? _vm.scrollToLeftRTL()
            : _vm.scrollToLeft()}}},[_c('feather',{attrs:{"type":"arrow-left"}})],1)]),_c('div',{attrs:{"id":"sidebar-menu"}},[_c('ul',{staticClass:"sidebar-links custom-scrollbar",style:([
          _vm.layoutobject.split(' ').includes('horizontal-wrapper')
            ? _vm.layout.settings.layout_type == 'rtl'
              ? { 'margin-right': _vm.margin + 'px' }
              : { 'margin-left': _vm.margin + 'px' }
            : { margin: '0px' },
        ]),attrs:{"id":"myDIV"}},[_c('li',{staticClass:"back-btn"},[_c('router-link',{attrs:{"to":"/home"}},[_c('img',{staticClass:"img-fluid",attrs:{"src":require("../assets/images/logo/logo-icon.png"),"alt":""}})]),_vm._m(0)],1),_vm._l((_vm.menuItemPer),function(menuItem,index){return _c('li',{key:index,staticClass:"sidebar-list",class:{
            active: menuItem.active,
            'sidebar-main-title': menuItem.type == 'headtitle',
          },staticStyle:{"margin":"auto"}},[(menuItem.type == 'headtitle')?_c('div',[_c('h6',{staticClass:"lan-1"},[_vm._v(_vm._s(menuItem.headTitle1))]),_c('p',{staticClass:"lan-2"},[_vm._v(_vm._s(menuItem.headTitle2))])]):_vm._e(),(menuItem.badgeType)?_c('label',{class:'badge badge-' + menuItem.badgeType},[_vm._v(_vm._s(menuItem.badgeValue))]):_vm._e(),(menuItem.type == 'sub')?_c('a',{staticClass:"sidebar-link sidebar-title",attrs:{"href":"javascript:void(0)"},on:{"click":function($event){return _vm.setNavActive(menuItem, index)}}},[_c('feather',{staticClass:"top",attrs:{"type":menuItem.icon}}),_c('span',[_vm._v(" "+_vm._s(menuItem.title)+" ")]),(menuItem.children)?_c('div',{staticClass:"according-menu"},[_c('i',{staticClass:"fa fa-angle-right pull-right"})]):_vm._e()],1):_vm._e(),(menuItem.type == 'link')?_c('router-link',{staticClass:"sidebar-link sidebar-title",attrs:{"to":menuItem.path,"router-link-exact-active":""}},[_c('font-awesome-icon',{attrs:{"icon":`fa-solid fa-${menuItem.icon}`}}),_c('span',[_vm._v(" "+_vm._s(menuItem.title)+" "),(menuItem.version == 2)?_c('span',{staticStyle:{"color":"red","font-size":"10px"}},[_vm._v("(dev)")]):_vm._e()]),(menuItem.children)?_c('i',{staticClass:"fa fa-angle-right pull-right"}):_vm._e()],1):_vm._e(),(menuItem.children)?_c('ul',{staticClass:"sidebar-submenu",class:{ 'menu-open': menuItem.active }},_vm._l((menuItem.children),function(childrenItem,index){return _c('li',{key:index,class:{ active: childrenItem.active }},[(childrenItem.type == 'sub')?_c('a',{staticClass:"submenu-title",attrs:{"href":"javascript:void(0)"},on:{"click":function($event){return _vm.setNavActive(childrenItem, index)}}},[_vm._v(" "+_vm._s(childrenItem.title)+" "),(childrenItem.badgeType)?_c('label',{class:'badge badge-' + childrenItem.badgeType + ' pull-right'},[_vm._v(_vm._s(childrenItem.badgeValue))]):_vm._e(),(childrenItem.children)?_c('i',{staticClass:"fa fa-angle-right pull-right mt-1"}):_vm._e()]):_vm._e()])}),0):_vm._e()],1)})],2)])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"mobile-back text-right"},[_c('span',[_vm._v("Back")]),_c('i',{staticClass:"fa fa-angle-right pl-2",attrs:{"aria-hidden":"true"}})])
}]

export { render, staticRenderFns }