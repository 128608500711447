import axiosInstance from './api';
import router from '../router/index';
// import router from '../router/index';
import { store } from '@/store/index';

const setup = (store) => {
  axiosInstance.interceptors.request.use((config) => {
    const token = localStorage.getItem('token');
    const checkToken = isTokenExpired();
    if (token) {
      if (checkToken) {
        localStorage.clear();
        router.push('/login');
      } else {
        config.headers['Authorization'] = `Bearer ${token}`;
      }
    } else {
      if(router.currentRoute.meta.requiresAuth){
        localStorage.clear();
        router.push('/login');
      }else{
        config.headers['Authorization'] = `Bearer ${token}`;
      }
    }

    return config;
  });
};

function isTokenExpired() {
  const decodedToken = localStorage.getItem('exp');
  const currentTime = Date.now() / 1000;
  return decodedToken < currentTime;
}

export default setup;
