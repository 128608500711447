<template>
  <div>
    <Breadcrumbs title="User Settings" />
    <div class="container-fluid mb-2">
      <div class="row">
        <div class="col-md-12">
          <div class="card">
            <div class="card-body">
              <div class="py-2 px-4 w-100">
                <b-form @submit.prevent="onSaved">
                  <div class="d-flex mt-2">
                    <!-- First Name -->
                    <b-form-group
                      id="fname-usg1"
                      label-for="firstname-settings-group"
                      class="mr-4 w-100"
                    >
                      <p class="text-size">
                        ชื่อ <span style="color: #f73164">*</span>
                      </p>
                      <b-form-input
                        v-model="user.firstname"
                        placeholder="Enter First Name"
                        required
                      ></b-form-input>
                    </b-form-group>
                    <!-- Last Name -->
                    <b-form-group label-for="lastname-setting" class="w-100">
                      <p class="text-size">
                        นามสกุล <span style="color: #f73164">*</span>
                      </p>
                      <b-form-input
                        v-model="user.lastname"
                        placeholder="Enter Last Name"
                        required
                      ></b-form-input>
                    </b-form-group>
                  </div>
                  <!-- === Box One === -->
                  <div class="d-flex">
                    <!-- Email -->
                    <b-form-group
                      label-for="useremail-settings-form"
                      class="w-100 mr-4"
                    >
                      <p class="text-size">อีเมลล์</p>

                      <b-form-input
                        v-model="user.email"
                        type="email"
                        disabled
                      ></b-form-input>
                    </b-form-group>

                    <!-- Current Password -->
                    <b-form-group label-for="cpassword01" class="w-100">
                      <p class="text-size">รหัสผ่านปัจจุบัน</p>
                      <b-form-input
                        v-model="user.currentPassword"
                        :type="showPassword ? 'text' : 'password'"
                        placeholder="ระบุรหัสผ่านปัจจุบัน"
                        class="heightInput"
                      ></b-form-input>
                    </b-form-group>
                  </div>
                  <!-- Current Password Ends-->

                  <!-- New Password -->
                  <div class="d-flex">
                    <b-form-group
                      label-for="newpassword-settings-form"
                      class="w-100 mr-4"
                    >
                      <p class="text-size">รหัสผ่านใหม่</p>
                      <b-form-input
                        v-model="user.newPassword"
                        type="password"
                        placeholder="ระบุรหัสผ่านใหม่"
                        class="m-0 heightInput"
                        @input="checkNewPassword"
                      ></b-form-input>
                    </b-form-group>
                    <!-- New Password Ends-->

                    <!-- Confirm Password -->
                    <b-form-group
                      label-for="confirmpassword-setting-form"
                      class="w-100"
                    >
                      <p class="text-size">ยืนยันรหัสผ่านใหม่</p>
                      <b-form-input
                        v-model="user.confirmNewPassword"
                        type="password"
                        placeholder="ระบุรหัสผ่านใหม่"
                        class="heightInput"
                        @input="checkNewPassword"
                      ></b-form-input>
                    </b-form-group>
                  </div>
                  <!-- Confirm Password Ends-->

                  <!-- Password Validator -->
                  <div class="passwordValidator">
                    <ul>
                      <li
                        v-bind:class="{ is_valid: contains_eight_characters }"
                      >
                        มีอย่างน้อย 8 ตัวอักษร
                      </li>
                      <li v-bind:class="{ is_valid: contains_number }">
                        มีตัวเลขอย่างน้อย 1 ตัว
                      </li>
                      <li v-bind:class="{ is_valid: contains_uppercase }">
                        มีตัวอักษรพิมพ์ใหญ่อย่างน้อย 1 ตัว
                      </li>
                      <li v-bind:class="{ is_valid: contains_lowercase }">
                        มีตัวอักษรพิมพ์เล็กอย่างน้อย 1 ตัว
                      </li>
                      <li
                        v-bind:class="{
                          is_valid: contains_special_character,
                        }"
                      >
                        มีอักขระพิเศษอย่างน้อย 1 ตัว
                      </li>
                    </ul>
                  </div>
                  <!-- Password Validator Ends -->

                  <!-- Button -->
                  <div class="d-flex justify-content-start my-3">
                    <b-button
                      :disabled="btnDisabled"
                      type="submit"
                      variant="primary"
                      >Save</b-button
                    >
                  </div>
                </b-form>
                <p style="color: #f73164; font-size: 14px">
                  {{ aleartMessage }}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from 'vue';
import VueSimpleAlert from 'vue-simple-alert';
// import { store } from '@/store/index.js';
// import axios from 'axios';
// import { api_host } from '../constants/config';
import { requestService } from '../_services/request.service';

Vue.use(VueSimpleAlert);

export default {
  data() {
    return {
      aleartMessage: '',
      showPassword: false,
      password_length: 0,
      contains_eight_characters: false,
      contains_number: false,
      contains_uppercase: false,
      contains_lowercase: false,
      contains_special_character: false,
      btnDisabled: true,
      user: {
        email: '',
        currentPassword: '',
        newPassword: '',
        confirmNewPassword: '',
        firstname: '',
        lastname: '',
      },
      // user_result: {
      //   currentPassword: null,
      //   newPassword: null,
      //   confirmNewPassword: null,
      //   firstname: null,
      //   lastname: null,
      // },
    };
  },
  mounted() {
    this.getData();
  },
  methods: {
    getData() {
      const id = localStorage.getItem('id');
      requestService
        .GetFetch(`/auth/user_management/${id}`)
        .then((res) => {
          this.user.email = res.data.email;
          this.user.firstname = res.data.firstname;
          this.user.lastname = res.data.lastname;
        })
        .catch((err) => {
          console.log('err: ', err);
        });
    },
    onSaved() {
      const token = localStorage.getItem('token');
      const newData = {
        email: this.user.email,
        current_password: this.user.currentPassword,
        new_password: this.user.newPassword,
        confirm_new_password: this.user.confirmNewPassword,
        firstname: this.user.firstname,
        lastname: this.user.lastname,
      };

      if (
        this.user.currentPassword == this.user.newPassword ||
        this.user.currentPassword == this.user.confirmNewPassword
      ) {
        this.$swal({
          icon: 'error',
          text: 'Your new password must be different.',
          // text: "New Password should be the same as Current Password.",
        });
        this.user.newPassword = '';
        this.user.confirmNewPassword = '';
        this.contains_eight_characters = false;
        this.contains_number = false;
        this.contains_uppercase = false;
        this.contains_lowercase = false;
        this.contains_special_character = false;
      } else if (this.user.newPassword != this.user.confirmNewPassword) {
        this.$swal({
          icon: 'error',
          text: 'New password and Confirm new password must be the same.',
          // text: "New Password should be the same as Current Password.",
        });
        this.user.newPassword = '';
        this.user.confirmNewPassword = '';
        this.contains_eight_characters = false;
        this.contains_number = false;
        this.contains_uppercase = false;
        this.contains_lowercase = false;
        this.contains_special_character = false;
      } else {
        requestService
          .PatchFetch(`/auth/change_password`, newData)
          .then((res) => {
            this.$fire({
              title: 'Saved',
              type: 'success',
              timer: 3000,
            });
            let obj = {
              firstname: newData.firstname,
              lastname: newData.lastname,
            };
            this.$store.dispatch('SET_NAME', obj);
            // localStorage.setItem('firstname', newData.firstname);
            // localStorage.setItem('lastname', newData.lastname);
            this.getData();
            this.user.currentPassword = '';
            this.user.newPassword = '';
            this.user.confirmNewPassword = '';
            this.contains_eight_characters = false;
            this.contains_number = false;
            this.contains_uppercase = false;
            this.contains_lowercase = false;
            this.contains_special_character = false;
          })
          .catch((err) => {
            // console.log('err: ', err);
            
            this.$swal.fire({
            icon: 'error',
            title: 'มีบางอย่างผิดพลาด',
            text: 'ไม่สามารถแก้ไขข้อมูลได้',
            showConfirmButton: true,
            timer: 1500,
          });

          });
      }
    },
    // getData() {
    //   const token = localStorage.getItem('token');
    //   const id = localStorage.getItem('id');
    //   const config = {
    //     headers: {
    //       Authorization: 'Bearer ' + token,
    //     },
    //   };
    //   axios
    //     .get(api_host + `/auth/user_management/${id}`, config)
    //     .then((res) => {
    //       // console.log('res: ', res.data);
    //       this.user.email = res.data.email;
    //       this.user.firstname = res.data.firstname;
    //       this.user.lastname = res.data.lastname;
    //     })
    //     .catch((err) => {
    //       console.log(err);
    //     });
    // },
    // onSaved() {
    //   if (this.user.currentPassword == store.state.login.userPass) {
    //     if (this.user.newPassword == this.user.confirmNewPassword) {
    //       this.user_result.currentPassword = null;
    //       this.aleartMessage = '';
    //       this.$fire({
    //         title: 'Saved',
    //         type: 'success',
    //         timer: 3000,
    //       });
    //     } else {
    //       this.aleartMessage = 'New passwords are not equal.';
    //     }
    //   } else {
    //     this.aleartMessage = 'Incorrect Password.';
    //     this.user_result.currentPassword = false;
    //   }
    // },
    checkNewPassword() {
      if (this.user.newPassword != '') {
        this.password_length = this.user.newPassword.length;
        const format = /[ !@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]/;
        if (this.password_length >= 8) {
          this.contains_eight_characters = true;
        } else {
          this.contains_eight_characters = false;
        }

        this.contains_number = /\d/.test(this.user.newPassword);
        this.contains_uppercase = /[A-Z]/.test(this.user.newPassword);
        this.contains_lowercase = /[a-z]/.test(this.user.newPassword);
        this.contains_special_character = format.test(this.user.newPassword);

        if (
          this.contains_eight_characters &&
          this.contains_number &&
          this.contains_uppercase &&
          this.contains_lowercase &&
          this.contains_special_character &&
          this.user.confirmNewPassword != ''
        ) {
          this.btnDisabled = false;

          // this.user_result.confirmNewPassword = null;
        } else {
          this.btnDisabled = true;
          // this.user_result.confirmNewPassword = false;
        }
      } else {
        this.btnDisabled = true;
      }
    },
    checkCurrentPass() {
      if (this.user.currentPassword != '') {
        this.btnDisabled = false;
      } else {
        this.btnDisabled = true;
      }
    },
    // checkName() {
    //   if (this.user.firstname != '') {
    //     this.btnDisabled = false;
    //     this.user_result.firstname = null;
    //   } else {
    //     this.btnDisabled = true;
    //     this.user_result.firstname = false;
    //   }

    //   if (this.user.lastname != '') {
    //     this.btnDisabled = false;
    //     this.user_result.lastname = null;
    //   } else {
    //     this.btnDisabled = true;
    //     this.user_result.lastname = false;
    //   }
    // },
  },
};
</script>

<style>
.heightInput {
  height: 38px !important;
}
/* .userEmail {
  background: #fff !important;
  color: #888888 !important;
} */
</style>
