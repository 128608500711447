<template>
  <div
    style="height: 80vh"
    class="d-flex justify-content-center align-items-center"
  >
  <!-- src="../assets/images/logo/logo-icon.png" -->
    <img
      class="img-fluid"
      :src="imgSrc"
      alt="logo website"
      width="170px"
      height="170px"
    />
  </div>
</template>

<script>
import thaipostLogo from '../assets/images/logo/logo-icon.png'
import csLogo from '../assets/images/logo/cloudsoft.png'
import smartCallLogo from '../assets/images/logo/smartcall_logo.png'
export default {
  data() {
    return {
      imgSrc: ''
    }
  },
  mounted() {
    this.imgSrc = window.location.hostname == 'thailandpost.smartcall.cloud' ? thaipostLogo : smartCallLogo
  },
};
</script>
