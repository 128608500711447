import Vue from 'vue';
import App from './App.vue';
import router from './router';
import VueMeta from 'vue-meta';
import Breadcrumbs from './components/bread_crumbs';
import { store } from './store';
import VueFeather from 'vue-feather';
import PxCard from './components/Pxcard.vue'; // Pxcard
import './assets/scss/app.scss'; // Import Theme scss
import { BootstrapVue, BootstrapVueIcons } from 'bootstrap-vue'; // Bootstrap-Vue, Icons
import Papa from 'papaparse';
import { ToggleButton } from 'vue-js-toggle-button';
import axios from 'axios';
import { fas } from '@fortawesome/free-solid-svg-icons';
import { far } from '@fortawesome/free-regular-svg-icons';
import { fab } from '@fortawesome/free-brands-svg-icons';

import VueSweetalert2 from 'vue-sweetalert2';
import 'sweetalert2/dist/sweetalert2.min.css';
Vue.use(VueSweetalert2);

/* import the fontawesome core */
import { library } from '@fortawesome/fontawesome-svg-core';

/* import font awesome icon component */
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';

/* import specific icons */
// import { faUserSecret } from '@fortawesome/free-solid-svg-icons'
import { faFileExport } from '@fortawesome/free-solid-svg-icons';
import { faFileImport } from '@fortawesome/free-solid-svg-icons';


import { MonthPicker } from 'vue-month-picker'
import { MonthPickerInput } from 'vue-month-picker'
Vue.use(MonthPicker)
Vue.use(MonthPickerInput)
/* add icons to the library */
// library.add(faUserSecret)
library.add(faFileExport);
library.add(faFileImport);

/* add font awesome icon component */
library.add(fas, far, fab);
Vue.component('font-awesome-icon', FontAwesomeIcon);

import VuePapaParse from 'vue-papa-parse';

Vue.use(VuePapaParse);

Vue.config.productionTip = false;

Vue.use(Papa);
Vue.use(VueFeather);
Vue.use(VueMeta);
Vue.component(PxCard.name, PxCard); // Pxcard
Vue.use(BootstrapVue); // Bootstrap-Vue
Vue.use(BootstrapVueIcons); // Bootstrap-Vue, Icons
Vue.component('Breadcrumbs', Breadcrumbs);
Vue.component('ToggleButton', ToggleButton);

import setupInterceptors from './_services/setupInterceptors';
import vuetify from './plugins/vuetify'
setupInterceptors();

Vue.prototype.$http = axios;

new Vue({
  router,
  store,
  axios,
  vuetify,
  render: (h) => h(App)
}).$mount('#app');

// import Papa from 'papaparse';
// import iconv from 'iconv-lite';

// Vue.use(Papa);
// Vue.use(iconv);
